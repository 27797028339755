/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Table,
    Media, Form, FormGroup,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, { useContext, useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import { AuthContext } from "context/AuthContext";
//import { MEDIA_URL } from "../../constants";
import Search from "antd/lib/transfer/search";
import moment from "moment";
import { getChatsPaginated } from "services/ChatServices";
import { useHistory } from "react-router-dom";
import { takeChat, continueChat } from "services/ChatServices";
import { getChats } from "services/ChatServices";
import { Link } from "react-router-dom";
import {getPostsTypePaginated} from "../../services/ArticleServices";
import env from "ts-react-dotenv";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MEDIA_URL = env.MEDIA_URL;

const MyChats = (props) => {

    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [chats, setChats] = useState([]);
    const [lastPage, setLastPage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const history = useHistory();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    useEffect(() => {

        const retrieveChats = async () => {
            let chats = await getChats(undefined, user?.id, undefined);
            setChats(chats.data);
            setLastPage(chats.meta.last_page);
        }

        if (user) {
            retrieveChats();
        }
    }, [user]);

    // const retrieveChats = async (page) => {
    //     let chats = await getChats(page, user?.id,undefined);
    //     setChats(chats.data);
    // }

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let chats = await getChats(parseInt(requestedPage), user?.id, nbPageElement);
        setChats(chats.data);
        setLastPage(chats.meta.last_page);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setNbPageElement(parseInt(nb));
        let chats = await getChats(1, user?.id, parseInt(nb));
        setChats(chats.data);
        setLastPage(chats.meta.last_page);
    }

    return (
        <>
            <ChatHeader />
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Chats Here</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Post Name</th>
                                        <th scope="col">Admin Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        chats?.map((item) =>
                                            <>
                                                {!(["PENDING", 4].includes(item?.status)) &&

                                                    <tr key={item.id} style={{ cursor: 'pointer' }} value={item.id}
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: '/admin/single-chat',
                                                                search: '?chatId=' + item.id
                                                            });
                                                        }}>
                                                        <th scope="row">
                                                            <Media className="align-items-center">
                                                                <a
                                                                    className="avatar rounded-circle mr-3"
                                                                >
                                                                    <img
                                                                        alt="..."
                                                                        src={MEDIA_URL + item?.user_avatar}
                                                                    />
                                                                </a>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">
                                                                        {item.user_name}
                                                                    </span>
                                                                </Media>
                                                            </Media>
                                                        </th>
                                                        <td>{item.post_name}</td>
                                                        <td>{item.admin_name}</td>
                                                        <td>
                                                            {item.status}
                                                        </td>
                                                        <td>
                                                            {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                                        </td>
                                                    </tr>
                                                }
                                            </>

                                        )
                                    }
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                <select className="form-control" onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default MyChats;
