import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Button,
    Col,
    UncontrolledTooltip,
    Table,
    Media, Form, FormGroup, Nav,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, { useContext, useEffect, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getChats, getChatsPaginated } from "services/ChatServices";
import { Link, useHistory, useLocation } from "react-router-dom";
import { takeChat, continueChat } from "services/ChatServices";
import { getPurchasesPaginated } from "services/PurchaseServices";
import { changePurchaseStatus } from "services/PurchaseServices";
import ReactLoading from "react-loading";
import env from "ts-react-dotenv";
import { getPurchase } from "services/ArticleServices";
import PurchaseHeader from "components/Headers/PurchaseHeader";
import { getJobApplication } from "services/JobServices";
import { getAllPaginatedApplications } from "services/JobServices";
import { computeNumerotation } from "../../constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";


const MEDIA_API_URL = env.MEDIA_API_URL;
const REACT_APP_BACKEND_SERVICE = env.REACT_APP_BACKEND_SERVICE;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SingleJobApplications = (props) => {

    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [jobApplications, setJobApplications] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [purchase, setPurchase] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(true);
    const history = useHistory();
    const { search } = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [isLoading, setLoading] = useState(true);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    const retrieveJobApplications = async () => {
        let response = await getAllPaginatedApplications();
        console.log(response.data);
        setJobApplications(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let response = await getAllPaginatedApplications(
            parseInt(requestedPage),
            itemPerPage
        );
        setJobApplications(response.data);
        setCurrentPage(response.meta.current_page);
    };

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        setNbPageElement(parseInt(nb));
        let response = await getAllPaginatedApplications(undefined, parseInt(nb));
        console.log(response.data);
        setJobApplications(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const downloadZippedFiles = async (dataObject) => {
        const zip = new JSZip();
        const timestamp = new Date().getTime(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string of 6 characters
        const fileName = `my_application_${timestamp}_${randomString}.zip`; // Ersetzt Sonderzeichen und Leerzeichen

        // Iteriere über die Dateien im 'files'-Array und füge sie zum ZIP-Archiv hinzu
        for (const file of dataObject.files) {
            const response = await fetch(MEDIA_API_URL + file.path); // Hier müsstest du den tatsächlichen Pfad der Datei angeben
            const content = await response.blob(); // Erhalte den Inhalt als Blob
            zip.file(file.path.split("/").pop(), content); // Nutze nur den Dateinamen aus dem Pfad
        }

        // Generiere das ZIP-Archiv und starte den Download
        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, fileName); // Verwende den generierten Namen für das ZIP-Archiv
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        let jobId = queryParams.get('jobId');

        const retrieveJobApplications = async () => {
            let response = await getJobApplication(jobId);
            console.log(response.data);
            setJobApplications(response.data);
            setLastPage(response.meta.last_page);
            setCurrentPage(response.meta.current_page);
            setLoading(false);
        };

        retrieveJobApplications();
    }, [search]);

    return (
        <>
            <PurchaseHeader />
            {/* Page content */}
            {/*             <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Purchase details</h3>
                                <Nav className="justify-content-end" pills>

                                    {openInfo ?
                                        <i className="fas fa-arrow-up text-success mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenInfo(!openInfo)} />
                                        : <i className="fas fa-arrow-down text-warning mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenInfo(!openInfo)} />
                                    }
                                </Nav>
                            </CardHeader>
                            {openInfo &&
                                <Table className="align-items-center table-flush" responsive>
                                    <tbody className="thead-light">
                                        <tr>
                                            <td></td>
                                            <th scope="col">Transaction ID</th>
                                            <td> {purchase?.transaction_id}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Payment Method</th>
                                            <td> {purchase?.payment_method}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Quantity</th>
                                            <td> {purchase?.quantity}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col"><span>Amount</span></th>
                                            <td>
                                                {purchase?.amount}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Address</th>
                                            <td> {purchase?.shipped ? purchase?.address : purchase?.user.address}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">City</th>
                                            <td> {purchase?.shipped ? purchase?.city : purchase?.user.city}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Country</th>
                                            <td> {purchase?.shipped ? purchase?.country : purchase?.user.country} </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Email</th>
                                            <td> {purchase?.shipped ? purchase?.email : purchase?.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">
                                                <span>Created at </span></th>
                                            <td>  {moment(purchase?.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">
                                                <span>Updated at</span></th>
                                            <td>  {moment(purchase?.updated_at).format("YYYY/MM/DD kk:mm:ss")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <th scope="col">Manage this user</th>
                                            <td>
                                                <Row>
                                                    <div className="col" >
                                                        <Link to={{
                                                            pathname: '/admin/users/details',
                                                            search: '?userId=' + purchase?.user_id
                                                        }}>

                                                            <Button
                                                                color="primary"
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                                Go the user page
                                                            </Button>
                                                        </Link>
                                                        <Link to={{
                                                            pathname: '/admin/invoice',
                                                            search: '?purchaseUid=' + purchase?.purchase_uid
                                                        }}
                                                            target="_blank">
                                                            <Button
                                                                color="primary"
                                                            >
                                                                See Invoice
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </Row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>}
                        </Card>
                    </div>
                </Row>
            </Container> */}

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All applications of Job {jobApplications[0]?.job_title}</h3>
                            </CardHeader>
                            {isLoading ? (
                                <ReactLoading type={"spin"} color="#B20101" />
                            ) : (
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Applier Name</th>
                                            <th scope="col">Apllier Email</th>
                                            <th scope="col">Apllier Phone</th>
                                            <th scope="col">Number of Files</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobApplications?.map((item, index) => (
                                            <>
                                                <tr key={item.id}>
                                                    <th scope="row">
                                                        <span className="mb-0 text-sm">
                                                            {computeNumerotation(
                                                                currentPage,
                                                                itemPerPage,
                                                                index
                                                            )}
                                                        </span>
                                                    </th>
                                                    <th scope="row">
                                                        <Media className="align-items-center">
                                                            <Media>
                                                                <span className="mb-0 text-sm">
                                                                    {item.job_title}
                                                                </span>
                                                            </Media>
                                                        </Media>
                                                    </th>
                                                    <th scope="row">{item.first_name + " " + item.last_name}</th>
                                                    <th scope="row">{item.email}</th>
                                                    <th scope="row">{item.phone}</th>
                                                    <th scope="row">{item.files.length}</th>
                                                    <td>
                                                        {moment(item.created_at).format(
                                                            "YYYY/MM/DD kk:mm:ss"
                                                        )}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">
                                                            <Tooltip title="Download Application FIles" placement="top">
                                                                <span
                                                                    className="material-icons danger"
                                                                    onClick={
                                                                        () => downloadZippedFiles(item)
                                                                    }
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                >
                                                                    download
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        handleSearch(e);
                                                    }}
                                                >
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>
                                                        25
                                                    </option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={
                                                    "pagination justify-content-end mb-0"
                                                }
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default SingleJobApplications;
