import React from "react";
import Badge from "react-bootstrap/Badge";
import { marked } from 'marked';
import Emoji from "./Emoji";
import Picker from "emoji-picker-react";

export default class Markdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markdown: "",
            chosenEmoji: null
        };
    }

    updateMarkdown(markdown) {
        this.setState({ markdown });
    }

     onEmojiClick = (event, emojiObject) => {
         this.setState(prevState => {
             return {markdown : prevState.markdown + emojiObject.emoji};})
        this.setState({chosenEmoji :emojiObject});
    };

    render() {

        var inputStyle = {
            width: "400px",
            height: "50vh",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px",
        };
        var outputStyle = {
            width: "400px",
            height: "50vh",
            backgroundColor: "#DCDCDC",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px",
        };

        return (
            <div className="App">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col text-center">
                            <div>
                                {this.state.chosenEmoji ? (
                                    <span>You chose: {this.state.chosenEmoji.emoji}</span>
                                ) : (
                                    <span>No emoji Chosen</span>
                                )}
                                <Picker onEmojiClick={this.onEmojiClick} />
                            </div>
                            <h1>
                                {" "}
                                <Badge className="text-align-center" variant="light">
                                    Markdown Previewer
                                </Badge>
                            </h1>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            {" "}
                            <div className="col text-center">
                                <h4>
                                    <Badge className="text-align-center" variant="secondary">
                                        Markdown Input
                                    </Badge>
                                </h4>
                                <button className="btn" value="<span>&#x1F525</span>" onClick={
                                    (e) => this.setState(prevState => {
                                        return {markdown : prevState.markdown + e.target.value};})}>feu</button>
                            </div>
                            <div className="input" style={inputStyle}>
                <textarea
                    className="input"
                    style={inputStyle}
                    value={this.state.markdown}
                    onChange={(e) => {
                        this.updateMarkdown(e.target.value);
                    }}
                >
                </textarea>
                            </div>
                        </div>

                        <div className="col-md-6">
                            {" "}
                            <div className="col text-center">
                                <h4>
                                    <Badge className="text-align-center" variant="secondary">
                                        Preview
                                    </Badge>
                                </h4>
                            </div>
                            <div
                                style={outputStyle}
                                dangerouslySetInnerHTML={{
                                    __html: marked(this.state.markdown),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}