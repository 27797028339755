/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Progress,
} from "reactstrap";

// core components
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import './filestyle.css';
import './videostyle.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AuthContext } from "context/AuthContext";
import { createArticle, deleteArticle, getPostsTypePaginated } from "services/ArticleServices";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { changeStatus } from "services/ArticleServices";
import { deactivateArticle } from "services/ArticleServices";
import ImageComponent from "./ImageComponent";
import { getPostsType } from "services/ArticleServices";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { hastagHighlight, inputStyle } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import { Player } from "video-react";
import { activateArticle } from "services/ArticleServices";
import FormHelperText from '@material-ui/core/FormHelperText';
import {marked} from "marked";
import Picker from "emoji-picker-react";
import env from "ts-react-dotenv";
import JobHeader from "components/Headers/JobHeader";

//import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

const Vehicles = () => {

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        reset, formState: { errors }
    } = useForm();

    const { ref: title3, ...restTitle3 } = register3("title", { required: true, minLength: 5 });
    //const { ref: description3, ...restDescription3 } = register3("description", { required: true, minLength: 50 });
    const { ref: unit_price3, ...restUnitPrice3 } = register3("unit_price", { required: true, min: 0 });
    const { ref: mileage3, ...restMileage3 } = register3("mileage", { required: true, min: 0 });
    const { ref: construction_year3, ...restConstructionYear3 } = register3("construction_year", { required: true, min: 0, max:new Date().getFullYear() });
    const { ref: Gear3, ...restGear3 } = register3("gear", { required: true });
    const { ref: number_of_doors3, ...restNumberOfDoors } = register3("number_of_doors", { required: true, min: 0 });
    const { ref: power3, ...restPower3 } = register3("power", { required: true, min: 0 });
    const { ref: color3, ...restColor3 } = register3("color", { required: true });
    const { ref: country3, ...restCountry3 } = register3("country", { required: true });
    const { ref: town3, ...restTown3 } = register3("town", { required: true });

    const { user } = useContext(AuthContext);
    const [openVehicle, setOpenVehicle] = useState(false);
    const [openEditVehicle, setOpenEditVehicle] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [selectedReduction, setSelectedReduction] = useState("No");
    const [selectedUntil, setSelectedUtil] = useState("No");
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [posts, setPosts] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [openDescriptions, setOpenDescriptions] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [newStatus, setNewStatus] = useState("2");
    const [images, setImages] = useState([]);
    const [savedImages, setSavedImages] = useState([]);
    const [toDeleteImages, setToDeleteImages] = useState([]);
    const inputRef = useRef(null);
    const videoRef = useRef(null);
    const [videos, setVideos] = useState([]);
    const [savedVideos, setSavedVideos] = useState([]);
    const [toDeleteVideos, setToDeleteVideos] = useState([]);
    const [lastPage, setLastPage] = useState('');
    const [progress, setProgress] = useState(0);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [markdown, setMarkdown] = useState('');
    const [markdownUpdated, setMarkdownUpdated] = useState('');


    //This variable is to take value inside table item (Vehicule)
    const [formItem, setFormItem] = useState('');

    const updateProgress = (val) => {
        setProgress(val);
    };

    const handleVehicle = () => {
        setOpenVehicle(!openVehicle);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setImages([]);
        setVideos([]);
    };

    const handleEditVehicle = () => {
        setOpenEditVehicle(!openEditVehicle);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleClickImageInput = () => {
        inputRef.current.click();
    }

    const handleClickVideoInput = () => {
        videoRef.current.click();
    }

    const handleUploadImage = (event) => {
        var image = event.target.files[0];
        var fSize = Math.round((image.size / 1024));
        if (fSize > 2048) {
            setAlertMessage("Image size should be less than 2Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileID = uuidv4() + "." + image.name.split('.').pop();
            let imageUrl = URL.createObjectURL(image);
            setImages([...images, { key: fileID, url: imageUrl, file: image }]);
        }
    }

    const handleRemoveImage = (imageKey) => {
        const myImages = images.filter(element => element.key !== imageKey);
        setImages(myImages);
    }

    const removeToDeleteImage = (imageId) => {
        const myImages = savedImages.filter(element => element.id !== imageId);
        setSavedImages(myImages);
        setToDeleteImages([...toDeleteImages, imageId]);
    }

    const handleUploadVideo = (event) => {
        var video = event.target.files[0];
        var fSize = Math.round((video.size / 1024));
        if (fSize > 30720) {
            setAlertMessage("Video size should be less than 30Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileID = uuidv4() + "." + video.name.split('.').pop();
            let videoUrl = URL.createObjectURL(video);
            setVideos([...videos, { key: fileID, url: videoUrl, file: video }]);
        }
    };

    const handleRemoveVideo = (videoKey) => {
        const myVideos = videos.filter(element => element.key !== videoKey);
        setVideos(myVideos);
    }

    const removeToDeleteVideo = (videoId) => {
        const myVideos = savedVideos.filter(element => element.id !== videoId);
        setSavedVideos(myVideos);
        setToDeleteVideos([...toDeleteVideos, videoId]);
    }


    const handleDetails = () => {
        setOpenDetails(!openDetails);
    };

    const handleDescription = () => {
        setOpenDescriptions(!openDescriptions)
    };

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDeactivateModal = () => {
        setOpenDeactivateModal(!openDeactivateModal);
    };

    const handleActivateModal = () => {
        setOpenActivateModal(!openActivateModal);
    };

    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    const saveVehicle = async (data) => {
        setSubmited(true);
        let post_type = "VEHICLE";
        let user_id = user.id;

        try {
            await createArticle(data, selectedUntil, selectedReduction, post_type, user_id, images, videos, updateProgress, markdown);

            setOpenVehicle(!openVehicle);
            setOpenTable(!openTable);
            setOff(!off);

            setAlertMessage("Post done sucessfully");
            setAlertType("success");
            setMarkdown('');
            handleOpenAlert();

            retrievePostsPaginated();
            reset();
            setImages([]);
            setVideos([]);
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setMarkdown('');
            setImages([]);
            setVideos([]);
            setSubmited(false);
            
        }

    }

    const editVehicle = async (data) => {

        setSubmited(true);
        let post_type = "VEHICLE";
        let user_id = user.id;
        let post_id = activeObject.item.id;

        try {
           // await updateArticle(data, selectedUntil, selectedReduction, post_type, post_id, user_id, images, videos, toDeleteImages, toDeleteVideos, updateProgress, markdownUpdated);

            setOpenEditVehicle(!openEditVehicle);
            setOpenTable(!openTable);
            setOff(!off);

            setAlertMessage("Vehicle updated sucessfully");
            setMarkdownUpdated('');
            setAlertType("success");
            handleOpenAlert();

            retrievePostsPaginated();
            reset();
            setImages([]);
            setSavedImages([]);
            setToDeleteImages([]);
            setVideos([]);
            setSavedVideos([]);
            setToDeleteVideos([]);
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setMarkdownUpdated('');
            setImages([]);
            setSavedImages([]);
            setToDeleteImages([]);
            setVideos([]);
            setSavedVideos([]);
            setToDeleteVideos([]);
            setSubmited(false);
        }
    }

    const deletePost = async () => {
        setOpenDeleteModal(false);
        let post_id = activeObject.item.id;

        try {
            await deleteArticle(post_id);

            setAlertMessage("Post deleted sucessfully");
            setAlertType("success");
            handleOpenAlert();

            retrievePostsPaginated();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const handleChangeStatus = (event) => {
        setNewStatus(event.target.value);
    };

    const changePostStatus = async () => {
        setOpenStatusModal(false);
        let post_id = activeObject.item.id;

        try {
            await changeStatus(post_id, newStatus);

            setAlertMessage("Post status sucessfully changed");
            setAlertType("success");
            handleOpenAlert();

            retrievePostsPaginated();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const deactivatePost = async () => {
        setOpenDeactivateModal(false);
        let post_id = activeObject.item.id;

        try {
            await deactivateArticle(post_id);

            setAlertMessage("Post sucessfully deactivated");
            setAlertType("success");
            handleOpenAlert();

            retrievePostsPaginated();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const activatePost = async () => {
        setOpenActivateModal(false);
        let post_id = activeObject.item.id;

        try {
            await activateArticle(post_id);

            setAlertMessage("Post sucessfully activated");
            setAlertType("success");
            handleOpenAlert();

            retrievePostsPaginated();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }


    const retrievePosts = async () => {
        let posts = await getPostsType(3);
        setPosts(posts);
    }

    const retrievePostsPaginated = async (page) => {
        let posts = await getPostsTypePaginated(3, undefined, null);
        setPosts(posts.data);
    }

    // const handlePageClick = (data) => {
    //     const requestedPage = data.selected + 1;
    //     retrievePostsPaginated(requestedPage);
    // }
    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let posts = await getPostsTypePaginated(3, parseInt(requestedPage),  nbPageElement);
        console.log(posts);
        setPosts(posts.data);
        setLastPage(posts.meta.last_page);

    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setNbPageElement(parseInt(nb));
        let posts = await getPostsTypePaginated(3, null, parseInt(nb));
        console.log(posts);
        setPosts(posts.data);
        setLastPage(posts.meta.last_page);
    }

    // const handleSearch = async (e) => {
    //     let nb = e.target.value;
    //     let posts = await getPostsTypePaginated(3, parseInt(nb));
    //     setPosts(posts.data);
    //     setLastPage(posts.meta.last_page);
    // }

    const updateMarkdown = (e) => {
        let input = document.getElementById("textarea");
        setMarkdown(input.value);
    };

    const onEmojiClick = (event, emojiObject) => {
        let input = document.getElementById("textarea");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdown(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    };

    const onUpdateEmojiClick = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdate");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdated(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    };

    useEffect(() => {
        const retrievePosts = async () => {
            let posts = await getPostsTypePaginated(3, null);
            setPosts(posts.data);
            setLastPage('1');
           // setLastPage(posts.meta.last_page);
        }
        retrievePosts();
    }, []);

    // useEffect(() => {
    //     const retrievePosts = async () => {
    //         let posts = await getPostsType("VEHICLE");
    //         setPosts(posts);
    //     }
    //     retrievePosts();
    // }, []);

    const DetailModal = ({ object: { item } }) => (
        <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Post details</DialogTitle>
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <h2>Post details</h2>
                        <Table className="align-items-center table-flush" responsive>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>Post Title</td>
                                    <td> {item.title}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Post type</td>
                                    <td> {item.post_type}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span>Description</span></td>
                                    <td>
                                        {item.description !== 'undefined' ? <div
                                            dangerouslySetInnerHTML={{
                                                __html: marked(hastagHighlight(item.description)),
                                            }}
                                        ></div> : ' '}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Town</td>
                                    <td> {item.town}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td> {item.post_type === "ARTICLE" ?
                                        <>
                                            Unit Price
                                            : item.unit_price</> : <> Wholesale Price :
                                                item.wholesale_price</>}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Quantity</td>
                                    <td> {item.quantity} </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td> {item.post_type === "VEHICLE" ? <> Price :
                                        item.unit_price </> : null}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Number Of Doors</td>
                                    <td> {item.number_of_doors}</td>
                                </tr>
                                {/*        <tr>*/}
                                {/*    <td></td>*/}
                                {/*    <td>Mileage</td>*/}
                                {/*    <td> {item.mileage ? <> Color : item.color </> : null}</td>*/}
                                {/*</tr>*/}
                                {/*        <tr>*/}
                                {/*    <td></td>*/}
                                {/*    <td>Mileage</td>*/}
                                {/*    <td> <h2>Gear : {item.gear}</h2><h2>Construction Year*/}
                                {/*        : {item.construction_year}</h2></td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td></td>
                                    <td>Country</td>
                                    <td> Country : {item.country ?
                                        <>Power
                                            : item.power </> : null}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span>Created at </span></td>
                                    <td>  {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span>Updated at</span></td>
                                    <td>  {moment(item.updated_at).format("YYYY/MM/DD kk:mm:ss")}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Row>
                                            <div className="col">
                                                <div className="card-profile-stats d-flex ">
                                                    <div>
                                                        <span
                                                            className="heading">{item.likes.length}</span>
                                                        <span className="description">likes</span>
                                                    </div>
                                                    <div>
                                                        <span
                                                            className="heading">{item.comments}</span>
                                                        <span className="description">Comments</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Row>
                                            <div className="col">
                                                <a href={CLIENT_URL + "details?postId=" + item.id} target="_blank">
                                                    <Button
                                                        color="primary"
                                                    >
                                                        Go the the post
                                                    </Button></a>
                                            </div>
                                        </Row>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetails} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const DescriptionInfos = () => (
        <Dialog open={openDescriptions} onClose={handleDescription} aria-labelledby="form-dialog-title">
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <h2>Description formatter</h2>
                        <Table className="align-items-center table-flush" responsive>
                            <thead>
                            <th>Commands</th>
                            <th>Syntax</th>
                            <th>Description</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Bold</td>
                                <td>Sample content for <sup>**</sup> bold text <sup>**</sup></td>
                                <td>For bold, add <sup>**</sup> or __ to front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>Sample content for <sup>*</sup> Italic text <sup>*</sup></td>
                                <td>For Italic, add <sup>*</sup> or _ to front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Bold and Italics</td>
                                <td><span>Sample content for <sup>***</sup> bold and Italic text <sup>***</sup> </span></td>
                                <td>For bold and Italics, add <sup>***</sup> to the front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Heading 1 <br/>
                                    Heading 6 </td>
                                <td>
                                    <span># Heading 1 content</span><br />
                                    <span>###### Heading 6 content</span>
                                </td>
                                <td>For heading 1, add # to start of the line.<br />
                                    For heading 6, add ###### to start of the line.</td>
                            </tr>
                            <tr>
                                <td>Hastag</td>
                                <td>
                                    #Hastag</td>
                                <td>#Hastag</td>

                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDescription} color="danger">
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    );

    // const DetailModal = ({ object: { item } }) => (
    //     <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
    //         <DialogTitle id="form-dialog-title">Event details</DialogTitle>
    //         <DialogContent>
    //             <h1>{item.post_type}</h1>
    //             <h2>Title : {item.title}</h2>
    //             <p>{item.description}</p>
    //             <p>Towm : {item.town}</p>
    //             {item.post_type === "ARTICLE" ? <> <h2>Unit Price : {item.unit_price}</h2><h2>Wholesale Price : {item.wholesale_price}</h2><h2>Quantity : {item.quantity}</h2> </> : null}
    //             {item.post_type === "VEHICLE" ? <> <h2>Price : {item.unit_price}</h2><h2>Numer Of Doors : {item.number_of_doors}</h2>
    //                 <h2>Mileage : {item.mileage}</h2><h2>Color : {item.color}</h2>
    //                 <h2>Gear : {item.gear}</h2><h2>Construction Year : {item.construction_year}</h2>
    //                 <h2>Country : {item.country}</h2><h2>Power : {item.power}</h2></> : null}
    //         </DialogContent>
    //         <DialogActions>
    //             <Button onClick={handleDetails} color="primary">
    //                 Cancel
    //             </Button>
    //         </DialogActions>
    //     </Dialog>
    // );

    const StatusModal = ({ object: { item } }) => (
        <Dialog open={openStatusModal} onClose={handleStatusModal} aria-labelledby="form-dialog-title">
            <form onSubmit={changePostStatus}>
                <DialogTitle id="form-dialog-title">Change Post State</DialogTitle>
                <DialogContent>
                    <CardBody>

                        <div className="pl-lg-4">
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-address"
                                        >
                                            Select state
                                        </label>
                                        {item.post_type === "ARTICLE" ?
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleChangeStatus}
                                                id="exampleSelect">
                                                <option value="2">INTERESTED</option>
                                                <option value="3">SOLD</option>
                                                <option value="1">CANCEL</option>
                                            </Input> : null}
                                        {item.post_type === "VEHICLE" ?
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleChangeStatus}
                                                id="exampleSelect">
                                                <option value="2">INTERESTED</option>
                                                <option value="3">SOLD</option>
                                                <option value="4">EMBARKED</option>
                                                <option value="5">ARRIVED</option>
                                                <option value="6">FINISHED</option>
                                                <option value="7">RELEASED</option>
                                                <option value="1">CANCEL</option>
                                            </Input> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusModal} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        Change
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );


    return (
        <>
            <Dialog open={openDeleteModal} onClose={handleDeleteModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Delete Post</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this post ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={deletePost}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openDeactivateModal} onClose={handleDeactivateModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Deactivate Post</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to deactivate this post ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeactivateModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={deactivatePost}
                            color="danger"
                        >
                            Deactivate
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openActivateModal} onClose={handleActivateModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Deactivate Post</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to activate this post ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleActivateModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={activatePost}
                            color="primary"
                        >
                            Activate
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {openStatusModal ? <StatusModal object={activeObject} /> : null}

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <JobHeader handleVehicle={handleVehicle} off={off} />
            {/* Page content */}

            {openVehicle ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Post a new Vehicle for sell</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleVehicle}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit3(saveVehicle)}>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Name (Brand + Model)
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-address"
                                                        placeholder="Name here ..."
                                                        type="text"
                                                        {...restTitle3}
                                                        innerRef={title3}
                                                    />
                                                    {errors.title && <FormHelperText style={{ color: 'red' }}>Atleats 5 caracters are required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <Tooltip title="Description Informations" placement="right">
                                                    <i className="fa fa-info-circle fa-2x" aria-hidden="true" onClick={handleDescription}></i>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label>Description <span style={{ color: 'red' }}>*</span></label>
                                                    <textarea
                                                        style={inputStyle}
                                                        className="input"

                                                        id="textarea"
                                                        onChange={(e) => {
                                                            updateMarkdown(e)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label>Preview </label>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: marked(hastagHighlight(markdown)),
                                                        }}
                                                    ></div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Picker  onEmojiClick={onEmojiClick}/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Mileage
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="10"
                                                        type="number"
                                                        {...restMileage3}
                                                        innerRef={mileage3}
                                                    />
                                                    {errors.mileage && <FormHelperText style={{ color: 'red' }}>Mileage must be positive !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Year of Construction
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        placeholder="2016"
                                                        type="number"
                                                        {...restConstructionYear3}
                                                        innerRef={construction_year3}
                                                    />
                                                    {errors.construction_year && <FormHelperText style={{ color: 'red' }}>Year must be positive and in the past !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Gear Type
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="select"
                                                        name="select"
                                                        {...restGear3}
                                                        innerRef={Gear3}>
                                                        <option>Manual</option>
                                                        <option>Auto</option>
                                                        <option>Hybrid</option>
                                                    </Input>
                                                    {errors.gear && <FormHelperText style={{ color: 'red' }}>Gear is required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Price
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        placeholder="2300"
                                                        type="number"
                                                        {...restUnitPrice3}
                                                        innerRef={unit_price3}
                                                    />
                                                    {errors.unit_price && <FormHelperText style={{ color: 'red' }}>Price must be positive !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Number of Doors
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        placeholder="5"
                                                        type="number"
                                                        {...restNumberOfDoors}
                                                        innerRef={number_of_doors3}
                                                    />
                                                    {errors.number_of_doors && <FormHelperText style={{ color: 'red' }}>Number of doors must be positive !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Color
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        placeholder="Red"
                                                        type="text"
                                                        {...restColor3}
                                                        innerRef={color3}
                                                    />
                                                     {errors.color && <FormHelperText style={{ color: 'red' }}>Color is required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Power
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        placeholder="25"
                                                        type="number"
                                                        {...restPower3}
                                                        innerRef={power3}
                                                    />
                                                    {errors.power && <FormHelperText style={{ color: 'red' }}>Power must be positive !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Country of Origin
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        placeholder="USA"
                                                        type="text"
                                                        {...restCountry3}
                                                        innerRef={country3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Town where available
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        placeholder="Tokyo"
                                                        type="text"
                                                        {...restTown3}
                                                        innerRef={town3}
                                                    />
                                                    {errors.town && <FormHelperText style={{ color: 'red' }}>Towm is required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Add Images</h6>

                                    <div>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={inputRef}
                                            onChange={handleUploadImage} />
                                    </div>

                                    <div className="images">
                                        {
                                            images?.map((item) =>
                                                <ImageComponent
                                                    key={item.key}
                                                    value={item.url}
                                                    onRemoveElement={() => handleRemoveImage(item.key)}
                                                />
                                            )
                                        }
                                        <div className="pic" onClick={handleClickImageInput}>
                                            ADD IMAGE
                                        </div>
                                    </div>

                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Add Videos</h6>

                                    <div>
                                        <input
                                            type="file"
                                            accept="video/*"
                                            style={{ display: 'none' }}
                                            ref={videoRef}
                                            onChange={handleUploadVideo} />
                                    </div>

                                    <div className="videos">
                                        {
                                            videos?.map((item) =>
                                                <div className="vid"
                                                >
                                                    <Player
                                                        key={item.key}
                                                        playsInline
                                                        src={item.url}
                                                        fluid={false}
                                                        width={250}
                                                        height={93}
                                                    />
                                                    <span onClick={() => handleRemoveVideo(item.key)}>remove</span>
                                                </div>
                                            )
                                        }
                                        <div className="med" onClick={handleClickVideoInput}>
                                            ADD VIDEO
                                        </div>
                                    </div>

                                    {progress > 0 && progress < 100 ?
                                        <div className="pl-lg-4">
                                            <div className="text-center">{progress}%</div>
                                            <Progress value={progress} />
                                        </div> : ''
                                    }


                                    <div className="pl-lg-4">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                {/* <h3 className="mb-0"></h3> */}
                                            </Col>
                                            <Col className="text-right" xs="4">
                                            {submited ?
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    size="sm"
                                                    disabled
                                                >
                                                    Submit
                                                </Button>
                                                :
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    size="sm"
                                                >
                                                    Submit
                                                </Button>
                                            }
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}
            {openEditVehicle ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Edit Vehicle</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleEditVehicle}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit3(editVehicle)}>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Name (Brand + Model)
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.title}
                                                        className="form-control-alternative"
                                                        id="input-address"
                                                        type="text"
                                                        {...restTitle3}
                                                        innerRef={title3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Mileage
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.mileage}
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        type="text"
                                                        {...restMileage3}
                                                        innerRef={mileage3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Year of Construction
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.construction_year}
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        type="number"
                                                        {...restConstructionYear3}
                                                        innerRef={construction_year3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Gear Type
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="select"
                                                        {...restGear3}
                                                        innerRef={Gear3}>
                                                        <option>Manual</option>
                                                        <option>Auto</option>
                                                        <option>Hybrid</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Price
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.unit_price}
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        type="number"
                                                        {...restUnitPrice3}
                                                        innerRef={unit_price3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Number of Doors
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.number_of_doors}
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="number"
                                                        {...restNumberOfDoors}
                                                        innerRef={number_of_doors3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Color
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.color}
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="text"
                                                        {...restColor3}
                                                        innerRef={color3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Power
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.power}
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        type="text"
                                                        {...restPower3}
                                                        innerRef={power3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Country of Origin
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.country}
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="text"
                                                        {...restCountry3}
                                                        innerRef={country3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Town where available
                                                    </label>
                                                    <Input
                                                        defaultValue={formItem.town}
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="text"
                                                        {...restTown3}
                                                        innerRef={town3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label>Description <span style={{ color: 'red' }}>*</span></label>
                                                    <textarea
                                                        style={inputStyle}
                                                        id="textareaUpdate"
                                                        className="input"
                                                        value={markdownUpdated}
                                                        onChange={(e) => {
                                                            setMarkdownUpdated(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label>Preview </label>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: marked(markdownUpdated),
                                                        }}
                                                    ></div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Picker  onEmojiClick={onUpdateEmojiClick}/>
                                            </Col>
                                        </Row>
                                    </div>

                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Add Images</h6>

                                    <div className="all-input-files">
                                        <input
                                            id="file0"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={inputRef}
                                            onChange={handleUploadImage} />
                                    </div>

                                    <div className="images">
                                        {
                                            savedImages?.map((item) =>
                                                <ImageComponent
                                                    key={item.id}
                                                    value={MEDIA_URL + item.path}
                                                    onRemoveElement={() => removeToDeleteImage(item.id)}
                                                />
                                            )
                                        }
                                        {
                                            images?.map((item) =>
                                                <ImageComponent
                                                    key={item.key}
                                                    value={item.url}
                                                    onRemoveElement={() => handleRemoveImage(item.key)}
                                                />
                                            )
                                        }
                                        <div className="pic" onClick={handleClickImageInput}>
                                            ADD IMAGE
                                        </div>
                                    </div>

                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Add Videos</h6>

                                    <div>
                                        <input
                                            type="file"
                                            accept="video/*"
                                            style={{ display: 'none' }}
                                            ref={videoRef}
                                            onChange={handleUploadVideo} />
                                    </div>

                                    <div className="videos">
                                        {
                                            savedVideos?.map((item) =>
                                                <div className="vid">
                                                    <Player
                                                        key={item.id}
                                                        playsInline
                                                        src={MEDIA_URL + item.path}
                                                        fluid={false}
                                                        width={250}
                                                        height={93}
                                                    />
                                                    <span onClick={() => removeToDeleteVideo(item.id)}>remove</span>
                                                </div>
                                            )
                                        }
                                        {
                                            videos?.map((item) =>
                                                <div className="vid">
                                                    <Player
                                                        key={item.key}
                                                        playsInline
                                                        src={item.url}
                                                        fluid={false}
                                                        width={250}
                                                        height={93}
                                                    />
                                                    <span onClick={() => handleRemoveVideo(item.key)}>remove</span>
                                                </div>
                                            )
                                        }
                                        <div className="med" onClick={handleClickVideoInput}>
                                            ADD VIDEO
                                        </div>
                                    </div>

                                    {progress > 0 && progress < 100 ?
                                        <div className="pl-lg-4">
                                            <div className="text-center">{progress}%</div>
                                            <Progress value={progress} />
                                        </div> : ''
                                    }


                                    <div className="pl-lg-4">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                {/* <h3 className="mb-0"></h3> */}
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                {submited ?
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        size="sm"
                                                        disabled
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        size="sm"
                                                    >
                                                        Submit
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>: ""}
            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Your Posts Here </h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Title / Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Is Active</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        posts?.map((item) =>
                                            <tr key={item.id}>
                                                <th scope="row">
                                                    <Media className="align-items-center">
                                                        <Media>
                                                            <span className="mb-0 text-sm">
                                                                {item.post_type}
                                                            </span>
                                                        </Media>
                                                    </Media>
                                                </th>
                                                <td>{item.title}</td>
                                                <td>

                                                    {item.status}
                                                </td>
                                                <td>
                                                    {item.is_active == 1 ?
                                                        <Badge color="" className="badge-dot mr-4">
                                                            <i className="bg-primary" />
                                                            Active
                                                        </Badge> : <Badge color="" className="badge-dot mr-4">
                                                            <i className="bg-warning" />
                                                            Deactivated
                                                        </Badge>
                                                    }

                                                </td>
                                                <td>
                                                {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                                </td>
                                                <td className="text-right">
                                                    <div className="d-flex px-2 py-1">
                                                        <Tooltip title="Edit" placement="top">
                                                            <span
                                                                className="material-icons"
                                                                onClick={() => {
                                                                    setActiveObject({ item });
                                                                    setFormItem(item);
                                                                    setMarkdownUpdated(item.description);
                                                                    setSavedImages(item.images);
                                                                    setSavedVideos(item.videos);
                                                                    handleEditVehicle();
                                                                }}
                                                                style={{ cursor: 'pointer', color: 'orange' }}>edit
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" placement="top">
                                                            <span
                                                                className='material-icons'
                                                                onClick={() => {
                                                                    setActiveObject({ item });
                                                                    handleDeleteModal();
                                                                }}
                                                                style={{ cursor: 'pointer', color: 'firebrick' }}>
                                                                delete
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="View Details" placement="top">
                                                            <span
                                                                className='material-icons danger'
                                                                onClick={() => {
                                                                    setActiveObject({ item });
                                                                    handleDetails();
                                                                }}
                                                                style={{ cursor: 'pointer', color: 'dodgerblue' }}>
                                                                visibility
                                                            </span>
                                                        </Tooltip>
                                                        {item.is_active == 1 ?
                                                            <Tooltip title="Deactivate" placement="top">
                                                                <span
                                                                    className='material-icons danger'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleDeactivateModal();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'red' }}>
                                                                    close
                                                                </span>
                                                            </Tooltip> : 
                                                            <Tooltip title="Activate" placement="top">
                                                                <span
                                                                    className='material-icons danger'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleActivateModal();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'green' }}>
                                                                    check
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                        {item.post_type === "VEHICLE" ? null :
                                                            <Tooltip title="Change Status" placement="top">
                                                                <span
                                                                    className='material-icons danger'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleStatusModal();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'pink' }}>
                                                                    build
                                                                </span>
                                                            </Tooltip>
                                                        }

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
            {openDetails ? <DetailModal object={activeObject} /> : null}
            {openDescriptions ? <DescriptionInfos /> : null}
        </>
    );
};

export default Vehicles;
