import axios from "axios";


export function getJobs() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/jobs')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedJobs(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/jobs/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/jobs/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}


export const createJob = (data, qualifications, expectations, benefits, formattedDescription) => {

    console.log(data.start_date);

    let jobFormData = new FormData();

    jobFormData.append("title", data.title);
    jobFormData.append("domain", data.domain);
    jobFormData.append("description", formattedDescription);
    jobFormData.append("salary_start", data.salary_start);
    jobFormData.append("salary_end", data.salary_end);

    if(data.end_date){
        jobFormData.append("end_date", data.end_date);
    }
    if(data.start_date){
        jobFormData.append("start_date", data.start_date);
    }

    //jobFormData.append("end_date", data.end_date === undefined || null ? null : data.end_date);
    //jobFormData.append("start_date", data.start_date === undefined || null ? null : data.start_date);
    jobFormData.append("type", data.type);
    jobFormData.append("contact_first_name", data.contact_first_name);
    jobFormData.append("contact_last_name", data.contact_last_name);
    jobFormData.append("contact_email", data.contact_email);
    jobFormData.append("contact_phone", data.contact_phone);
    jobFormData.append("qualifications", qualifications.map(item => item.element));
    jobFormData.append("expectations", expectations.map(item => item.element));
    jobFormData.append("benefits", benefits.map(item => item.element));

    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                //updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/create/job`, jobFormData,  {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                //updateProgress(100);
                console.log(response.data.data);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const updateJob = (job_id, data, qualifications, expectations, benefits, formattedDescription) => {

    let jobFormData = new FormData();

    jobFormData.append("title", data.title);
    jobFormData.append("domain", data.domain);
    jobFormData.append("description", formattedDescription);
    jobFormData.append("salary_start", data.salary_start);
    jobFormData.append("salary_end", data.salary_end);
    if(data.end_date){
        jobFormData.append("end_date", data.end_date);
    }
    if(data.start_date){
        jobFormData.append("start_date", data.start_date);
    }
    jobFormData.append("type", data.type);
    jobFormData.append("contact_first_name", data.contact_first_name);
    jobFormData.append("contact_last_name", data.contact_last_name);
    jobFormData.append("contact_email", data.contact_email);
    jobFormData.append("contact_phone", data.contact_phone);
    jobFormData.append("qualifications", qualifications.map(item => item.element));
    jobFormData.append("expectations", expectations.map(item => item.element));
    jobFormData.append("benefits", benefits.map(item => item.element));

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/job/${job_id}`, jobFormData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteJob(post_id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/job/${post_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getAllPaginatedApplications(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/jobs/applications/pagesize?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/jobs/applications/pagesize', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function getJobApplication(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/job/${id}/applications`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}