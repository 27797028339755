import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Media,
  Row,
  Table,
} from "reactstrap";

// core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import CategoryHeader from "components/Headers/CategoryHeader";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import {
  addCategeory,
  createCategory,
  deleteCategeory,
  deleteSelectedCategeory,
  getCategories,
  getDescentCategories,
  getPaginatedCategories,
  getSelectedCategories,
  updateCategory,
} from "services/CategoryServices";
import env from "ts-react-dotenv";
import { v4 as uuidv4 } from "uuid";
import "video-react/dist/video-react.css";
import { computeNumerotation } from "../../constants";
import "./filestyle.css";
import "./videostyle.css";

const MEDIA_URL = env.MEDIA_URL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedFeatures, theme) {
  return {
    fontWeight:
      selectedFeatures.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Category = () => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { ref: name, ...restName } = register("name", {
    required: true,
    minLength: 5,
  });
  const { ref: description, ...restDescription } = register("description", {
    required: true,
    minLength: 10,
  });
  const { ref: type, ...restType } = register("type", {
    required: "select one option"
  });
  const { ref: parent_category, ...restParentCategory } =
    register("parent_category");

  const { user } = useContext(AuthContext);
  const [openCategory, setOpenCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openTable, setOpenTable] = useState(true);
  const [off, setOff] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [remainingCategories, setRemainingCategories] = useState([]);
  const [selectedChildCategories, setSelectedChildCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({
    id: 0,
    name: "",
    description: "",
    type: "",
    parent_id: 0,
  });
  const [chosenParentCategory, setChosenParentCategory] = useState(0);
  const [parentCategory, setParentCategory] = useState(0);
  const [selectedType, setSelectedType] = useState("1");
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [featuresToLoad, setFeaturesToLoad] = useState([]);
  const inputRef = useRef(null);
  const inputERef = useRef(null);
  const [image, setImage] = useState(null);
  const [eImage, setEImage] = useState(null);
  const [deleteImage, setDeleteImage] = useState(false);

  const handleSelectFeature = (event) => {
    setSelectedFeatures(event.target.value);
  };

  const handleSelectChildCategory = (event) => {
    setSelectedChildCategories(event.target.value);
    //fetchFeatures(event.target.value);
  };

  const fetchFeatures = async (selectedCats) => {
    console.log(selectedCats);
    const myCategories = categories.filter((cat) => {
      return selectedCats.some((id) => {
        return id === cat.id;
      });
    });

    let myFeatures = [];
    for (let category of myCategories) {
      let cats = await getDescentCategories(category.id);
    }

    const ids = myFeatures.map((obj) => obj.id);
    const uniqueFeatures = myFeatures.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let featureArray = [];

    for (var i in uniqueFeatures) {
      var item = uniqueFeatures[i];
      featureArray.push(item);
    }
    setFeaturesToLoad(featureArray);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
  };

  const retrieveCategories = async () => {
    let data = await getCategories();
    setCategories(data);
  };

  const retrieveSelectedCategories = async () => {
    let data = await getSelectedCategories();
    setSelectedCategories(data);
  };

  const isSelected = (data) => {
    let res = selectedCategories.filter((ac) => ac.category_id === data.id);
    console.log(res);
    return res.length > 0;
  };

  const handleCategory = () => {
    // console.log("i am in handle Category");
    setOpenCategory(!openCategory);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setSelectedChildCategories([]);
    setCategories([]);
    setCurrentCategory((prevState) => ({
      ...prevState,
      id: 0,
      name: "",
      description: "",
      type: "",
      parent_id: 0,
    }));
    setChosenParentCategory(0);
  };

  const fetchRemainingCategories = async (category_id) => {
    let childs = await getDescentCategories(category_id);

    const results = categories.filter(
      ({ id: id1 }) => !childs.some(({ id: id2 }) => id2 === id1)
    );
    setRemainingCategories(results);
  };

  const handleEditCategory = () => {
    setOpenEditCategory(!openEditCategory);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const closeEditCategory = () => {
    setOpenEditCategory(!openEditCategory);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setSelectedChildCategories([]);
    setCategories([]);
    setRemainingCategories([]);
    setImage(null);
    setCurrentCategory((prevState) => ({
      ...prevState,
      id: 0,
      name: "",
      description: "",
      type: "",
      parent_id: 0,
    }));
    setParentCategory(0);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleChoseParent = (e) => {
    setChosenParentCategory(e.target.value);
  };

  const handleParentCategory = (e) => {
    setParentCategory(e.target.value);
  };

  const handleChangeInput = (e) => {
    setCurrentCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickImageInput = () => {
    inputRef.current.click();
  };

  const handleClickEImageInput = () => {
    inputERef.current.click();
  };

  const handleUploadImage = (event) => {
    var img = event.target.files[0];
    var fSize = Math.round(img.size / 1024);
    if (fSize > 5120) {
      setAlertMessage("Image size should be less than 5Mo");
      setAlertType("error");
      handleOpenAlert();
    } else {
      let fileID = uuidv4() + "." + img.name.split(".").pop();
      let imageUrl = URL.createObjectURL(img);
      setImage({ key: fileID, url: imageUrl, file: img });
    }
    event.target.value = null;
  };

  const handleUploadEImage = (event) => {
    var img = event.target.files[0];
    var fSize = Math.round(img.size / 1024);
    if (fSize > 5120) {
      setAlertMessage("Image size should be less than 5Mo");
      setAlertType("error");
      handleOpenAlert();
    } else {
      let fileID = uuidv4() + "." + img.name.split(".").pop();
      let imageUrl = URL.createObjectURL(img);
      setEImage({ key: fileID, url: imageUrl, file: img });
    }
    event.target.value = null;
  };

  const handleRemoveImage = () => {
    setImage(null);
    setDeleteImage(true);
  };

  const handleRemoveEImage = () => {
    setEImage(null);
  };

  const saveCategory = async (data) => {
    console.log(selectedFeatures);
    setSubmited(true);

    try {
      await createCategory(data);
      setOpenCategory(!openCategory);
      setOpenTable(!openTable);
      setOff(!off);

      retrieveCategories();

      setAlertMessage("Category created sucessfully");
      setAlertType("success");
      handleOpenAlert();
      reset();
      setSelectedChildCategories([]);
      setChosenParentCategory(0);
      setCurrentCategory((prevState) => ({
        ...prevState,
        id: 0,
        name: "",
        description: "",
        type: "",
        parent_id: 0,
      }));
      setChosenParentCategory(0);
      setSubmited(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
      setSubmited(false);
    }
  };

  const editCategory = async (data) => {
    setSubmited(true);

    let category_id = activeObject.item.id;

    try {
      await updateCategory(data, category_id);

      setOpenEditCategory(!openEditCategory);
      setOpenTable(!openTable);
      setOff(!off);
      retrieveCategories();

      setAlertMessage("Category updated sucessfully");
      setAlertType("success");
      handleOpenAlert();
      reset();
      setSelectedChildCategories([]);
      setCategories([]);
      setSelectedFeatures([]);
      setFeatures([]);
      setFeaturesToLoad([]);
      setRemainingCategories([]);
      setCurrentCategory((prevState) => ({
        ...prevState,
        id: 0,
        name: "",
        description: "",
        type: "",
        parent_id: 0,
      }));
      setParentCategory(0);
      setSubmited(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
      setSubmited(false);
    }
  };

  const delCategory = async () => {
    setOpenDeleteModal(false);
    let category_id = activeObject.item.id;

    try {
      await deleteCategeory(category_id);

      setAlertMessage("Category deleted sucessfully");
      setAlertType("success");
      handleOpenAlert();

      retrieveCategories();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const selectCategory = async (category_id) => {
    console.log("lid " + category_id);
    //return;
    try {
      await addCategeory(category_id);

      setAlertMessage("Category selected successfully");
      setAlertType("success");
      handleOpenAlert();
      await retrieveSelectedCategories();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const removeCategoryHome = async (category_id) => {
    console.log("lid " + category_id);
    //return;
    try {
      await deleteSelectedCategeory(category_id);

      setAlertMessage("Category removed successfully");
      setAlertType("success");
      handleOpenAlert();
      await retrieveSelectedCategories();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const retrieveCategoriesPaginated = async (page) => {
    let response = await getPaginatedCategories(page, itemPerPage);
    setCategories(response.data);
    setCurrentPage(response.meta.current_page);
  };

  const handlePageClick = (data) => {
    const requestedPage = data.selected + 1;
    retrieveCategoriesPaginated(requestedPage);
  };

  const handleSearch = async (e) => {
    let nb = e.target.value;
    setItemPerPage(nb);
    let response = await getPaginatedCategories(undefined, parseInt(nb));
    setCategories(response.data);
    setLastPage(response.meta.last_page);
    setCurrentPage(response.meta.current_page);
  };

  useEffect(() => {
    const retrieveCategories = async () => {
      let response = await getPaginatedCategories();
      setLastPage(response.meta.last_page);
      setCurrentPage(response.meta.current_page);
      console.log(response.data);
      setCategories(response.data);
      setLoading(false);
    };

    //const retrieveFeatures = async () => {
    //    let data = await getFeatures();
    //    setFeatures(data);
    //}
    //retrieveSelectedCategories();
    //retrieveChildCategories();
    //retrieveFeatures();
    retrieveCategories();
  }, [currentCategory]);

  const DetailModal = ({ object: { item } }) => (
    <Dialog
      open={openDetails}
      onClose={handleDetails}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Category details</DialogTitle>
      <DialogContent>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <td></td>
                  <td>Name</td>
                  <td> {item.name}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Description</td>
                  <td> {item.description} </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Association</td>
                  <td> {item.type} </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span>Created at</span>
                  </td>
                  <td>
                    {" "}
                    {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDetails} color="danger">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle id="form-dialog-title">Delete Category</DialogTitle>
          <DialogContent>
            <h3 className="mb-0">
              Are you sure you want to delete this category ?
            </h3>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteModal} color="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={delCategory} color="danger">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <CategoryHeader handleCategory={handleCategory} off={off} />
      {/* Page content */}

      {openCategory ? (
        <Container className="mt--7 mb-3" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Create a new category</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button color="danger" onClick={handleCategory} size="sm">
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit(saveCategory)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup error={errors.name ? true : undefined}>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Name here ..."
                              {...restName}
                              innerRef={name}
                              aria-invalid={errors.name ? "true" : "false"}
                            />
                            {errors.name && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup
                            error={errors.description ? true : undefined}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Description{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              type="textarea"
                              className="form-control-alternative"
                              placeholder="Description here ..."
                              {...restDescription}
                              innerRef={description}
                              rows={3}
                              aria-invalid={
                                errors.description ? "true" : "false"
                              }
                            />

                            {errors.description && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 10 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4 mb-3">
                      <Row>
                        <Col md="12">
                          <FormControl sx={{ width: "100%" }}>
                            <label className="form-control-label">
                              Select Parent Category
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="select"
                              {...restParentCategory}
                              innerRef={parent_category}
                              onChange={handleChoseParent}
                              aria-invalid={
                                errors.parent_category ? "true" : "false"
                              }
                            >
                              <option value="0">
                                --Select Parent Category--
                              </option>
                              {categories.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                          </FormControl>
                        </Col>
                      </Row>
                    </div>
                    {chosenParentCategory == "0" && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup error={errors.type ? true : undefined}>
                              <label className="form-control-label">
                                Association
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                type="select"
                                {...restType}
                                innerRef={type}
                                aria-invalid={errors.type ? "true" : "false"}
                              >
                                <option value="">
                                  --Select Category Association--
                                </option>
                                <option value="1">ARTICLE</option>
                                <option value="2">NEWS</option>
                              </Input>
                              {errors.type && (
                                <FormHelperText style={{ color: "red" }}>
                                  Select one association !
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <hr className="my-4 mt-3" />

                    <div className="pl-lg-4 mt-3">
                      <Row className="align-items-center">
                        <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                        <Col className="text-right" xs="4">
                          {submited ? (
                            <Button
                              color="primary"
                              type="submit"
                              size="sm"
                              disabled
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button color="primary" type="submit" size="sm">
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}

      {openEditCategory ? (
        <Container className="mt--7 mb-3" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Category Information</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="danger"
                        onClick={closeEditCategory}
                        size="sm"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit(editCategory)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Name
                            </label>
                            <Input
                              name="name"
                              value={currentCategory.name}
                              className="form-control-alternative"
                              {...restName}
                              innerRef={name}
                              onChange={handleChangeInput}
                            />
                            {errors.name && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              value={currentCategory.description}
                              className="form-control-alternative"
                              {...restDescription}
                              innerRef={description}
                              onChange={handleChangeInput}
                            />
                            {errors.description && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup error={errors.type ? true : undefined}>
                            <label className="form-control-label">
                              Select Parent Category
                            </label>

                            <Input
                              className="form-control-alternative"
                              type="select"
                              {...restParentCategory}
                              innerRef={parent_category}
                              onChange={handleParentCategory}
                              aria-invalid={
                                errors.parent_category ? "true" : "false"
                              }
                            >
                              <option value="0">
                                --Select Parent Category--
                              </option>
                              {categories.map((item) => (
                                <option
                                  selected={
                                    currentCategory.parent_id == item.id
                                  }
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {errors.parent_category && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {parentCategory == "0" && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup error={errors.type ? true : undefined}>
                              <label className="form-control-label">
                                Association
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                type="select"
                                name="association"
                                {...restType}
                                innerRef={type}
                                aria-invalid={errors.type ? "true" : "false"}
                                onChange={handleChangeInput}
                              >
                                <option
                                  value="1"
                                  selected={
                                    currentCategory.type == "ARTICLE" ||
                                    currentCategory.type == "1"
                                  }
                                >
                                  ARTICLE
                                </option>
                                <option
                                  value="2"
                                  selected={
                                    currentCategory.type == "ACTUALITE" ||
                                    currentCategory.type == "2"
                                  }
                                >
                                  NEWS
                                </option>
                              </Input>
                              {errors.type && (
                                <FormHelperText style={{ color: "red" }}>
                                  At least 5 characters are required !
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <hr className="my-4" />

                    <div className="pl-lg-4">
                      <Row className="align-items-center">
                        <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                        <Col className="text-right" xs="4">
                          {submited ? (
                            <Button
                              color="primary"
                              type="submit"
                              size="sm"
                              disabled
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button color="primary" type="submit" size="sm">
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}

      {openTable ? (
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All Your Categories Here </h3>
                </CardHeader>
                {isLoading ? (
                  <ReactLoading type={"spin"} color="#B20101" />
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Association</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories?.map((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {computeNumerotation(
                                currentPage,
                                itemPerPage,
                                index
                              )}
                            </span>
                          </th>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {item.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{item.description}</td>
                          <td>{item.type}</td>
                          <td>
                            {moment(item.created_at).format(
                              "YYYY/MM/DD kk:mm:ss"
                            )}
                          </td>
                          <td className="text-right">
                            <div className="d-flex px-2 py-1">
                              <Tooltip title="Edit" placement="top">
                                <span
                                  className="material-icons"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    setCurrentCategory((prevState) => ({
                                      ...prevState,
                                      id: item.id,
                                      name: item.name,
                                      description: item.description,
                                      type: item.type,
                                      parent_id: item.parent_id,
                                    }));
                                    handleEditCategory();
                                  }}
                                  style={{ cursor: "pointer", color: "orange" }}
                                >
                                  edit
                                </span>
                              </Tooltip>
                              <Tooltip title="View Details" placement="top">
                                <span
                                  className="material-icons danger"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    handleDetails();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "dodgerblue",
                                  }}
                                >
                                  visibility
                                </span>
                              </Tooltip>
                              <Tooltip title="Delete" placement="top">
                                <span
                                  className="material-icons"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    handleDeleteModal();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "firebrick",
                                  }}
                                >
                                  delete
                                </span>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <CardFooter className="py-4">
                  <Row>
                    <Col>
                      <Form>
                        <FormGroup className="mb-0" style={{ width: "100px" }}>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25" selected>
                              25
                            </option>
                          </select>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col>
                      <nav aria-label="...">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={lastPage}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-end mb-0"
                          }
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          pageLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        ""
      )}
      {openDetails ? <DetailModal object={activeObject} /> : null}
    </>
  );
};

export default Category;
