import React, { createContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { me } from '../services/UserServices';

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const history = useHistory();
  const [connected, setConnected] = useState(false);
  //const socket = useRef(null);

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setConnected(false);
    history.push("/auth/login");
  };

  const afterLogin = async () => {
    if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
      try {
        let result = await me();
        setUser(result.data.data);
        setConnected(true);
        //history.push("/admin/user-profile");
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
        try {
          let result = await me();
          setUser(result.data.data);
          setConnected(true);
          /*
          * This is the instruction that make the page to return to the dashboard when the page is refreshed*/
          //history.push("/admin/index");
        } catch (error) {
        }
      } else {
        setConnected(false);
      }
    };

    fetchUser();

  }, []);

  return (
    <AuthContext.Provider value={{ connected, user, logOut, afterLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
