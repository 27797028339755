import axios from "axios";

export const me = () => {

  return new Promise((resolve, reject) => {
    axios
      .get('/me')
      .then( response => {

        if(response.data){

        resolve(response);
        }else {
          throw new Error('USER NOT FOUND');
        }
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

export function getAll() {
    return new Promise((resolve, reject) => {
        axios
            .get('/admins')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function getAllUsers() {
    return new Promise((resolve, reject) => {
        axios
            .get('/users')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};


export const forgot = (data) => {
  let emailFormData = new FormData();

  emailFormData.append("email", data.email);

  console.log(emailFormData);
  return new Promise((resolve, reject) => {
    axios
      .post('/forgetpassword', emailFormData)
      .then( response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response.data);
      });
    });
};

export const reset = (data) => {

  let resetFormData = new FormData();

  resetFormData.append("email", data.email);
  resetFormData.append("password", data.password);
  resetFormData.append("password_confirmation", data.password_confirmation);
  resetFormData.append("token", data.token);

  return new Promise((resolve, reject) => {
    axios
      .post('/resetpassword', resetFormData)
      .then( response => {
        console.log(response.data);

        resolve(response);
      })
      .catch(error => {
        reject(error.response.data);
      });
    });
  };

export function bannishUser(id) {
    return new Promise((resolve, reject) => {
        axios
            .put('/status/user/'+`${id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getNotifications(user_id) {

  console.log(user_id);

  return new Promise((resolve, reject) => {
      axios
          .get(`/user/${user_id}/notifications`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

}

export function clearNotif(user_id) {

  return new Promise((resolve, reject) => {
      axios
          .put(`/user/${user_id}/notifications/read`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

};

export function readNotifByUserAndChat(user_id, chat_id) {

  return new Promise((resolve, reject) => {
      axios
          .put(`/user/${user_id}/chat/${chat_id}/notifications/read`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

};

export function readNotif(id) {

  return new Promise((resolve, reject) => {
      axios
          .delete(`/notification/${id}/markread`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

}

export function countNotif(user_id) {

  return new Promise((resolve, reject) => {
      axios
          .get(`/user/${user_id}/notifications/count/unread`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });

};

export function deleteNotif(user_id) {

  return new Promise((resolve, reject) => {
      axios
          .delete(`/user/${user_id}/notifications/clear`)
          .then( response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });}

//     export function purcharges(id) {
//
//         return new Promise((resolve, reject) => {
//             axios
//                 .get(`/user/${id}/purchases`)
//                 .then( response => {
//                     resolve(response.data.data);
//                 })
//                 .catch(error => {
//                     reject(error.response.data);
//                 });
//         });
// }

export function purchases(id, pageId, nb_of_element) {
     const data = {numberElement: nb_of_element}

   if(pageId !== undefined && pageId !== null){
    return new Promise((resolve, reject) => {
        axios

            .post(`/user/${id}/purchases?page=${pageId}`, data)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
   }else {
       return new Promise((resolve, reject) => {
           axios
               .post(`/user/${id}/purchases`, data)
               .then( response => {
                   resolve(response.data.data);
               })
               .catch(error => {
                   reject(error.response.data);
               });
       });
    }

}
