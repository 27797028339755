
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col, Badge,
} from "reactstrap";

import React, {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { connection } from '../../../services/AuthServices';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputBase from "@material-ui/core/InputBase";
import { AuthContext } from "context/AuthContext";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//import { CLIENT_URL } from "../../../constants";
import env from "ts-react-dotenv";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

function Login(props) {

  const {register, handleSubmit} = useForm();
  const { afterLogin } = useContext(AuthContext);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const loginUser = async (data) => {

    console.log("on entre avec les datas")
    console.log(data);

      try {
        await connection(data);

        setAlertMessage("connected sucessfully");
        setAlertType("success");
        handleOpenAlert();
        afterLogin();
        //props.history.push("/admin/index");
        props.history.push("/admin/user-profile");
      } catch (error) {
        console.log(error);
        setAlertMessage(error.data.message);
        setAlertType("error");
        handleOpenAlert();
      }
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   console.log("voici l'adresse "+process.env.REACT_APP_BACKEND_SERVICE)
  // });
  return (
    <>
      <Snackbar anchorOrigin={{ vertical:"top", horizontal:"right"}} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
           <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with Credentials</small>
            </div>
{/*             <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div> */}
          </CardHeader> 
          <CardBody className="px-lg-5 py-lg-5">
{/*              <div className="text-center text-muted mb-4">
              <small>sign in with credentials</small>
            </div> */} 
            <Form role="form" onSubmit={handleSubmit(loginUser)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputBase
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    {...register('email')}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputBase
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    {...register('password')}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  name="remember"
                  {...register('remember')}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/auth/forgot"
              //onClick={(e) => {e.preventDefault(); return <Redirect to="/auth/forgot" />}}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href={CLIENT_URL}
              target="_blank"
              //onClick={(e) => e.preventDefault()}
            >
              <small>Go to Media Intelligence Main</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
