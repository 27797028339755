import Tooltip from "@material-ui/core/Tooltip";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate"; // Assuming this is the pagination component
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Table,
} from "reactstrap"; // Assuming Reactstrap is used for UI components
// Props should include all necessary data and methods used in the component
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";

const JobsTable = ({
  jobs,
  isLoading,
  goToJobApplications,
  startEditJob,
  setCurrentJob,
  setToEditQualifications,
  setToEditBenefits,
  setToEditExpectations,
  handleDeleteModal,
  lastPage,
}) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [previewedAnswer, setPreviewedAnswer] = useState("");
  const handleOpenPreview = (answer) => {
    setPreviewedAnswer(answer);
    setOpenPreview(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    overflowX: "auto",
  };


  const handleClosePreview = () => setOpenPreview(false);
  return (
    <Container className="mt--7" fluid>
      <Modal
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Preview
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: previewedAnswer,
                }}
              />
            </div>
          </Typography>
        </Box>
      </Modal>

      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">All Your Jobs Here</h3>
            </CardHeader>
            {isLoading ? (
              <ReactLoading type={"spin"} color="#B20101" />
            ) : (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Type</th>
                    <th scope="col">Domain</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{item.type}</td>
                      <td>{item.domain}</td>
                      <td className="text-right">
                        <div className="d-flex px-2 py-1">
                           <Tooltip title="Edit" placement="top">
                            <span
                              className="material-icons"
                              onClick={() => {
                                
                                setCurrentJob((prevState) => ({
                                  ...prevState,
                                  id: item.id,
                                  title: item.title,
                                  type: item.type,
                                  salary_start: item.salary_start,
                                  salary_end: item.salary_end,
                                  start_date: item.start_date,
                                  end_date: item.end_date,
                                  contact_first_name: item.contact_first_name,
                                  contact_last_name: item.contact_last_name,
                                  contact_email: item.contact_email,
                                  contact_phone: item.contact_phone,
                                  description: item.description,
                                  domain: item.domain,
                                  expectations: item.expectations,
                                  benefits: item.benefits,
                                  qualifications: item.qualifications,
                                }));
                                const qArray = item.qualifications.split(',');
                                qArray.forEach((qualification) => {
                                  const key = uuidv4();
                                  setToEditQualifications((prevQualifications) => [
                                      ...prevQualifications,
                                      { key: key, element: qualification }
                                  ]);
                                });
                                const bArray = item.benefits.split(',');
                                bArray.forEach((benefit) => {
                                  const key = uuidv4();
                                  setToEditBenefits((prevBenefits) => [
                                      ...prevBenefits,
                                      { key: key, element: benefit }
                                  ]);
                                });
                                const eArray = item.expectations.split(',');
                                eArray.forEach((expectation) => {
                                  const key = uuidv4();
                                  setToEditExpectations((prevExpectations) => [
                                      ...prevExpectations,
                                      { key: key, element: expectation }
                                  ]);
                                });
                                startEditJob(item);
                              }}
                              style={{ cursor: "pointer", color: "orange" }}
                            >
                              edit
                            </span>
                          </Tooltip>
                          <Tooltip title="See Applications" placement="top">
                                  <span
                                    className="material-icons danger"
                                    value={item.id}
                                    onClick={(e) => {
                                      goToJobApplications(e);
                                    }}
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    visibility
                                  </span>
                                </Tooltip>
                          <Tooltip title="Preview" placement="top">
                            <WysiwygIcon
                              onClick={() => handleOpenPreview(item.domain)}
                              style={{ cursor: "pointer", color: "#2D2D2D" }}
                            />
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <span
                              className="material-icons"
                              onClick={() => {
                                setCurrentJob((prevState) => ({
                                  ...prevState,
                                  id: item.id,
                                  title: item.title,
                                  type: item.type,
                                  salary_start: item.salary_start,
                                  salary_end: item.salary_end,
                                  start_date: item.start_date,
                                  end_date: item.end_date,
                                  contact_first_name: item.contact_first_name,
                                  contact_last_name: item.contact_last_name,
                                  contact_email: item.contact_email,
                                  contact_phone: item.contact_phone,
                                  description: item.description,
                                  domain: item.domain,
                                  expectations: item.expectations,
                                  benefits: item.benefits,
                                  qualifications: item.qualifications,
                                }));
                                handleDeleteModal();
                              }}
                              style={{ cursor: "pointer", color: "firebrick" }}
                            >
                              delete
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <CardFooter className="py-4">
              <Row>
                <Col>
                  <Form>
                    <FormGroup className="mb-0" style={{ width: "100px" }}>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          //handleSearch(e)
                        }}
                        defaultValue="25"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                      </select>
                    </FormGroup>
                  </Form>
                </Col>
                <Col>
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={lastPage}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                      onPageChange={""} // This should be passed as a prop or defined in this component
                      containerClassName={"pagination justify-content-end mb-0"}
                      pageClassName={"page-item"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      pageLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </nav>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default JobsTable;
