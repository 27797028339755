import React, { useEffect, useState } from "react";
import { download } from "services/PurchaseServices";


const InvoicePage = () => {

    const [html, setHTML] = useState({__html: ""});

    const queryParams = new URLSearchParams(window.location.search);

    let uid = queryParams.get('purchaseUid');
  
    useEffect(() => {

        async function createMarkup() {

            try {
                let invoice = await download(uid);
                console.log(invoice);
                return {__html: invoice};
    
              } catch (error) {
                console.log(error.message);
              }
           }
           createMarkup().then((result) => setHTML(result));
  
    }, []);

  


    return (
         <div dangerouslySetInnerHTML={html} />
    );
}

export default InvoicePage;
