import axios from "axios";

export const getCategory = (id) => {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedCategories(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/categories/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/categories/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export const getSubCategory = (id) => {

    return new Promise((resolve, reject) => {
        axios
            .get(`/sub-category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getSubCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/sub-categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedSubCategories(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/sub-categories/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/sub-categories/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function getChildCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/child/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getDescentCategories(id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}/descent`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getCategoriesWithoutChild(type) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/categories/without/child/${type}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const createCategory = (data) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/create/category`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const createSubCategory = (data) => {

    let toSend = new FormData();

    toSend.append("name", data.name);
    toSend.append("description", data.description);
    //toSend.append("category_id", category_id);
    toSend.append("type", data.type);


    return new Promise((resolve, reject) => {
        axios
            .post(`/create/sub-category`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const updateCategory = (data, categoryId) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/category/${categoryId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const updateSubCategory = (data, sub_category_id) => {

    let toSend = new FormData();

    toSend.append("name", data.name);
    toSend.append("description", data.description);
    //toSend.append("category_id", category_id);
    toSend.append("type", data.type);

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/sub-category/${sub_category_id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getCategoriesTypePaginated(type, id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate?page=${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function deleteCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteSubCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/sub-category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function addCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/add/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/selected/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteSelectedCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/category/${id}/selected`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}