
import { Button, Container, Row, Col } from "reactstrap";

const ActualiteHeader = ({ handleArticle, off }) => {

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "200px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/profile-cover.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              {off ?
                <Button
                  color="warning"
                  disabled
                  onClick={handleArticle}
                >
                  Add News
                </Button>
                :
                <Button
                  color="warning"
                  onClick={handleArticle}
                >
                  Add News
                </Button>
              }
              {/* <Link to="/admin/article-form" className="btn btn-primary">Sign up</Link> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ActualiteHeader;
