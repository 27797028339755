/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Button } from "reactstrap";
// core components
import FaqHeader from "components/Headers/FaqHeader.js";
import React, { useContext, useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AuthContext } from "context/AuthContext";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import {
  createFaq,
  deleteFaq,
  getFaqs,
  updateFaq,
} from "services/AdminServices";
import FaqBase from "./FaqBase";
import FaqsTable from "./FaqsTable";

const modules = {
  toolbar: [
    // Header options: choose between different header levels or no header
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // Font style options: bold, italic, underline, strike
    ["bold", "italic", "underline", "strike"],

    // Script/subscript/superscript: allows superscript or subscript text
    [{ script: "sub" }, { script: "super" }],

    // List options: ordered list or bullet list
    [{ list: "ordered" }, { list: "bullet" }],

    // Blockquote and code-block
    ["blockquote", "code-block"],

    // Text alignment options: left, center, right, justify
    [{ align: [] }],

    // Link, image, and video insertion
    ["link", "image", "video"],

    // Text color and background color

    [{ color: [] }, { background: [] }],

    // Font size options
    [{ size: ["small", false, "large", "huge"] }],

    // Dropdown for font family selection
    [{ font: [] }],

    // Indent and outdent options
    [{ indent: "-1" }, { indent: "+1" }],

    // Direction of the text
    [{ direction: "rtl" }],

    // Clean: removes formatting from the selected text
    ["clean"],
  ],
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Faq = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { ref: question, ...restQuestion } = register("question", {
    required: true,
    minLength: 5,
  });
  const { ref: answer, ...restAnswer } = register("answer", {
    required: true,
    minLength: 5,
  });

  const { user } = useContext(AuthContext);
  const [openFaq, setOpenFaq] = useState(false);
  const [openEditFaq, setOpenEditFaq] = useState(false);
  const [openTable, setOpenTable] = useState(true);
  const [off, setOff] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [lastPage, setLastPage] = useState(1);
  const [activeObject, setActiveObject] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [faq_id, setfaq_id] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [formattedAnswer, setFormattedAnswer] = useState("");
  const [faqQuestion, setFaqQuestion] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    if (openDeleteModal) {
      setActiveObject(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const retrieveFaqs = async () => {
    let data = await getFaqs();
    let arr = Object.values(data);
    setFaqs(arr);
  };

  const onCancel = () => {
    setOpenFaq(false);
    setOpenEditFaq(false);
    setOpenTable(true);
    setOff(false);
    reset();
    resetCurrentFaq();
  };

  const onAdd = () => {
    setOpenFaq(true);
    setOpenEditFaq(false);
    setOpenTable(false);
    setOff(true);
    reset();
    resetCurrentFaq();
  };

  useEffect(() => {
    const retrieveFaqs = async () => {
      let data = await getFaqs();
      let arr = Object.values(data);
      setFaqs(arr);
      setLoading(false);
    };
    retrieveFaqs();
  }, []);

  const saveFaq = async () => {
    setSubmited(true);

    let data = {
      question: faqQuestion,
      answer: formattedAnswer,
    };
    try {
      setOff(true);
      await createFaq(data);
      setOpenFaq(false);
      setOpenTable(true);
      retrieveFaqs();
      setAlertMessage("Faq created sucessfully");
      setAlertType("success");
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      reset();
    } finally {
      handleOpenAlert();
      reset();
      setSubmited(false);
      resetCurrentFaq();
      setOff(false);
    }
  };

  const resetCurrentFaq = () => {
    setFaqQuestion("");
    setFormattedAnswer("");
  };
  const updateCurrentFaq = async () => {
    setSubmited(true);

    let data = {
      question: faqQuestion,
      answer: formattedAnswer,
    };
    try {
      setOff(true);
      await updateFaq(faq_id, data);
      setfaq_id("");
      setOpenFaq(false);
      setOpenEditFaq(false);
      setOpenTable(true);
      setAlertMessage("Faq updated successfully");
      setAlertType("success");
      retrieveFaqs();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
    } finally {
      handleOpenAlert();
      reset();
      setSubmited(false);
      resetCurrentFaq();
      setOff(false);
    }
  };

  const delFaq = async () => {
    setOpenDeleteModal(false);

    let faq_id = activeObject.item.id;

    try {
      await deleteFaq(faq_id);

      setAlertMessage("Faq deleted successfully");
      setAlertType("success");
      setActiveObject(null);
      retrieveFaqs();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
    } finally {
      handleOpenAlert();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const startEditFaq = (item) => {
    setActiveObject({ item });
    setOpenFaq(false);
    setOpenTable(false);
    setOpenEditFaq(true);
    setfaq_id(item.id);
    setOff(true);
    setFaqQuestion(item.question_en);
    setFormattedAnswer(item.answer_en);
  };

  return (
    <>
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle id="form-dialog-title">Delete faq</DialogTitle>
          <DialogContent>
            <h3 className="mb-0">Are you sure you want to delete this faq ?</h3>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteModal} color="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={delFaq} color="danger">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <FaqHeader onAdd={onAdd} off={off} />
      {/* Page content */}
      {openFaq && (
        <FaqBase
          onCancel={onCancel}
          faqQuestion={faqQuestion}
          setFaqQuestion={setFaqQuestion}
          setFormattedAnswer={setFormattedAnswer}
          onSubmit={saveFaq}
          formattedAnswer={formattedAnswer}
          submited={submited}
        />
      )}
      {openEditFaq && (
        <FaqBase
          update
          onCancel={onCancel}
          faqQuestion={faqQuestion}
          setFaqQuestion={setFaqQuestion}
          formattedAnswer={formattedAnswer}
          setFormattedAnswer={setFormattedAnswer}
          onSubmit={updateCurrentFaq}
          submited={submited}
        />
      )}

      {openTable && (
        <FaqsTable
          faqs={faqs}
          isLoading={isLoading}
          startEditFaq={startEditFaq}
          setActiveObject={setActiveObject}
          handleDeleteModal={handleDeleteModal}
          lastPage={lastPage}
        />
      )}
    </>
  );
};

export default Faq;
