import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// core components
import React from "react";

import MuiAlert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    // Header options: choose between different header levels or no header
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // Font style options: bold, italic, underline, strike
    ["bold", "italic", "underline", "strike"],

    // Script/subscript/superscript: allows superscript or subscript text
    [{ script: "sub" }, { script: "super" }],

    // List options: ordered list or bullet list
    [{ list: "ordered" }, { list: "bullet" }],

    // Blockquote and code-block
    ["blockquote", "code-block"],

    // Text alignment options: left, center, right, justify
    [{ align: [] }],

    // Link, image, and video insertion
    // ["link", "image", "video"],
    ["link"],

    // Text color and background color

    [{ color: [] }, { background: [] }],

    // Font size options
    [{ size: ["small", false, "large", "huge"] }],

    // Dropdown for font family selection
    [{ font: [] }],

    // Indent and outdent options
    [{ indent: "-1" }, { indent: "+1" }],

    // Direction of the text
    [{ direction: "rtl" }],

    // Clean: removes formatting from the selected text
    ["clean"],
  ],
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FaqBase = (props) => {
  function isNotValidString(element) {
    let result = true;
    if (
      typeof element !== "string" ||
      element === null ||
      element === undefined
    ) {
      result = true;
    } else
      result =
        element.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
        !element.includes("<img");
    return result;
  }

  return (
    <>
      <Container className="mt--7 mb-3" fluid>
        <Row>
          <Col className="order-xl-1" xl="10">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    {props.update ? (
                      <h3 className="mb-0">Update a new FAQ</h3>
                    ) : (
                      <h3 className="mb-0">Create a new FAQ</h3>
                    )}
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="danger" onClick={props.onCancel} size="sm">
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>
                          Question <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <Input
                          type="text"
                          placeholder="Enter your question here"
                          value={props.faqQuestion}
                          va
                          onChange={(e) => {
                            props.setFaqQuestion(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>
                          Answer <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <ReactQuill
                          modules={modules}
                          theme="snow"
                          value={props.formattedAnswer}
                          onChange={(e) => props.setFormattedAnswer(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <br />
                <div className="pl-lg-4">
                  <Row className="align-items-center">
                    <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        type="button"
                        size="sm"
                        onClick={props.onSubmit}
                        disabled={
                          isNotValidString(props.faqQuestion) ||
                          isNotValidString(props.formattedAnswer) ||
                          props.submited
                        }
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FaqBase;
