import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Button,
    Col,
    UncontrolledTooltip,
    Table,
    Media, Form, FormGroup, Nav,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, { useContext, useEffect, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getChats, getChatsPaginated } from "services/ChatServices";
import { Link, useHistory, useLocation } from "react-router-dom";
import { takeChat, continueChat } from "services/ChatServices";
import { getPurchasesPaginated } from "services/PurchaseServices";
import { changePurchaseStatus } from "services/PurchaseServices";

import env from "ts-react-dotenv";
import { getPurchase } from "services/ArticleServices";
import PurchaseHeader from "components/Headers/PurchaseHeader";


const MEDIA_URL = env.MEDIA_URL;
const REACT_APP_BACKEND_SERVICE = env.REACT_APP_BACKEND_SERVICE;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SinglePurchase = (props) => {

    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [purchase, setPurchase] = useState(null);
    const [lastPage, setLastPage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(true);
    const history = useHistory();
    const { search } = useLocation();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        let purchaseId = queryParams.get('purchaseId');
        console.log(purchaseId);

        const retrievePurchase = async () => {
            let data = await getPurchase(purchaseId);
            console.log(data);
            setPurchase(data);
        }

        retrievePurchase();
    }, [search]);



    return (
        <>
            <PurchaseHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Purchase details</h3>
                                <Nav className="justify-content-end" pills>

                                {openInfo ?
                                    <i className="fas fa-arrow-up text-success mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenInfo(!openInfo)} />
                                    : <i className="fas fa-arrow-down text-warning mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenInfo(!openInfo)} />
                                }
                                </Nav>
                            </CardHeader>
                            {openInfo &&
                            <Table className="align-items-center table-flush" responsive>
                                <tbody className="thead-light">
                                    <tr>
                                        <td></td>
                                        <th scope="col">Transaction ID</th>
                                        <td> {purchase?.transaction_id}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Payment Method</th>
                                        <td> {purchase?.payment_method}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Quantity</th>
                                        <td> {purchase?.quantity}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col"><span>Amount</span></th>
                                        <td>
                                            {purchase?.amount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Address</th>
                                        <td> {purchase?.shipped ? purchase?.address : purchase?.user.address}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">City</th>
                                        <td> {purchase?.shipped ? purchase?.city : purchase?.user.city}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Country</th>
                                        <td> {purchase?.shipped ? purchase?.country : purchase?.user.country} </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Email</th>
                                        <td> {purchase?.shipped ? purchase?.email : purchase?.user.email}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">
                                            <span>Created at </span></th>
                                        <td>  {moment(purchase?.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">
                                            <span>Updated at</span></th>
                                        <td>  {moment(purchase?.updated_at).format("YYYY/MM/DD kk:mm:ss")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <th scope="col">Manage this user</th>
                                        <td>
                                            <Row>
                                                <div className="col" >
                                                    <Link to={{
                                                        pathname: '/admin/users/details',
                                                        search: '?userId=' + purchase?.user_id
                                                    }}>
                                                        
                                                        <Button
                                                            color="primary"
                                                            style={{marginRight:"5px"}}
                                                        >
                                                            Go the user page
                                                        </Button>
                                                    </Link>
                                                    <Link to={{
                                                        pathname: '/admin/invoice',
                                                        search: '?purchaseUid=' + purchase?.purchase_uid}}
                                                        target="_blank">
                                                    <Button
                                                            color="primary"
                                                        >
                                                            See Invoice
                                                    </Button>
                                                        </Link>
                                                </div>
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>}
                        </Card>
                    </div>
                </Row>
            </Container>

            <Container className="mt-3" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Articles bought</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Article Name</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        purchase?.purchase_carts?.map((item, index) =>
                                            <tr key={index}>
                                                <td>
                                                    {item.article_name}
                                                </td>
                                                <td>
                                                    {item.article_type}
                                                </td>
                                                <td>
                                                    {item.amount}
                                                </td>
                                                <td className="text-right">
                                                    <div className="d-flex px-2 py-1">
                                                        <img src={MEDIA_URL + item?.image}
                                                            style={{ width: '70px', height: "50px" }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <Row>

                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default SinglePurchase;
