import axios from "axios";

export function getAllPaginatedSubscribers(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/subscribers/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/subscribers/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function getAllPaginatedNewsletters(id, nb_of_element, status) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/newsletters/status/'+`${status}`+'/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/newsletters/status/'+`${status}`+'/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function getPaginatedNewsletters(status) {

        return new Promise((resolve, reject) => {
            axios
                .get('/get/newsletters/status/'+`${status}`+'/paginate')
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
}

export const saveNewsletter = (data) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/save/newsletter`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const editNewsletter = (data, id) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/edit/newsletter/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const diffuseNewsletter = (id) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/send/newsletter/${id}`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function deleteNewsletter(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/newsletter/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}