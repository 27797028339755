import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Table, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {
    removeAvatarService,
    updateAdminInfos,
    updateAdminPassword,
    updateAvatar
} from "../../../services/AdminServices";
import {useForm} from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {InputBase, Snackbar} from "@material-ui/core";
//import {MEDIA_URL} from "../../../constants";
import MuiAlert from '@material-ui/lab/Alert';
import * as AdminServices from "../../../services/AdminServices";
import FormHelperText from '@material-ui/core/FormHelperText';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import env from "ts-react-dotenv";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

const Profile = () => {

    const {user} = useContext(AuthContext);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {ref: email, ...restEmail} = register("email");
    const {ref: username, ...restUserName} = register("username");
    const {ref: first_name, ...restFirstName} = register("first_name");
    const {ref: last_name, ...restLastName} = register("last_name");
    const {ref: country, ...restCountry} = register("country");
    const {ref: phone, ...restPhone} = register("phone");
    const {ref: city, ...restCity} = register("city");
    const {ref: address, ...restAddress} = register("address");
    // const {ref: postal_code, ...restPostlCode} = register("postal_code", {required: true});

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [openDetails, setOpenDetails] = useState(false);
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const {afterLogin} = useContext(AuthContext);
    const [openPassword, setOpenPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [adminDetails, setAdminDetails] = useState(null);
    const [connectedUser, setConnectedUser] = useState(null);
    const [mobilePhone, setMobilePhone] = useState();


    const handleUploadImage = (event) => {
        var image = event.target.files[0];
        let imageUrl = URL.createObjectURL(image);
        setImageUrl(imageUrl);
        setImage(image);
    }

    const handleClickInput = () => {
        inputRef.current.click();
    }


    const handleDetails = () => {
        setOpenDetails(!openDetails);
    };

    // const handlePasswordModal = () => {
    //     setOpenPassword(!openDetails);
    // };

    const handlePassword = () => {
        setOpenPassword(!openPassword);
    };

    const formCurrentPassword = (event) => {
        const result = event.target.value;
        setCurrentPassword(result);
    };
    const formPassword = (event) => {
        const result = event.target.value;
        setPassword(result);
    };
    const formConfirmPassword = (event) => {
        const result = event.target.value;
        setConfirmPassword(result);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const completeInfos = async (data) => {
        console.log('on entre')
        try {
            await updateAdminInfos(user?.id, data, mobilePhone);
            setAlertMessage("Information sucessfully updated !");
            setAlertType("success");
            handleOpenAlert();
            afterLogin();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const changePassword = async () => {

        if (password !== confirmPassword) {
            setAlertMessage("The password and the password confirmation are different!");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        if (password.length < 6) {
            setAlertMessage("The password security is weak. Please use a strong password with aat least 6 characters");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        let data = {
            password: currentPassword,
            new_password: password
        }
        try {
            const result = await updateAdminPassword(user?.id, data);
            setAlertMessage(result.message);
            setAlertType("success");
            handleOpenAlert();
            handlePassword();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const changeAvatar = async () => {
        try {
            handleDetails();
            await updateAvatar(user?.id, image);
            afterLogin();
            setAlertMessage("Avatar changed successfully");
            setAlertType("success");
            handleOpenAlert();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const removeAvatar = async () => {
        try {
            handleDetails();
            // if (imageUrl !== ' ') {
            //     setImageUrl(' ');
            //     setImage(' ');
            //     return;
            // }

            await removeAvatarService(user?.id, null);
            afterLogin();
            setAlertMessage("Avatar successfully removed");
            setAlertType("success");
            handleOpenAlert();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }
    const retrieveAdminDetails = async (id) => {
        let response = await AdminServices.adminDetails(id);
        setAdminDetails(response.data);
    }

    useEffect(() => {
        setConnectedUser(user);

        const retrieveAdminDetails = async () => {

            //setMobilePhone(user.phone)
            // const connectedUserId = user?.id;
            // let response = await AdminServices.adminDetails(connectedUserId);
            // setAdminDetails(response.data);
        }

        retrieveAdminDetails();
    }, [user]);

    const Modal = () => (
        <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Form role="form">
                        <center>
                            <Row>
                                <Col><h2>Change your avatar</h2></Col>
                            </Row>

                            <input
                                id="file"
                                type="file"
                                accept="image/*"
                                style={{display: 'none'}}
                                ref={inputRef}
                                onChange={handleUploadImage}
                            />


                            {/*<div className="images" style={{width: '100%'}}>
                                <div id="button-pic" className="pic" onClick={handleClickInput}>
                                    Select image
                                </div>
                            </div>*/}
                            {imageUrl === '' ?
                                <div className="images">
                                    <img src={MEDIA_URL + user?.avatar} className="rounded-circle"
                                         style={{width: '250px', height: "200px", cursor: 'pointer'}}
                                         onClick={handleClickInput}/>
                                </div> :
                                <div className="images">
                                    <img src={imageUrl} className="rounded-circle"
                                         width="50%"
                                         style={{width: '250px', height: "200px", cursor: 'pointer'}}
                                         onClick={handleClickInput}/>
                                </div>}
                            <Button
                                color="info"
                                type="button"
                                onClick={changeAvatar}
                            >
                                save
                            </Button>
                            <Button
                                color="danger"
                                onClick={removeAvatar}
                            >
                                remove
                            </Button>

                        </center>
                    </Form>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetails} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Dialog open={openPassword} onClose={handlePassword} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <CardBody>
                        <Form role="form">
                            <Table className="align-items-center table-flush" responsive>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td>Current password </td>
                                    <td>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <InputBase type="password"
                                                           name="currentPassword"
                                                           value={currentPassword}
                                                           onChange={formCurrentPassword}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>New password </td>
                                    <td>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <InputBase name="password"
                                                           type="password"
                                                           value={password}
                                                           onChange={formPassword}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span>Confirm password </span></td>
                                    <td>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <InputBase type="password"
                                                           name="confirmPassword"
                                                           value={confirmPassword}
                                                           onChange={formConfirmPassword}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Button
                                            color="info"
                                            type="button"
                                            onClick={changePassword}
                                        >
                                            save
                                        </Button>
                                    </td>
                                    <td></td>
                                </tr>

                                </tbody>
                            </Table>
                        </Form>
                    </CardBody>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handlePassword} color="danger">
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={3000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <UserHeader/>
            {/* Page content */}
            <Container className="mt--9" fluid>
                <Row>
                    {/*<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">*/}
                    {/*    <Card className="card-profile shadow">*/}
                    {/*        <Row className="justify-content-center">*/}
                    {/*            <Col className="order-lg-2" lg="3">*/}
                    {/*                <div className="card-profile-image">*/}
                    {/*                    <a onClick={() => {*/}
                    {/*                        handleDetails();*/}
                    {/*                    }}>*/}
                    {/*                        <img*/}
                    {/*                            alt="..."*/}
                    {/*                            className="rounded-circle"*/}
                    {/*                            src={MEDIA_URL + user?.avatar}*/}
                    {/*                            style={{cursor: 'pointer'}}*/}
                    {/*                        />*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">*/}
                    {/*            <div className="d-flex justify-content-between">*/}
                    {/*                <Button*/}
                    {/*                    className="mr-4"*/}
                    {/*                    color="info"*/}
                    {/*                    onClick={() => {*/}
                    {/*                        handleDetails();*/}
                    {/*                    }}*/}
                    {/*                    size="sm"*/}
                    {/*                >*/}
                    {/*                    Edit avatar*/}
                    {/*                </Button>*/}
                    {/*                <Button*/}
                    {/*                    className="float-right"*/}
                    {/*                    color="default"*/}
                    {/*                    onClick={(e) => {*/}
                    {/*                        e.preventDefault();*/}
                    {/*                        alert('Functionality not yet testable !');*/}
                    {/*                    }}*/}
                    {/*                    size="sm"*/}
                    {/*                >*/}
                    {/*                    Chats*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        </CardHeader>*/}
                    {/*        <CardBody className="pt-0 pt-md-4">*/}
                    {/*            {adminDetails ?*/}
                    {/*                <Row>*/}
                    {/*                    <div className="col">*/}
                    {/*                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">*/}
                    {/*                            <div>*/}
                    {/*                            <span*/}
                    {/*                                className="heading">{adminDetails.news.value.pending + adminDetails.news.value.interested + adminDetails.news.value.sold*/}
                    {/*                                + adminDetails.news.value.released + adminDetails.news.value.finished + adminDetails.news.value.embarked}</span>*/}
                    {/*                                <span className="description">News</span>*/}
                    {/*                            </div>*/}
                    {/*                            <div>*/}
                    {/*                            <span*/}
                    {/*                                className="heading">{adminDetails.articles.value.pending + adminDetails.articles.value.interested + adminDetails.articles.value.sold*/}
                    {/*                                + adminDetails.articles.value.released + adminDetails.articles.value.finished + adminDetails.articles.value.embarked}</span>*/}
                    {/*                                <span className="description">Articles</span>*/}
                    {/*                            </div>*/}
                    {/*                            <div>*/}
                    {/*                            <span*/}
                    {/*                                className="heading">{adminDetails.vehicles.value.pending + adminDetails.vehicles.value.interested + adminDetails.vehicles.value.sold*/}
                    {/*                                + adminDetails.vehicles.value.released + adminDetails.vehicles.value.finished + adminDetails.vehicles.value.embarked}</span>*/}
                    {/*                                <span className="description">Vehicles</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </Row>*/}
                    {/*                :*/}
                    {/*                ''*/}
                    {/*            }*/}
                    {/*            <hr className="my-4"/>*/}
                    {/*            <div className="text-center">*/}
                    {/*                <h3>*/}
                    {/*                    {user?.first_name != null ? user.first_name : ''} {user?.last_name != null ? user?.last_name : ''}*/}
                    {/*                    <span className="font-weight-light"></span>*/}
                    {/*                </h3>*/}
                    {/*                <div className="h5 font-weight-300">*/}
                    {/*                    <i className="ni location_pin mr-2"/>*/}
                    {/*                    {user?.city != null ? user?.city : ''} {user?.country != null ? user?.country : ''}*/}
                    {/*                </div>*/}
                    {/*                <div className="h5 mt-4">*/}
                    {/*                    <i className="ni business_briefcase-24 mr-2"/>*/}
                    {/*                    Solution Manager - Creative Tim Officer*/}
                    {/*                </div>*/}
                    {/*                <div>*/}
                    {/*                    <i className="ni education_hat mr-2"/>*/}
                    {/*                    University of Computer Science*/}
                    {/*                </div>*/}
                    {/*                <hr className="my-4"/>*/}
                    {/*                <p>*/}
                    {/*                  Ryan — the name taken by Melbourne-raised, Brooklyn-based*/}
                    {/*                  Nick Murphy — writes, performs and records all of his own*/}
                    {/*                  music.*/}
                    {/*                </p>*/}
                    {/*                <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                    {/*                  Show more*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*        </CardBody>*/}
                    {/*    </Card>*/}
                    {/*</Col>*/}
                    <Col className="order-xl-1" xl="1">
                    </Col>
                    <Col className="order-xl-1" xl="10">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">

                                <Card className="card-profile shadow">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <a onClick={() => {
                                                    handleDetails();
                                                }}>
                                                    <img
                                                        alt="..."
                                                        className="rounded-circle"
                                                        src={MEDIA_URL + user?.avatar}
                                                        style={{cursor: 'pointer'}}
                                                    />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                className="mr-4"
                                                color="info"
                                                onClick={() => {
                                                    handleDetails();
                                                }}
                                                size="sm"
                                            >
                                                Edit avatar
                                            </Button>
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={() => {
                                                    handlePassword()
                                                }}
                                                size="sm"
                                            >
                                                Password
                                            </Button>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="pt-0 pt-md-4">

                                    </CardBody>
                                </Card>

                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit(completeInfos)}>
                                    <Table className="align-items-center table-flush" responsive>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td>Email </td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.email}
                                                    className="form-control-alternative"
                                                    {...restEmail}
                                                    innerRef={email}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>

                                            </td>
                                            <td>Username </td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.username}
                                                    className="form-control-alternative"
                                                    {...restUserName}
                                                    innerRef={username}
                                                />
                                                {errors.username &&
                                                    <FormHelperText style={{color: 'red'}}>User name is required
                                                        !</FormHelperText>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><span>First name </span></td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.first_name}
                                                    className="form-control-alternative"
                                                    {...restFirstName}
                                                    innerRef={first_name}
                                                />
                                                {errors.first_name &&
                                                    <FormHelperText style={{color: 'red'}}>First name is required
                                                        !</FormHelperText>}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><span>Last name </span></td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.last_name}
                                                    className="form-control-alternative"
                                                    {...restLastName}
                                                    innerRef={last_name}
                                                />
                                                {errors.last_name &&
                                                    <FormHelperText style={{color: 'red'}}>Last name is required
                                                        !</FormHelperText>}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Phone</td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.phone}
                                                    className="form-control-alternative"
                                                    {...restPhone}
                                                    innerRef={phone}
                                                />
                                                {errors.Phone &&
                                                    <FormHelperText style={{color: 'red'}}>Phone is required
                                                        !</FormHelperText>}

                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Address</td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.address}
                                                    className="form-control-alternative"
                                                    {...restAddress}
                                                    innerRef={address}
                                                />
                                                {errors.address &&
                                                    <FormHelperText style={{color: 'red'}}>Address is required
                                                        !</FormHelperText>}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>City</td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.city}
                                                    className="form-control-alternative"
                                                    {...restCity}
                                                    innerRef={city}
                                                />
                                                {errors.city &&
                                                    <FormHelperText style={{color: 'red'}}>City is required
                                                        !</FormHelperText>}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Country</td>
                                            <td>
                                                <Input
                                                    defaultValue={user?.country}
                                                    className="form-control-alternative"
                                                    {...restCountry}
                                                    innerRef={country}
                                                />
                                                {errors.country &&
                                                    <FormHelperText style={{color: 'red'}}>Country is required
                                                        !</FormHelperText>}
                                            </td>
                                            <td></td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td></td>*/}
                                        {/*    <td>Postal code </td>*/}
                                        {/*    <td>*/}
                                        {/*        <Input*/}
                                        {/*            defaultValue={user?.postal_code}*/}
                                        {/*            className="form-control-alternative"*/}
                                        {/*            {...restPostlCode}*/}
                                        {/*            innerRef={postal_code}*/}
                                        {/*        />*/}
                                        {/*        {errors.postal_code && <FormHelperText style={{ color: 'red' }}>Postal Code is required !</FormHelperText>}*/}
                                        {/*    </td>*/}
                                        {/*    <td></td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td></td>*/}
                                        {/*    <td><span>About me</span></td>*/}
                                        {/*    <td>*/}
                                        {/*        <FormGroup className="mb-3">*/}
                                        {/*            <InputGroup className="input-group-alternative">*/}
                                        {/*                <InputGroupAddon addonType="prepend">*/}
                                        {/*                    <InputGroupText>*/}
                                        {/*                        <i className="ni ni-single-02"/>*/}
                                        {/*                    </InputGroupText>*/}
                                        {/*                </InputGroupAddon>*/}
                                        {/*                <InputBase placeholder="about me" name="about_me"*/}
                                        {/*                           defaultValue={user?.about_me}*/}
                                        {/*                           {...register('about_me')}/>*/}
                                        {/*            </InputGroup>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </td>*/}
                                        {/*    <td></td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <Button
                                                    className="my-4"
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    Save
                                                </Button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="1">
                    </Col>
                </Row>
            </Container>
            {openDetails ? <Modal/> : null}
        </>
    );
};

export default Profile;
