import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row,
    Table,
    Nav,
} from "reactstrap";
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@mui/material/Alert";
import FormHelperText from "@material-ui/core/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import ChatHeader from "components/Headers/ChatHeader.js";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import {
    changePurchaseStatus,
    getAllPaginatedPurchases,
} from "services/PurchaseServices";
import { computeNumerotation } from "../../constants";
import { getAllPaginatedNewsletters } from "services/NewsletterServices";
import { getAllPaginatedSubscribers } from "services/NewsletterServices";
import NewsletterHeader from "components/Headers/NewsletterHeader";
import { createNewsletter } from "services/NewsletterServices";
import { Controller, useForm } from "react-hook-form";
import { saveNewsletter } from "services/NewsletterServices";
import { editNewsletter } from "services/NewsletterServices";
import { diffuseNewsletter } from "services/NewsletterServices";
import { getPaginatedNewsletters } from "services/NewsletterServices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { deleteNewsletter } from "services/NewsletterServices";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const modules = {
    toolbar: [
        // Header options: choose between different header levels or no header
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        // Font style options: bold, italic, underline, strike
        ["bold", "italic", "underline", "strike"],

        // Script/subscript/superscript: allows superscript or subscript text
        [{ script: "sub" }, { script: "super" }],

        // List options: ordered list or bullet list
        [{ list: "ordered" }, { list: "bullet" }],

        // Blockquote and code-block
        ["blockquote", "code-block"],

        // Text alignment options: left, center, right, justify
        [{ align: [] }],

        // Link, image, and video insertion
        // ["link", "image", "video"],
        ["link"],

        // Text color and background color

        [{ color: [] }, { background: [] }],

        // Font size options
        [{ size: ["small", false, "large", "huge"] }],

        // Dropdown for font family selection
        [{ font: [] }],

        // Indent and outdent options
        [{ indent: "-1" }, { indent: "+1" }],

        // Direction of the text
        [{ direction: "rtl" }],

        // Clean: removes formatting from the selected text
        ["clean"],
    ],
};

const Newsletters = (props) => {
    const {
        control,
        setValue,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const { ref: subject, ...restSubject } = register("subject", {
        required: true,
        minLength: 5,
    });

    const { ref: message, ...restMessage } = register("message", {
        required: true,
        minLength: 10,
    });

    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [submited, setSubmited] = useState(false);
    const [newsletters, setNewsletters] = useState([]);
    const [pendingNewsletters, setPendingNewsletters] = useState([]);
    const [subscribers, setSubscribers] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();
    const [openNewsletter, setOpenNewsletter] = useState(false);
    const [openEditNewsletter, setOpenEditNewsletter] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [openSubscribers, setOpenSubscribers] = useState(false);
    const [openPendingNews, setOpenPendingNews] = useState(false);
    const [openSentNews, setOpenSentNews] = useState(false);
    const [newsSubject, setNewsSubject] = useState("");
    const [newsMessage, setNewsMessage] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleNewsletter = () => {
        setOpenNewsletter(!openNewsletter);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
    };

    const handleEditNewsletter = () => {
        setOpenEditNewsletter(!openEditNewsletter);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    const handleDetailModal = () => {
        setOpenDetailModal(!openDetailModal);
    };

    useEffect(() => {
        const retrieveNewsletters = async () => {
            let response = await getPaginatedNewsletters(3);
            console.log(response)
            setNewsletters(response.data);
            setLastPage(response.last_page);
            setCurrentPage(response.current_page);
            setLoading(false);
        };
        const retrievePendingNewsletters = async () => {
            let response = await getPaginatedNewsletters(2);
            console.log(response)
            setPendingNewsletters(response.data);
            setLastPage(response.last_page);
            setCurrentPage(response.current_page);
            setLoading(false);
        };
        const retrieveSubscribers = async () => {
            let response = await getAllPaginatedSubscribers();
            setSubscribers(response.data);
            setLastPage(response.meta.last_page);
            setCurrentPage(response.meta.current_page);
            setLoading(false);
        };

        retrievePendingNewsletters();
        retrieveNewsletters();
        retrieveSubscribers();
    }, []);

    const retrieveNewsletters = async () => {
        let response = await getPaginatedNewsletters(3);
        setNewsletters(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
        setLoading(false);
    };

    const retrievePendingNewsletters = async () => {
        let response = await getPaginatedNewsletters(2);
        console.log(response)
        setPendingNewsletters(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
        setLoading(false);
    };

    const retrieveSubscribers = async () => {
        let response = await getAllPaginatedSubscribers();
        setSubscribers(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
        setLoading(false);
    };

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let response = await getAllPaginatedPurchases(
            parseInt(requestedPage),
            itemPerPage
        );
        //setPurchases(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const handleSearchNewsletters = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        setNbPageElement(parseInt(nb));
        let response = await getAllPaginatedNewsletters(undefined, parseInt(nb), 3);
        setNewsletters(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const handleSearchPendingNewsletters = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        setNbPageElement(parseInt(nb));
        let response = await getAllPaginatedNewsletters(undefined, parseInt(nb), 2);
        setPendingNewsletters(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const handleSearchSubscribers = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        setNbPageElement(parseInt(nb));
        let response = await getAllPaginatedSubscribers(undefined, parseInt(nb));
        setSubscribers(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    };

    const goToPurchase = (e) => {
        let purchase_id = parseInt(e.target.getAttribute("value"));

        props.history.push({
            pathname: "/admin/single-purchase",
            search: "?purchaseId=" + purchase_id,
        });
    };

    const createNewsletter = async (data) => {
        setSubmited(true);

        try {
            await saveNewsletter(data);
            setOpenNewsletter(!openNewsletter);
            setOpenTable(!openTable);
            setOff(!off);

            retrieveNewsletters();
            retrievePendingNewsletters();

            setAlertMessage("Newsletter created sucessfully");
            setAlertType("success");
            handleOpenAlert();
            reset();
            setSubmited(false);
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            setSubmited(false);
        }
    };

    const updateNewsletter = async (data) => {
        let news_id = activeObject.item.id;
        setSubmited(true);

        try {
            await editNewsletter(data, news_id);
            setOpenEditNewsletter(!openEditNewsletter);
            setOpenTable(!openTable);
            setOff(!off);

            retrievePendingNewsletters();
            retrieveNewsletters();

            setAlertMessage("Newsletter edited sucessfully");
            setAlertType("success");
            handleOpenAlert();
            reset();
            setSubmited(false);
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            setSubmited(false);
        }
    };

    const sendNewsletter = async () => {
        let news_id = activeObject.item.id;

        try {
            await diffuseNewsletter(news_id);
            setOpenStatusModal(false);

            retrieveNewsletters();
            retrievePendingNewsletters();

            setAlertMessage("Newsletter send sucessfully");
            setAlertType("success");
            handleOpenAlert();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    };

    const delNewsletter = async () => {

        setOpenDeleteModal(false);
        let newsletter_id = activeObject.item.id;
    
        try {
          await deleteNewsletter(newsletter_id);
    
          setAlertMessage("Newsletter deleted sucessfully");
          setAlertType("success");
          handleOpenAlert();
    
          retrieveNewsletters();
          retrievePendingNewsletters();
        } catch (error) {
          setAlertMessage(error.message);
          setAlertType("error");
          handleOpenAlert();
        }
    };

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleChangeInput = (e) => {
        if (e.target.name == "subject") {
            setNewsSubject(e.target.value);
        } else {
            setNewsMessage(e.target.value);
        }
    };

    const changePostStatus = async () => {
        let purchase_id = activeObject.item.id;
        setOpenStatusModal(false);

        try {
            await changePurchaseStatus(purchase_id);
            //retrievePurchases();
            setAlertMessage("Feature value added sucessfully");
            setAlertType("success");
            handleOpenAlert();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={openDeleteModal}
                onClose={handleDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <form>
                    <DialogTitle id="form-dialog-title">Delete Newsletter</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">
                            Are you sure you want to delete this newsletter ?
                        </h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button type="button" onClick={delNewsletter} color="danger">
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={openStatusModal}
                onClose={handleStatusModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Send Newsletter</DialogTitle>
                <DialogContent>
                    <CardBody>
                        <div className="pl-lg-4">
                            <Row>
                                <Col md="12">
                                    <p>Confirm that you want send newsletter ?</p>
                                    <FormGroup></FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleStatusModal} color="secondary">
                        Cancel
                    </Button>
                    {submited ? (
                        <Button type="button" disabled onClick={sendNewsletter} color="primary">
                            Send
                        </Button>) : (
                        <Button type="button" onClick={sendNewsletter} color="primary">
                            Send
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDetailModal}
                onClose={handleDetailModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Newsletter detail</DialogTitle>
                <DialogContent>
                    <CardBody>
                        <div className="pl-lg-4">
                            <Row>
                                <Col md="12">
                                    <div className="ql-snow">
                                        <div
                                            className="ql-editor"
                                            dangerouslySetInnerHTML={{
                                                __html: activeObject?.item.message,
                                            }}
                                        />
                                    </div>
                                    <FormGroup></FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleDetailModal} color="primary">
                        ok
                    </Button>
                </DialogActions>
            </Dialog>

            <NewsletterHeader handleNewsletter={handleNewsletter} off={off} />
            {/* Page content */}

            {openNewsletter ? (
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Create a newsletter</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button color="danger" onClick={handleNewsletter} size="sm">
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(createNewsletter)}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup error={errors.subject ? true : undefined}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Subject <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <Input
                                                            name="subject"
                                                            className="form-control-alternative"
                                                            placeholder="Subject here ..."
                                                            {...restSubject}
                                                            innerRef={subject}
                                                            aria-invalid={errors.subject ? "true" : "false"}
                                                        />
                                                        {errors.subject && (
                                                            <FormHelperText style={{ color: "red" }}>
                                                                At least 5 characters are required !
                                                            </FormHelperText>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup
                                                        error={errors.message ? true : undefined}
                                                    >
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Message to send{" "}
                                                            <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <Controller
                                                            name="message"
                                                            control={control}
                                                            rules={{ required: true, minLength: 10 }}
                                                            render={({ field }) => (
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    modules={modules}
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    placeholder="Message here ..."
                                                                />
                                                            )}
                                                        />

                                                        {errors.message && (
                                                            <FormHelperText style={{ color: "red" }}>
                                                                At least 10 characters are required !
                                                            </FormHelperText>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <hr className="my-4 mt-3" />

                                        <div className="pl-lg-4 mt-3">
                                            <Row className="align-items-center">
                                                <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                                                <Col className="text-right" xs="4">
                                                    {submited ? (
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                            disabled
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button color="primary" type="submit" size="sm">
                                                            Submit
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                ""
            )}

            {openEditNewsletter ? (
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Edit a newsletter</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button color="danger" onClick={handleEditNewsletter} size="sm">
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(updateNewsletter)}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Subject <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <Input
                                                            name="subject"
                                                            value={newsSubject}
                                                            className="form-control-alternative"
                                                            {...restSubject}
                                                            innerRef={subject}
                                                            onChange={handleChangeInput}
                                                        />
                                                        {errors.subject && (
                                                            <FormHelperText style={{ color: "red" }}>
                                                                At least 5 characters are required !
                                                            </FormHelperText>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup
                                                        error={errors.message ? true : undefined}
                                                    >
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Message to send{" "}
                                                            <span style={{ color: "red" }}>*</span>
                                                        </label>

                                                        <Controller
                                                            name="message"
                                                            control={control}
                                                            rules={{ required: true, minLength: 10 }}
                                                            render={({ field }) => (
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    modules={modules}
                                                                    value={field.value || newsMessage}
                                                                    onChange={field.onChange}
                                                                    placeholder="Message here ..."
                                                                />
                                                            )}
                                                        />{/* 
                                                        <Input
                                                            type="textarea"
                                                            name="message"
                                                            value={newsMessage}
                                                            className="form-control-alternative"
                                                            placeholder="Message here ..."
                                                            {...restMessage}
                                                            innerRef={message}
                                                            rows={5}
                                                            onChange={handleChangeInput}
                                                            aria-invalid={
                                                                errors.description ? "true" : "false"
                                                            }
                                                        /> */}

                                                        {errors.message && (
                                                            <FormHelperText style={{ color: "red" }}>
                                                                At least 10 characters are required !
                                                            </FormHelperText>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <hr className="my-4 mt-3" />

                                        <div className="pl-lg-4 mt-3">
                                            <Row className="align-items-center">
                                                <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                                                <Col className="text-right" xs="4">
                                                    {submited ? (
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                            disabled
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button color="primary" type="submit" size="sm">
                                                            Submit
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                ""
            )}


            {openTable ? (
                <>
                    <Container className="mt--7" fluid>
                        {/* Table */}
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">All subscribers here ({subscribers?.length})</h3>
                                        <Nav className="justify-content-end" pills>
                                            {openSubscribers ?
                                                <i className="fas fa-arrow-up text-success mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenSubscribers(!openSubscribers)} />
                                                : <i className="fas fa-arrow-down text-warning mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenSubscribers(!openSubscribers)} />
                                            }
                                        </Nav>
                                    </CardHeader>
                                    {isLoading ?
                                        <ReactLoading type={"spin"} color="#B20101" />
                                        : <>
                                            {openSubscribers &&
                                                <>
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">email</th>
                                                                <th scope="col">Subscription Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subscribers?.map((item, index) => (
                                                                <>
                                                                    <tr key={item.id}>
                                                                        <th scope="row">
                                                                            <span className="mb-0 text-sm">
                                                                                {computeNumerotation(
                                                                                    currentPage,
                                                                                    itemPerPage,
                                                                                    index
                                                                                )}
                                                                            </span>
                                                                        </th>
                                                                        <th scope="row">{item.email}</th>
                                                                        <td>
                                                                            {moment(item.created_at).format(
                                                                                "YYYY/MM/DD kk:mm:ss"
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </Table>

                                                    <CardFooter className="py-4">
                                                        <Row>
                                                            <Col>
                                                                <Form>
                                                                    <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                                        <select
                                                                            className="form-control"
                                                                            onChange={(e) => {
                                                                                handleSearchSubscribers(e);
                                                                            }}
                                                                        >
                                                                            <option value="5">5</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                            <option value="20">20</option>
                                                                            <option value="25" selected>
                                                                                25
                                                                            </option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                            <Col>
                                                                <nav aria-label="...">
                                                                    <ReactPaginate
                                                                        previousLabel={"<"}
                                                                        nextLabel={">"}
                                                                        breakLabel={"..."}
                                                                        pageCount={lastPage}
                                                                        marginPagesDisplayed={3}
                                                                        pageRangeDisplayed={6}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName={
                                                                            "pagination justify-content-end mb-0"
                                                                        }
                                                                        pageClassName={"page-item"}
                                                                        previousClassName={"page-item"}
                                                                        previousLinkClassName={"page-link"}
                                                                        pageLinkClassName={"page-link"}
                                                                        nextClassName={"page-item"}
                                                                        nextLinkClassName={"page-link"}
                                                                        breakClassName={"page-item"}
                                                                        breakLinkClassName={"page-link"}
                                                                        activeClassName={"active"}
                                                                    />
                                                                </nav>
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </>
                                            }
                                        </>
                                    }
                                </Card>
                            </div>
                        </Row>
                    </Container>


                    <Container className="mt-3" fluid>
                        {/* Table */}
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">All Pending Newsletters here ({pendingNewsletters?.length})</h3>
                                        <Nav className="justify-content-end" pills>
                                            {openPendingNews ?
                                                <i className="fas fa-arrow-up text-success mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenPendingNews(!openPendingNews)} />
                                                : <i className="fas fa-arrow-down text-warning mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenPendingNews(!openPendingNews)} />
                                            }
                                        </Nav>
                                    </CardHeader>
                                    {isLoading ?
                                        <ReactLoading type={"spin"} color="#B20101" />
                                        : <>
                                            {openPendingNews &&
                                                <>
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col">Message</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Last Sent Date</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pendingNewsletters?.map((item, index) => (
                                                                <>
                                                                    <tr key={item.id}>
                                                                        <th scope="row">
                                                                            <span className="mb-0 text-sm">
                                                                                {computeNumerotation(
                                                                                    currentPage,
                                                                                    itemPerPage,
                                                                                    index
                                                                                )}
                                                                            </span>
                                                                        </th>
                                                                        <td>
                                                                            {item.subject.length > 70 ? item.subject.slice(0, 10) + '...' : item.subject}
                                                                        </td>
                                                                        <th scope="row">{item.message.length > 70 ? item.message.slice(0, 50) + '...' : item.message}</th>

                                                                        <td>
                                                                            {item.status}
                                                                        </td>
                                                                        {item.last_sent_date !== null ? <td>
                                                                            {moment(item.last_sent_date).format(
                                                                                "YYYY/MM/DD kk:mm:ss"
                                                                            )}
                                                                        </td> : <td>Never sent</td>}

                                                                        <td className="text-right">
                                                                            <div className="d-flex px-2 py-1">
                                                                                <Tooltip title="EditNews" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setNewsSubject(item.subject)
                                                                                            setNewsMessage(item.message)
                                                                                            setValue(
                                                                                                "message",
                                                                                                item.message
                                                                                            );
                                                                                            handleEditNewsletter();
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                    >
                                                                                        edit
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Send News" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setOpenStatusModal(true);
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "blue",
                                                                                        }}
                                                                                    >
                                                                                        send
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="View details" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setOpenDetailModal(true);
                                                                                        }}
                                                                                        style={{ cursor: "pointer", color: "green" }}
                                                                                    >
                                                                                        visibility
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete" placement="top">
                                                                                    <span
                                                                                        className="material-icons"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            handleDeleteModal();
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "firebrick",
                                                                                        }}
                                                                                    >
                                                                                        delete
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </Table>

                                                    <CardFooter className="py-4">
                                                        <Row>
                                                            <Col>
                                                                <Form>
                                                                    <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                                        <select
                                                                            className="form-control"
                                                                            onChange={(e) => {
                                                                                handleSearchPendingNewsletters(e);
                                                                            }}
                                                                        >
                                                                            <option value="5">5</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                            <option value="20">20</option>
                                                                            <option value="25" selected>
                                                                                25
                                                                            </option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                            <Col>
                                                                <nav aria-label="...">
                                                                    <ReactPaginate
                                                                        previousLabel={"<"}
                                                                        nextLabel={">"}
                                                                        breakLabel={"..."}
                                                                        pageCount={lastPage}
                                                                        marginPagesDisplayed={3}
                                                                        pageRangeDisplayed={6}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName={
                                                                            "pagination justify-content-end mb-0"
                                                                        }
                                                                        pageClassName={"page-item"}
                                                                        previousClassName={"page-item"}
                                                                        previousLinkClassName={"page-link"}
                                                                        pageLinkClassName={"page-link"}
                                                                        nextClassName={"page-item"}
                                                                        nextLinkClassName={"page-link"}
                                                                        breakClassName={"page-item"}
                                                                        breakLinkClassName={"page-link"}
                                                                        activeClassName={"active"}
                                                                    />
                                                                </nav>
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </>
                                            }
                                        </>
                                    }
                                </Card>
                            </div>
                        </Row>
                    </Container>

                    <Container className="mt-3" fluid>
                        {/* Table */}
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">All Sent Newsletters here ( {newsletters?.length} )</h3>
                                        <Nav className="justify-content-end" pills>
                                            {openSentNews ?
                                                <i className="fas fa-arrow-up text-success mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenSentNews(!openSentNews)} />
                                                : <i className="fas fa-arrow-down text-warning mr-3" style={{ cursor: 'pointer' }} onClick={() => setOpenSentNews(!openSentNews)} />
                                            }
                                        </Nav>
                                    </CardHeader>
                                    {isLoading ?
                                        <ReactLoading type={"spin"} color="#B20101" />
                                        : <>
                                            {openSentNews &&
                                                <>
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col">Message</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Last Sent Date</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {newsletters?.map((item, index) => (
                                                                <>
                                                                    <tr key={item.id}>
                                                                        <th scope="row">
                                                                            <span className="mb-0 text-sm">
                                                                                {computeNumerotation(
                                                                                    currentPage,
                                                                                    itemPerPage,
                                                                                    index
                                                                                )}
                                                                            </span>
                                                                        </th>
                                                                        <td>
                                                                            {item.subject.length > 70 ? item.subject.slice(0, 10) + '...' : item.subject}
                                                                        </td>
                                                                        <th scope="row">{item.message.length > 70 ? item.message.slice(0, 50) + '...' : item.message}</th>
                                                                        <th scope="row">{item.status}</th>
                                                                        <td>
                                                                            {moment(item.last_sent_date).format(
                                                                                "YYYY/MM/DD kk:mm:ss"
                                                                            )}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <div className="d-flex px-2 py-1">
                                                                                <Tooltip title="Edit News" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setNewsMessage(item.message)
                                                                                            setValue(
                                                                                                "message",
                                                                                                item.message
                                                                                            );
                                                                                            handleEditNewsletter();
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                    >
                                                                                        edit
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Send News" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setOpenStatusModal(true);
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "blue",
                                                                                        }}
                                                                                    >
                                                                                        send
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="View details" placement="top">
                                                                                    <span
                                                                                        className="material-icons danger"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            setOpenDetailModal(true);
                                                                                        }}
                                                                                        style={{ cursor: "pointer", color: "green" }}
                                                                                    >
                                                                                        visibility
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete" placement="top">
                                                                                    <span
                                                                                        className="material-icons"
                                                                                        onClick={() => {
                                                                                            setActiveObject({ item });
                                                                                            handleDeleteModal();
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "firebrick",
                                                                                        }}
                                                                                    >
                                                                                        delete
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </Table>

                                                    <CardFooter className="py-4">
                                                        <Row>
                                                            <Col>
                                                                <Form>
                                                                    <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                                        <select
                                                                            className="form-control"
                                                                            onChange={(e) => {
                                                                                handleSearchNewsletters(e);
                                                                            }}
                                                                        >
                                                                            <option value="5">5</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                            <option value="20">20</option>
                                                                            <option value="25" selected>
                                                                                25
                                                                            </option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                            <Col>
                                                                <nav aria-label="...">
                                                                    <ReactPaginate
                                                                        previousLabel={"<"}
                                                                        nextLabel={">"}
                                                                        breakLabel={"..."}
                                                                        pageCount={lastPage}
                                                                        marginPagesDisplayed={3}
                                                                        pageRangeDisplayed={6}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName={
                                                                            "pagination justify-content-end mb-0"
                                                                        }
                                                                        pageClassName={"page-item"}
                                                                        previousClassName={"page-item"}
                                                                        previousLinkClassName={"page-link"}
                                                                        pageLinkClassName={"page-link"}
                                                                        nextClassName={"page-item"}
                                                                        nextLinkClassName={"page-link"}
                                                                        breakClassName={"page-item"}
                                                                        breakLinkClassName={"page-link"}
                                                                        activeClassName={"active"}
                                                                    />
                                                                </nav>
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </>
                                            }
                                        </>
                                    }
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default Newsletters;
