import {
    Container,
    Row,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, {useContext, useEffect, useState} from "react";
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import {AuthContext} from "context/AuthContext";
import moment from "moment";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getChats, getChatsPaginated} from "services/ChatServices";
import {useHistory, useLocation} from "react-router-dom";
import {takeChat, continueChat} from "services/ChatServices";
import {getPurchasesPaginated} from "services/PurchaseServices";
import {changePurchaseStatus} from "services/PurchaseServices";

import env from "ts-react-dotenv";
import {getPurchase, getUserPurchase} from "services/ArticleServices";
import PurchaseHeader from "components/Headers/PurchaseHeader";


const MEDIA_URL = env.MEDIA_URL;
const REACT_APP_BACKEND_SERVICE = env.REACT_APP_BACKEND_SERVICE;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PurchaseUserDetails = (props) => {

    const [purchase, setPurchase] = useState('');
    const [unitPurchased, setUnitPurchased] = useState('');
    const [purchaseCarts, setPurchaseCarts] = useState([]);
    const {search} = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        let purchaseId = queryParams.get('purchaseId');
        let userId = queryParams.get('userId');

        const retrievePurchase = async () => {
            let data = await getUserPurchase(userId, purchaseId);
            console.log("les données ")
            console.log(data);
            setPurchase(data);
            setUnitPurchased(data)
            setPurchaseCarts(data.purchase_carts);
        }

        retrievePurchase();
    }, [search]);


    return (
        <>
            <PurchaseHeader/>
            {/* Page content */}

            <Container className="mt-3" fluid>
                {/* Table */}
                <Row>
                    <h2>Purchase details</h2>
                </Row>
                <Row className="mb-1">
                    <div className="col-md-3"></div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col">Transaction ID :</div>
                            <div className="col">{(unitPurchased.transaction_id)}</div>
                        </div>
                    </div>
                </Row>
                <Row className="mb-1">
                    <div className="col-md-3"></div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col">Payment method :</div>
                            <div className="col">{(unitPurchased.payment_method)}</div>
                        </div>
                    </div>
                </Row>
                <Row className="mb-1">
                    <div className="col-md-3"></div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col">Transaction status :</div>
                            <div className="col">{(unitPurchased.status)}</div>
                        </div>
                    </div>
                </Row>
                <Row className="mb-5">
                    <div className="col-md-3"></div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col">Transaction date:</div>
                            <div className="col">
                                {moment(unitPurchased.created_at).format("YYYY/MM/DD kk:mm:ss")}</div>
                        </div>
                    </div>
                </Row>
                <Row>
                    {
                        purchaseCarts?.map((item, index) =>
                            <div className="col-md-3" key={index}>
                                <div className="card" style={{width: '18rem'}}>
                                    <img className="card-img-top" src={MEDIA_URL + item?.image}
                                         alt="Card image cap"
                                         style={{height: "20vh"}}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">Product name : {item?.article_name}</h5>
                                        <h5 className="card-title">Product quantity : {item?.quantity}</h5>
                                        <h5 className="card-title">Product price : {item?.amount}</h5>
                                        <div className="row">
                                            <div className="col ml-4 mt-1 mb-2">
                                                <h5 className="card-title text-uppercase text-muted mb-0">Features and
                                                    values</h5>
                                                <span className="h6 font-weight-bold mb-0">
                                                    {item.feature_values?.map((itm, i) =>
                                                        <div key={i}>{itm.feature_name + ' => ' + itm.name}</div>)}
                                                    </span>
                                            </div>
                                        </div>
                                        <i className="fas fa-arrow-up text-success" style={{cursor: 'pointer'}}></i>
                                        {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                                    </div>
                                </div>
                            </div>
                        )}
                </Row>
            </Container>

        </>
    );
};

export default PurchaseUserDetails;
