import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";

import React, {useContext, useEffect, useState} from "react";
import '../filestyle.css';
import AdminMainHeader from "../../../components/Headers/AdminMainHeader";
import {useForm} from "react-hook-form";
import InputBase from "@material-ui/core/InputBase";
import * as AdminServices from "../../../services/AdminServices";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, Modal, Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import moment from "moment";
import {AuthContext} from "../../../context/AuthContext";
import ReactLoading from "react-loading";
//import {MEDIA_URL} from "../../../constants";
import ReactPaginate from "react-paginate";
import env from "ts-react-dotenv";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const CreateAdmin2 = () => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [danger, setDanger] = useState(false);
    const [deleteAdminModal, setDeleteAdminModal] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    const [admins, setAdmins] = useState(null);
    const [openNews, setOpenNews] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [openVehicle, setOpenVehicle] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [openModalForm, setOpenModalForm] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [lastPage, setLastPage] = useState('');
    const [adminDetails, setAdminDetails] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [nbElement, setNbElement] = useState('');


    const handleDeleteAdmin = () => {
        setDeleteAdminModal(!deleteAdminModal);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCloseDanger = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDanger(false);
    };

    const {
        register,
        handleSubmit
    } = useForm();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    const handleNews = () => {
        setOpenNews(!openNews);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleArticle = () => {
        setOpenArticle(!openArticle);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleVehicle = () => {
        setOpenVehicle(!openVehicle);
        setOpenTable(!openTable);
        setOff(!off);
    };
    const handleModalForm = () => {
        setOpenModalForm(!openModalForm);
    };

    const handleModalForm2 = () => {
        setOpenModalForm(!openModalForm);
        //setDanger(true);
    };

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDetails = () => {
        setOpenDetails(!openDetails);
    };
    const handleStatus = () => {
        setOpenStatus(!openStatus);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const Modal = () => (
        <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">

                    <Row>
                        <h2>Administrator informations</h2>
                        <Table className="align-items-center table-flush" responsive>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Email</td>
                                <td> {adminDetails.admin.email}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>Last name</span></td>
                                <td> {adminDetails.admin.last_name}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>First name</span></td>
                                <td> {adminDetails.admin.first_name}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>Username</span></td>
                                <td>  {adminDetails.admin.username}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>Phone</span></td>
                                <td>  {adminDetails.admin.phone}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>Status</span></td>
                                <td>  {adminDetails.admin.status}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Created at </span></td>
                                <td>  {moment(adminDetails.admin.created_at).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(adminDetails.admin.created_at).format("HH:mm")}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Updated at</span></td>
                                <td>  {moment(adminDetails.admin.updated_at).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(adminDetails.admin.created_at).format("HH:mm")}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <Row>
                                        <div className="col">
                                            <div className="card-profile-stats d-flex ">
                                                <div>
                                                    <span
                                                        className="heading">{adminDetails.news.value.pending + adminDetails.news.value.interested + adminDetails.news.value.sold
                                                        + adminDetails.news.value.released + adminDetails.news.value.finished + adminDetails.news.value.embarked}</span>
                                                    <span className="description">News</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className="heading">{adminDetails.articles.value.pending + adminDetails.articles.value.interested + adminDetails.articles.value.sold
                                                        + adminDetails.articles.value.released + adminDetails.articles.value.finished + adminDetails.articles.value.embarked}</span>
                                                    <span className="description">Articles</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className="heading">{adminDetails.vehicles.value.pending + adminDetails.vehicles.value.interested + adminDetails.vehicles.value.sold
                                                        + adminDetails.vehicles.value.released + adminDetails.vehicles.value.finished + adminDetails.vehicles.value.embarked}</span>
                                                    <span className="description">Vehicles</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetails} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const UserDetails = () => (
        <>
            {/*<UserHeader />*/}
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    {/*<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">*/}
                    <Col className="order-xl-1" xl="8">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="rounded-circle"
                                                src={MEDIA_URL + adminDetails.admin.avatar}
                                            />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    {adminDetails.admin.status === 'ACCEPTED' ? <Button
                                        className="mr-4"
                                        color="danger"
                                        size="sm"
                                        id={adminDetails.admin.id}
                                        onClick={banishAdmin}
                                    >
                                        Bannish
                                    </Button> : <Button
                                        className="mr-4"
                                        color="info"
                                        size="sm"
                                        id={adminDetails.admin.id}
                                        onClick={banishAdmin}
                                    >
                                        Restore
                                    </Button>}

                                    <Button
                                        className="float-right"
                                        color="default"
                                        value={adminDetails.admin.email}
                                        href="#pablo"
                                        onClick={(e) => {
                                            resendMail(e);
                                        }}
                                        size="sm"
                                    >
                                        Resend Mail
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">

                                <Row>
                                    <div className="col">

                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                    <span
                                                        className="heading">{adminDetails.news.value.pending + adminDetails.news.value.interested + adminDetails.news.value.sold
                                                        + adminDetails.news.value.released + adminDetails.news.value.finished + adminDetails.news.value.embarked}</span>
                                                <span className="description">News</span>
                                            </div>
                                            <div>
                                                    <span
                                                        className="heading">{adminDetails.articles.value.pending + adminDetails.articles.value.interested + adminDetails.articles.value.sold
                                                        + adminDetails.articles.value.released + adminDetails.articles.value.finished + adminDetails.articles.value.embarked}</span>
                                                <span className="description">Articles</span>
                                            </div>
                                            <div>
                                                    <span
                                                        className="heading">{adminDetails.vehicles.value.pending + adminDetails.vehicles.value.interested + adminDetails.vehicles.value.sold
                                                        + adminDetails.vehicles.value.released + adminDetails.vehicles.value.finished + adminDetails.vehicles.value.embarked}</span>
                                                <span className="description">Vehicles</span>
                                            </div>
                                        </div>
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                     <span
                                                         className="heading">{adminDetails.vehicles.likes + adminDetails.articles.likes +
                                                         adminDetails.news.likes}</span>
                                                <span className="description">Likes</span>
                                            </div>
                                            <div>
                                                    <span
                                                        className="heading">{adminDetails.vehicles.comments + adminDetails.articles.comments +
                                                        adminDetails.news.comments}</span>
                                                <span className="description">Comments</span>
                                            </div>

                                        </div>
                                        <center>
                                            <h2>User informations</h2>
                                            <Table className="align-items-center table-flush" responsive>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Email</td>
                                                    <td> {adminDetails.admin.email}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>Last name</span></td>
                                                    <td> {adminDetails.admin.last_name}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>First name</span></td>
                                                    <td> {adminDetails.admin.first_name}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>Username</span></td>
                                                    <td>  {adminDetails.admin.username}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>Phone</span></td>
                                                    <td>  {adminDetails.admin.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><span>Status</span></td>
                                                    <td>  {adminDetails.admin.status}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <span>Created at </span></td>
                                                    <td>  {moment(adminDetails.admin.created_at).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(adminDetails.admin.created_at).format("HH:mm")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <span>Updated at</span></td>
                                                    <td>  {moment(adminDetails.admin.updated_at).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(adminDetails.admin.created_at).format("HH:mm")}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                            <Table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        {/*<Row>*/}
                                                        {/*    <div className="col">*/}
                                                        {/*        <div className="card-profile-stats d-flex ">*/}
                                                        {/*            <div>*/}
                                                        {/*                <span className="heading">{item.news}</span>*/}
                                                        {/*                <span className="description">News</span>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div>*/}
                                                        {/*                <span className="heading">{item.articles}</span>*/}
                                                        {/*                <span className="description">Articles</span>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div>*/}
                                                        {/*                <span className="heading">{item.vehicles}</span>*/}
                                                        {/*                <span className="description">Vehicles</span>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</Row>*/}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </center>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">

                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        All vehicles
                                    </Button>
                                    <span> {adminDetails.vehicles.likes}</span><span>likes</span><span> {adminDetails.vehicles.comments}</span><span>comments</span>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Table className="align-items-center table-flush" responsive>
                                    <tbody>
                                    <tr>
                                        <td> Pending</td>
                                        <td> {adminDetails.vehicles.value.pending}</td>
                                    </tr>
                                    <tr>
                                        <td> Interested</td>
                                        <td> {adminDetails.vehicles.value.interested}</td>
                                    </tr>
                                    <tr>
                                        <td> Sold</td>
                                        <td> {adminDetails.vehicles.value.sold}</td>
                                    </tr>
                                    <
                                        tr>
                                        <td> Released</td>
                                        <td> {adminDetails.vehicles.value.released}</td>
                                    </tr>
                                    <tr>
                                        <td> Finished</td>
                                        <td> {adminDetails.vehicles.value.finished}</td>
                                    </tr>
                                    <tr>
                                        <td> Embarked</td>
                                        <td> {adminDetails.vehicles.value.embarked}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card className="card-profile shadow">

                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        All articles
                                    </Button>
                                    <span> {adminDetails.articles.likes}</span><span>likes</span><span> {adminDetails.articles.comments}</span><span>comments</span>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Table className="align-items-center table-flush" responsive>

                                    <tbody>
                                    <tr>
                                        <td> Pending</td>
                                        <td> {adminDetails.articles.value.pending}</td>
                                    </tr>
                                    <tr>
                                        <td> Interested</td>
                                        <td> {adminDetails.articles.value.interested}</td>
                                    </tr>
                                    <tr>
                                        <td> Sold</td>
                                        <td> {adminDetails.articles.value.sold}</td>
                                    </tr>
                                    <tr>
                                        <td> Finished</td>
                                        <td> {adminDetails.articles.value.finished}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <Card className="card-profile shadow">

                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        All news
                                    </Button>
                                    <span> {adminDetails.news.likes}</span><span>likes</span><span> {adminDetails.news.comments}</span><span>comments</span>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Table className="align-items-center table-flush" responsive>

                                    <tbody>
                                    <tr>
                                        <td> Pending</td>
                                        <td> {adminDetails.news.value.pending}</td>
                                    </tr>
                                    <tr>
                                        <td> Interested</td>
                                        <td> {adminDetails.news.value.interested}</td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td> Released</td> <td> {item.news.released}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td> Finished</td> <td> {item.news.finished}</td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                    </Col>

                </Row>
            </Container>
        </>
    );

    const ModalStatus = ({object: {item}}) => (
        <Dialog open={openStatus} onClose={handleStatus} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Status change</DialogTitle>
            <DialogContent>

                <span>Current admin is</span> : {item.status}
                {item.status !== 'BANNED' ?
                    <p><span>Click here to banish the admin </span> <Button id={item.admin.id} onClick={banishAdmin}
                                                                            color="danger">
                        Banish
                    </Button></p> :
                    <p><span>Click here to restore the admin </span><Button id={item.admin.id} onClick={banishAdmin}
                                                                            color="success">
                        Restore
                    </Button></p>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStatus} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const retrieveAdmins = async () => {
        let admins = await AdminServices.getAll();
        setAdmins(admins);
    }

    const retrieveAdminsPaginated = async (page) => {
        let response = await AdminServices.getPaginatedUsers(page);
        console.log(response);
        setAdmins(response.data);
    }

    const retrieveAdminDetails = async (id) => {
        let response = await AdminServices.adminDetails(id);
        setAdminDetails(response.data);
        handleDetails();
        // setAdmins(response.data);
    }

    const retrieveAdminDetailsEdit = async (id) => {
        let response = await AdminServices.adminDetails(id);
        setAdminDetails(response.data);
        handleStatus();
    }

    const saveAdmin = async (data) => {
        setOpenModalForm(false);
        let newAdmin = AdminServices.createAdmin(data);
        setOpenNews(false);
        setOpenTable(true);
        setOff(!off);
        setOpen(true);
        let response = await AdminServices.getPaginatedAdmins(1, parseInt("25"));
        setAdmins(response.data);
        // let response = await AdminServices.getPaginatedAdmins();
        // setAdmins(response.data);

    }

    const resendMail = async (e) => {
        try {
            let message = await AdminServices.resendMailAdmin(e);
            setAlertMessage(message);
            setAlertType("success");
            handleOpenAlert();

        } catch (e) {
            setAlertMessage(e.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const banishAdmin = async (e) => {
        const id = e.target.getAttribute("id");
        const result = await AdminServices.bannishAdmin(id);
        setAlertMessage(result.message);
        setAlertType("success");
        handleOpenAlert();
        setOpenStatus(false);
        setOpenNews(false);
        setOpenTable(true);
        let response = await AdminServices.getPaginatedAdmins(1, parseInt("25"));
        setAdmins(response.data);
        // let response = await AdminServices.getPaginatedAdmins();
        // setAdmins(response.data);
    }

    const deleteAdmin = async () => {
        setOpenDeleteModal(false);
        const id = activeObject.item.id;
        await AdminServices.deleteAdmin(id);
        setDeleteAdminModal(true);

        // let response = await AdminServices.getPaginatedAdmins();
        // setAdmins(response.data);

        let response = await AdminServices.getPaginatedAdmins(1, parseInt("25"));
        setAdmins(response.data);
    }

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let response = await AdminServices.getPaginatedAdmins(requestedPage, parseInt(nbElement));
        setAdmins(response.data);
        setLastPage(response.last_page);
        //retrieveAdminsPaginated(requestedPage);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setNbElement(nb);
        console.log(nbElement);
        let response = await AdminServices.getPaginatedAdmins(1, parseInt(nb));
        setAdmins(response.data);
        setLastPage(response.last_page);
    }

    const searchAdmin = async () => {
        console.log(searchText);
        let response = await AdminServices.searchWithIndicator(searchText);
         console.log(response);
        setAdmins(response);
    }


    useEffect(() => {
        const retrieveAdminsPaginated = async () => {
            let response = await AdminServices.getPaginatedAdmins(1, parseInt("25"));
            // console.log(response);
            setAdmins(response.data);
            setLastPage(response.last_page);
            setLoading(false);
        }
        retrieveAdminsPaginated();
    }, []);

    // useEffect(() => {
    //     const retrieveAdmins = async () => {
    //         let admins = await AdminServices.getAll();
    //         console.log(admins);
    //         setAdmins(admins);
    //         setLoading(false);
    //     }
    //     retrieveAdmins();
    // }, []);

    const {user} = useContext(AuthContext);
    return (
        <>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={3000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openDeleteModal} onClose={handleDeleteModal} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(deleteAdmin)}>
                    <DialogTitle id="form-dialog-title">Delete Post</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this admin ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openModalForm} onClose={handleModalForm} aria-labelledby="form-dialog-title">
                <form role="form" onSubmit={handleSubmit(saveAdmin)}>
                    <DialogTitle id="form-dialog-title">Add New Admin</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to create this admin ?</h3>
                        {/* <div className="pl-lg-4">
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-3">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                        >
                                            Email Address
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputBase
                                                placeholder="Email"
                                                type="email"
                                                autoComplete="new-email"
                                                name="email"
                                                {...register('email')}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalForm2} color="danger">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <AdminMainHeader handleNews={handleNews} handleArticle={handleArticle} handleVehicle={handleVehicle}
                             off={off}/>
            {/* Page content */}

            {openNews ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Create an admin</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleNews}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form role="form">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup className="mb-3">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email Address
                                                    </label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputBase
                                                            placeholder="Email"
                                                            type="email"
                                                            autoComplete="new-email"
                                                            name="email"
                                                            {...register('email')}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="pl-lg-4">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                {/* <h3 className="mb-0"></h3> */}
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                <Button
                                                    color="primary"
                                                    onClick={handleModalForm}
                                                    size="sm"
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}
            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Admins</h3>
                                {/*<Form>*/}
                                {/*    <FormGroup className="mb-0" style={{marginLeft: "70%"}}>*/}
                                {/*        <InputGroup className="input-group-alternative">*/}
                                {/*            <InputGroupAddon addonType="prepend">*/}
                                {/*                <InputGroupText>*/}
                                {/*                    <i className="fas fa-search" onClick={searchAdmin}/>*/}
                                {/*                </InputGroupText>*/}
                                {/*            </InputGroupAddon>*/}
                                {/*            <InputBase placeholder="Search" type="text" name="searchText"*/}
                                {/*                       placeholder="search text"*/}
                                {/*                       style={{width: "100px"}} onChange={(e) => {*/}
                                {/*                // console.log(e.target.value);*/}
                                {/*                setSearchText(e.target.value)*/}
                                {/*            }}/>*/}
                                {/*        </InputGroup>*/}
                                {/*    </FormGroup>*/}
                                {/*</Form>*/}
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spinningBubbles"} color="green"/> :
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Avatar</th>
                                        <th scope="col">UserName(s)</th>
                                        <th scope="col">Last name(s)</th>
                                        <th scope="col">First name(s)</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">...</th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        admins.map((item) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        <div className="avatar-group">

                                                            <a
                                                                className="avatar avatar-sm"
                                                                href="#pablo"
                                                                id="tooltip941738690"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    className="rounded-circle"
                                                                    src={MEDIA_URL + item.avatar}
                                                                />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <th scope="row">
                                <span className="mb-0 text-sm">
                            {item.username}
                                </span>
                                                    </th>
                                                    <th scope="row">
                                <span className="mb-0 text-sm">
                            {item.first_name}
                                </span>
                                                    </th>
                                                    <th scope="row">
                                <span className="mb-0 text-sm">
                            {item.last_name}
                                </span>
                                                    </th>
                                                    <th scope="row">
                                <span className="mb-0 text-sm">
                            {item.email}
                                </span>
                                                    </th>

                                                    <td>
                                                        {item.status
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                <span
                                    className="material-icons"
                                    style={{cursor: 'pointer', color: 'dodgerblue'}}
                                    onClick={() => {
                                        // setActiveObject({item});
                                        // handleStatus();
                                        retrieveAdminDetailsEdit(item.id);
                                    }}
                                >edit

                                </span>

                                                            <span
                                                                className='material-icons'
                                                                style={{cursor: 'pointer', color: 'firebrick'}}
                                                                id={item.id}
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    handleDeleteModal();
                                                                }}
                                                            >
                                delete
                                </span>
                                                            <span
                                                                className='material-icons danger'
                                                                style={{cursor: 'pointer', color: 'dodgerblue'}}
                                                                onClick={() => {
                                                                    retrieveAdminDetails(item.id);
                                                                }}
                                                            >
                                visibility
                                </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            }

                            <div>
                                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="success">
                                        Admin well created !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={danger} autoHideDuration={2000} onClose={handleCloseDanger}>
                                    <Alert onClose={handleCloseDanger} severity="error">
                                        Admin cancelled !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={deleteAdminModal} autoHideDuration={2000} onClose={handleCloseDanger}>
                                    <Alert onClose={handleDeleteAdmin} severity="error">
                                        Admin deleted !
                                    </Alert>
                                </Snackbar>
                            </div>
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
            {openDetails ? <Modal object={activeObject}/> : null}
            {openStatus ? <UserDetails object={activeObject}/> : ""}
            {/*{openStatus ? <ModalStatus object={activeObject} /> : null}*/}
        </>
    );
};

export default CreateAdmin2;
