import React from "react";
import './filestyle.css';

const FileComponent = props => {
  const handleClick = () => {
    props.onRemoveElement();
  };
  return (

    <div className="fil" onClick={handleClick}>
                                                         <span>CLICK TO REMOVE &nbsp;</span>   {props.name}
                                                            
                                                        </div>

  );
};
export default FileComponent;
