import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = (props) => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-4">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                          Total articles
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{props.total_article}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-palette" />
                        </div>
                      </Col>
                    </Row>
                    {/*<p className="mt-3 mb-0 text-muted text-sm">*/}
                    {/*  <span className="text-success mr-2">*/}
                    {/*    <i className="fas fa-arrow-up" />0%*/}
                    {/*  </span>{" "}*/}
                    {/*  <span className="text-nowrap">Since last month</span>*/}
                    {/*</p>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total users
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{props?.nb_users}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow  text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/*<p className="mt-3 mb-0 text-muted text-sm">*/}
                    {/*  <span className="text-danger mr-2">*/}
                    {/*    <i className="fas fa-arrow-down" />0%*/}
                    {/*  </span>{" "}*/}
                    {/*  <span className="text-nowrap">Since last week</span>*/}
                    {/*</p>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Sales
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{props.sales}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-trophy" />
                        </div>
                      </Col>
                    </Row>
                    {/*<p className="mt-3 mb-0 text-muted text-sm">*/}
                    {/*  <span className="text-warning mr-2">*/}
                    {/*    <i className="fas fa-arrow-down" /> 0%*/}
                    {/*  </span>{" "}*/}
                    {/*  <span className="text-nowrap">Since yesterday</span>*/}
                    {/*</p>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                          Total sales amount
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {props.total_purchase} XAF
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow  text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    {/*<p className="mt-3 mb-0 text-muted text-sm">*/}
                    {/*  <span className="text-success mr-2">*/}
                    {/*    <i className="fa fa-arrow-up" />*/}
                    {/*    0%*/}
                    {/*  </span>{" "}*/}
                    {/*  <span className="text-nowrap">Since last month</span>*/}
                    {/*</p>*/}
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
