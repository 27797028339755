import axios from "axios";

export function getAllPost() {
    return new Promise((resolve, reject) => {
        axios
            .get('/posts')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getArticles() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/articles')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedArticle(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/articles/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/articles/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export const createArticle = (data, images, articleFile, articlePreviewFile, updateProgress) => {

    let articleFormData = new FormData();

    for (const img of images) {
        articleFormData.append("images[]", img.file);
    }

    articleFormData.append("article_file", articleFile.file);
    articleFormData.append("preview_file", articlePreviewFile.file);

    articleFormData.append("sub_category", data.sub_category);

    articleFormData.append("name", data.name);
    articleFormData.append("short_description", data.short_description);
    articleFormData.append("long_description", data.long_description);
    articleFormData.append("reduction_type", data.reduction_type);
    articleFormData.append("type", data.type);
    articleFormData.append("price", data.price);
    articleFormData.append("reduction_price", data.reduction_price);
    //articleFormData.append("quantity", data.quantity);


    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent <= 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/create/article`, articleFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                //resolve(response.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const addNewVariant = (article_id, variants, updateProgress) => {

    let articleFormData = new FormData();

    for (const variant of variants) {
        let variant_key = variant.variant_key;
        console.log(variant_key);
        for(const img of variant.variant_images){
            articleFormData.append(variant_key + '[]', img.file);
        }
    }

    for (const variant of variants) {
        articleFormData.append("variants[]", JSON.stringify(variant));
    }

    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/add/article/${article_id}/variant`, articleFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const updateArticle = (data, currentArticle, article_id, images, articleFile, articlePreviewFile, toDeleteImages, updateProgress) => {

    let articleFormData = new FormData();

    for (const img of images) {
        articleFormData.append("images[]", img.file);
    }

    if(articleFile != null){
        articleFormData.append("article_file", articleFile.file);
    }

    if(articlePreviewFile != null){
        articleFormData.append("preview_file", articlePreviewFile.file);
    }

    articleFormData.append("sub_category", data.sub_category);

    articleFormData.append("name", data.name);
    articleFormData.append("short_description", data.short_description);
    articleFormData.append("long_description", data.long_description);
    articleFormData.append("type", data.type);
    //articleFormData.append("quantity", data.quantity);

    articleFormData.append("price", data.price);
    articleFormData.append("reduction_price", data.reduction_price);
    articleFormData.append("reduction_type", data.reduction_type);
    articleFormData.append("to_delete_img", toDeleteImages);


    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/article/${article_id}`, articleFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const updateVariant = (currentVariant, variantImages, toDeleteVariantImages, variantReductionPrice, variantReductionType) => {

    let articleFormData = new FormData();

    for (const img of variantImages) {
        articleFormData.append("images[]", img.file);
    }

    articleFormData.append("quantity", currentVariant.quantity);
    articleFormData.append("price", currentVariant.price);
    articleFormData.append("to_delete_img", toDeleteVariantImages);
    articleFormData.append("reduction_price", variantReductionPrice);
    articleFormData.append("reduction_type", variantReductionType);

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/variant/${currentVariant.id}`, articleFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteArticle(post_id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/article/${post_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function changeStatus(id, newStatus) {

    let statusFormData = new FormData();
    console.log(newStatus);

    statusFormData.append("status", newStatus);

    return new Promise((resolve, reject) => {
        axios
            .post(`/status/post/${id}`, statusFormData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deactivateArticle(id) {

    return new Promise((resolve, reject) => {
        axios
            .put(`/deactivate/post/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function activateArticle(id) {

    return new Promise((resolve, reject) => {
        axios
            .put(`/activate/post/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

// export function getPostsTypePaginated(type, id, nb_of_element)  {
//     let formData = new FormData();
//     formData.append("numberElement", nb_of_element);
//
//     return new Promise((resolve, reject) => {
//         axios
//             .post('/get/posts/type/${type}/paginate?page=${id}', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             })
//             .then( response => {
//                 resolve(response.data.data);
//             })
//             .catch(error => {
//                 reject(error.response.data);
//             });
//     });
//
// };

export function getPostsTypePaginated(type, id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate?page=${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}
//
// export function getPostsTypePaginated(type, id) {
//     if (id !== undefined) {
//         return new Promise((resolve, reject) => {
//             axios
//                 .get(`/get/posts/type/${type}/paginate?page=${id}`)
//                 .then(response => {
//                     resolve(response.data.data);
//                 })
//                 .catch(error => {
//                     reject(error.response.data);
//                 });
//         });
//     } else {
//         return new Promise((resolve, reject) => {
//             axios
//                 .get(`/get/posts/type/${type}/paginate`)
//                 .then(response => {
//                     resolve(response.data.data);
//                 })
//                 .catch(error => {
//                     reject(error.response.data);
//                 });
//         });
//     }
// }


export function getPurchase(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/purchase/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getPostsType(type) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/posts/type/${type}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getUserPurchase(userId, purchaseId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/users/${userId}/purchases/${purchaseId}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function addArticle(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/add/article/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getSelectedArticles() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/selected/articles')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteSelectedArticle(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/article/${id}/selected`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}