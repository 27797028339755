import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import axios from "axios";
import { AuthContextProvider } from "./context/AuthContext";
import "./services/i18n";
import env from "ts-react-dotenv";
import ProtectionAdmin from "ProtectionAdmin";

// set main base url
//axios.defaults.baseURL = "http://localhost:8000/api";
//axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVICE+"/api";
//axios.defaults.baseURL = env.REACT_APP_BACKEND_SERVICE+"/api";

axios.defaults.baseURL = env.REACT_APP_BACKEND_SERVICE+"/api";

console.log("adresse du backend");
console.log(env.REACT_APP_BACKEND_SERVICE+"/api");
//axios.defaults.baseURL = "https://api.dodo.ntb-entreprise.de/api";

// Request interceptor for API calls
axios.interceptors.request.use(
  async config => {

    let access_token = "";
    if(localStorage.getItem("access_token")){
      access_token = localStorage.getItem("access_token");
    }

    if(sessionStorage.getItem("access_token")){
      access_token = sessionStorage.getItem("access_token");
    }

    config.headers = {
      'Authorization': `Bearer ${access_token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });


// Response interceptor for API calls
axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");
    window.location.replace('/auth/login');
  }
  return Promise.reject(error);
});


ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <BrowserRouter>
        <AuthContextProvider>
          <Switch>
            <Route path="/admin" render={(props) => <ProtectionAdmin {...props} Cmp={AdminLayout} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </AuthContextProvider>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
