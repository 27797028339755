import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Table,
    Media, Form, FormGroup,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, { useContext, useEffect, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import {getChats, getChatsPaginated} from "services/ChatServices";
import { useHistory } from "react-router-dom";
import { takeChat, continueChat } from "services/ChatServices";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AllChat = (props) => {

    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [chats, setChats] = useState([]);
    const [lastPage, setLastPage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const history = useHistory();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    useEffect(() => {
        const retrieveChats = async () => {
            let chats = await getChatsPaginated(1, undefined);
            setChats(chats.data);
            setLastPage(chats.meta.last_page);
        }

        retrieveChats();
    }, []);

    const retrieveChatsPaginated = async (page) => {
        let chats = await getChatsPaginated(page, undefined);
        setChats(chats.data);
    }

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let chats = await getChatsPaginated(parseInt(requestedPage), nbPageElement);
        setChats(chats.data);
        setLastPage(chats.meta.last_page);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setNbPageElement(parseInt(nb));
        let chats = await getChatsPaginated(1, parseInt(nb));
        setChats(chats.data);
        setLastPage(chats.meta.last_page);
    }

    const goToChat = async (e) => {

        let resuly = e.target.getAttribute("value");
        // console.log(resuly);
        let chat_id = parseInt(e.target.getAttribute("value"));
        let admin_id = user.id;

        try {
            await takeChat(chat_id, admin_id);
            props.history.push({
                pathname: '/admin/single-chat',
                search: '?chatId=' + chat_id,
            });

        } catch (error) {
        }
    }

    const continueToChat = async (e) => {

        let resuly = e.target.getAttribute("value");
        // console.log(resuly);
        let chat_id = parseInt(e.target.getAttribute("value"));
        let admin_id = user.id;

        try {
            await continueChat(chat_id, admin_id);
            props.history.push({
                pathname: '/admin/single-chat',
                search: '?chatId=' + chat_id,
            });

        } catch (error) {
        }
    }

    return (
        <>
            <ChatHeader />
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Chats Here</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Post Name</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Admin Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        chats?.map((item) => 
                                            <>
                                                {item?.is_empty === 1 ?

                                                    <tr key={item.id}>
                                                        <th scope="row">
                                                            <Media className="align-items-center">
                                                                <Media>
                                                                    <span className="mb-0 text-sm">
                                                                        {item.post_name}
                                                                    </span>
                                                                </Media>
                                                            </Media>
                                                        </th>
                                                        <td>{item.user_name}</td>
                                                        <td>{item.admin_name}</td>
                                                        <td>
                                                            {item.status}
                                                        </td>
                                                        <td>
                                                            {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="d-flex px-2 py-1">
                                                                {item.status === "OPENED" ?
                                                                    <Tooltip title="Take this chat" placement="top">
                                                                        <span
                                                                            className='material-icons danger'
                                                                            value={item.id}
                                                                            onClick={(e) => {
                                                                                setActiveObject({ item });
                                                                                goToChat(e);
                                                                            }}
                                                                            style={{ cursor: 'pointer', color: 'green' }}>
                                                                            check
                                                                        </span>
                                                                    </Tooltip> :
                                                                    ""
                                                                }
                                                                {(["PENDING", 4].includes(item?.status)) ?
                                                                    <Tooltip title="Continue this Chat" placement="top">
                                                                        <span
                                                                            className='material-icons danger'
                                                                            value={item.id}
                                                                            onClick={(e) => {
                                                                                setActiveObject({ item });
                                                                                continueToChat(e);
                                                                            }}
                                                                            style={{ cursor: 'pointer', color: 'orange' }}>
                                                                            check
                                                                        </span>
                                                                    </Tooltip>
                                                                    :
                                                                    ""
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    : null

                                                }
                                            </>
                                        
                                        )
                                    }
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                <select className="form-control" onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default AllChat;
