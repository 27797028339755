/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Button, Col, Row, Container, Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AuthContext } from "context/AuthContext";
import { useForm, FormProvider } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { getJobs } from "services/JobServices";
import { createJob } from "services/JobServices";
import { updateJob } from "services/JobServices";
import { deleteJob } from "services/JobServices";
import JobForm from "./JobForm";
import JobsTable from "./JobsTable";
import JobHeader from "components/Headers/JobHeader";


const modules = {
  toolbar: [
    // Header options: choose between different header levels or no header
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // Font style options: bold, italic, underline, strike
    ["bold", "italic", "underline", "strike"],

    // Script/subscript/superscript: allows superscript or subscript text
    [{ script: "sub" }, { script: "super" }],

    // List options: ordered list or bullet list
    [{ list: "ordered" }, { list: "bullet" }],

    // Blockquote and code-block
    ["blockquote", "code-block"],

    // Text alignment options: left, center, right, justify
    [{ align: [] }],

    // Link, image, and video insertion
    ["link", "image", "video"],

    // Text color and background color

    [{ color: [] }, { background: [] }],

    // Font size options
    [{ size: ["small", false, "large", "huge"] }],

    // Dropdown for font family selection
    [{ font: [] }],

    // Indent and outdent options
    [{ indent: "-1" }, { indent: "+1" }],

    // Direction of the text
    [{ direction: "rtl" }],

    // Clean: removes formatting from the selected text
    ["clean"],
  ],
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Job = (props) => {

  const methods = useForm();

  const { user } = useContext(AuthContext);
  const [openJob, setOpenJob] = useState(false);
  const [openEditJob, setOpenEditJob] = useState(false);
  const [openTable, setOpenTable] = useState(true);
  const [off, setOff] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [lastPage, setLastPage] = useState(1);
  const [activeObject, setActiveObject] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [formattedAnswer, setFormattedAnswer] = useState("");
  const [formattedDescription, setFormattedDescription] = useState("");
  const [jobQuestion, setJobQuestion] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [expectations, setExpectations] = useState([]);
  const [toEditQualifications, setToEditQualifications] = useState([]);
  const [toEditBenefits, setToEditBenefits] = useState([]);
  const [toEditExpectations, setToEditExpectations] = useState([]);


  const [currentJob, setCurrentJob] = useState({
    id: "",
    title: "",
    type: "1",
    salary_start: 0,
    salary_end: 0,
    start_date: 0,
    end_date: 0,
    contact_first_name: "",
    contact_last_name: "",
    contact_email: "",
    contact_phone: "",
    description: "",
    domain: "",
    expectations: [],
    benefits: [],
    qualifications: [],
  });

  const handleChangeInput = (e) => {
    setCurrentJob((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDeleteModal = () => {
    if (openDeleteModal) {
      setActiveObject(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const retrieveJobs = async () => {
    let data = await getJobs();
    let arr = Object.values(data);
    setJobs(arr);
  };

  const onCancel = () => {
    setOpenJob(false);
    setOpenEditJob(false);
    setOpenTable(true);
    setOff(false);
    methods.reset();
    setFormattedDescription("");
    resetCurrentJob();
  };

  const onAdd = () => {
    console.log('add on click');
    setOpenJob(true);
    setOpenEditJob(false);
    setOpenTable(false);
    setOff(true);
    methods.reset();
    resetCurrentJob();
  };

  useEffect(() => {
    const retrieveJobs = async () => {
      let data = await getJobs();
      let arr = Object.values(data);
      setJobs(arr);
      console.log('laod jobs')
      setLoading(false);
    };
    retrieveJobs();
  }, []);

  const saveJob = async (data) => {
    if (qualifications.length < 1) {
      setAlertMessage("You have to enter atleast one qualification");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (expectations.length < 1) {
      setAlertMessage("You have to enter atleast one expectation");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (benefits.length < 1) {
      setAlertMessage("You have to enter atleast one benefits");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    
    setSubmited(true);

    try {
      setOff(true);
      await createJob(data, qualifications, expectations, benefits, formattedDescription);
      setOpenJob(false);
      setOpenTable(true);
      retrieveJobs();
      setAlertMessage("Job created sucessfully");
      setAlertType("success");
      methods.reset();
      setSubmited(false);
      resetCurrentJob();
      setOff(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
    } finally {
      handleOpenAlert();
      setSubmited(false);
    }
  };

  const resetCurrentJob = () => {
    setCurrentJob({
      id: "",
      title: "",
      type: "1",
      salary_start: 0,
      salary_end: 0,
      start_date: null,
      end_date: null,
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_phone: "",
      description: "",
      domain: "",
      expectations: [],
      benefits: [],
      qualifications: [],
    });
    setQualifications([]);
    setBenefits([]);
    setExpectations([]);
  };

  const updateCurrentJob = async (data) => {
    setSubmited(true);

    try {
      setOff(true);
      await updateJob(jobId, data, qualifications, expectations, benefits, formattedDescription);
      setJobId("");
      setOpenJob(false);
      setOpenEditJob(false);
      setOpenTable(true);
      setAlertMessage("Job updated successfully");
      setAlertType("success");
      retrieveJobs();
      methods.reset();
      setSubmited(false);
      resetCurrentJob();
      setOff(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
    } finally {
      handleOpenAlert();
      setSubmited(false);
    }
  };

  const delJob = async () => {
    setOpenDeleteModal(false);

    let job_id = currentJob.id;

    try {
      await deleteJob(job_id);

      setAlertMessage("Job deleted successfully");
      setAlertType("success");
      setActiveObject(null);
      retrieveJobs();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
    } finally {
      handleOpenAlert();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const startEditJob = (item) => {
    setActiveObject({ item });
    console.log(item);
    setOpenJob(false);
    setOpenTable(false);
    setOpenEditJob(true);
    setJobId(item.id);
    setOff(true);
    setJobQuestion(item.question_en);
    setFormattedDescription(item.description);
  };

  const handleDeleteBen = (key) => {
    const updatedItems = [...benefits];
    const myBenefits = updatedItems.filter((element) => element.key !== key);
    setBenefits(myBenefits);
  };

  const handleDeleteExp = (key) => {
    const myExpectations = expectations.filter((element) => element.key !== key);
    setExpectations(myExpectations);
  };

  const handleDeleteQual = (key) => {
    const myQualifications = qualifications.filter((element) => element.key !== key);
    setQualifications(myQualifications);
  };

  const goToJobApplications = (e) => {
    let job_id = parseInt(e.target.getAttribute("value"));

    props.history.push({
      pathname: "/admin/single-job-applications",
      search: "?jobId=" + job_id,
    });
  };

  return (
    <>
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle id="form-dialog-title">Delete Job</DialogTitle>
          <DialogContent>
            <h3 className="mb-0">Are you sure you want to delete this Job ?</h3>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteModal} color="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={delJob} color="danger">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <JobHeader onAdd={onAdd} off={off} />
      {/* Page content */}
      {openJob && (

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(saveJob)}>
            <Container className="mt--7 mb-3" fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Create a new Job</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button color="danger" onClick={onCancel} size="sm">
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <JobForm
                        onCancel={onCancel}
                        currentJob={currentJob}
                        setFormattedDescription={setFormattedDescription}
                        handleChangeInput={handleChangeInput}
                        formattedDescription={formattedDescription}
                        qualifications={qualifications}
                        benefits={benefits}
                        expectations={expectations}
                        setQualificationData={setQualifications}
                        setBenefitData={setBenefits}
                        setExpectationData={setExpectations}
                      />
                      <div className="pl-lg-4">
                        <Row className="align-items-center">
                          <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="warning"
                              type="submit"
                              size="sm"
                              disabled={submited
                              }
                            >
                              Submit
                            </Button>
                          </Col>

                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-xl-1" xl="4">
                  <Card className="bg-secondary shadow">
                    <CardBody>
                      <h3 >Expectations</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush" responsive>
                        {expectations?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteExp(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>

                      <h3 >Benefits</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush mt--2" responsive>
                        {benefits?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteBen(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>

                      <h3 >Qualifications</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush" responsive>
                        {qualifications?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteQual(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </form>

        </FormProvider >

      )}
      {
        openEditJob && (
          <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(updateCurrentJob)}>
            <Container className="mt--7 mb-3" fluid>
              <Row>
                <Col className="order-xl-1" xl="8">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Update current Job</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button color="danger" onClick={onCancel} size="sm">
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <JobForm
                        onCancel={onCancel}
                        currentJob={currentJob}
                        setFormattedDescription={setFormattedDescription}
                        handleChangeInput={handleChangeInput}
                        formattedDescription={formattedDescription}
                        qualifications={qualifications}
                        benefits={benefits}
                        expectations={expectations}
                        setQualificationData={setQualifications}
                        setBenefitData={setBenefits}
                        setExpectationData={setExpectations}
                      />
                      <div className="pl-lg-4">
                        <Row className="align-items-center">
                          <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                          <Col className="text-right" xs="4">
                            <Button
                              color="warning"
                              type="submit"
                              size="sm"
                              disabled={submited
                              }
                            >
                              Submit
                            </Button>
                          </Col>

                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-xl-1" xl="4">
                  <Card className="bg-secondary shadow">
                    <CardBody>
                      <h3 >Expectations</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush" responsive>
                        {expectations?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteExp(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>

                      <h3 >Benefits</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush mt--2" responsive>
                        {benefits?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteBen(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>

                      <h3 >Qualifications</h3>
                      <hr className="my-1" />
                      <Table className="align-items-center table-flush" responsive>
                        {qualifications?.map((item, index) => (
                          <tr key={index}><td> - {item.element}</td>
                            <td>  <Tooltip title="Delete" placement="top">
                              <span
                                className="material-icons"
                                onClick={() => {
                                  handleDeleteQual(item.key);
                                }}
                                style={{ cursor: "pointer", color: "firebrick" }}
                              >
                                delete
                              </span>
                            </Tooltip>
                            </td>
                          </tr>))}
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </form>

        </FormProvider >
        )
      }

      {
        openTable && (
          <JobsTable
            jobs={jobs}
            isLoading={isLoading}
            goToJobApplications={goToJobApplications}
            startEditJob={startEditJob}
            setCurrentJob={setCurrentJob}
            setToEditQualifications={setQualifications}
            setToEditBenefits={setBenefits}
            setToEditExpectations={setExpectations}
            handleDeleteModal={handleDeleteModal}
            lastPage={lastPage}
          />
        )
      }
    </>
  );
};

export default Job;
