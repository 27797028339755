
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  
  import React, {useState }  from "react";
  import { useForm } from "react-hook-form";
  import { forgot } from '../../../services/UserServices';
  import Snackbar from '@mui/material/Snackbar';
  import MuiAlert from '@mui/material/Alert';
  import InputBase from "@material-ui/core/InputBase";
   
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  function Forgot(props) {
  
    const {register, handleSubmit} = useForm();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
  
    const loginUser = async (data) => {
  
      console.log(data);
  
        try {
          await forgot(data)
  
          setAlertMessage("Password reset link successfully send !");
          setAlertType("success");
          handleOpenAlert();
          
        } catch (error) {
          setAlertMessage(error.message);
          setAlertType("error");
          handleOpenAlert();
        }
    }
  
  
    const handleOpenAlert = () => {
      setOpenAlert(true);
    };
    
    const handleCloseAlert = () => {
      setOpenAlert(false);
    };

    
    return (
      <>
        <Snackbar anchorOrigin={{ vertical:"top", horizontal:"right"}} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
             <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Forgot Your Password ?</small>
              </div>
            </CardHeader> 
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={handleSubmit(loginUser)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputBase
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      name="email"
                      {...register('email')}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Send Password Reset Link
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="12">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Click again to send another if not received.</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  
  export default Forgot;
  