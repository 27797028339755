import React from "react";
import './filestyle.css';

const ImageComponent = props => {
  const handleClick = () => {
    props.onRemoveElement();
  };
  return (

    <div className="img" style={{ backgroundImage: `url('${props.value}')` }} onClick={handleClick} ><span>remove</span></div>
    

  );
};
export default ImageComponent;
