import axios from "axios";

export function getChat(chat_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/chat/${chat_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getChatsPaginated(id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .post('/paginate/chats?page='+`${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .post('/paginate/chats')
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

// export function getChatsPaginated(id) {
//     if (id !== undefined) {
//         return new Promise((resolve, reject) => {
//             axios
//                 .get(`/chats?page=${id}`)
//                 .then(response => {
//                     resolve(response.data.data);
//                 })
//                 .catch(error => {
//                     reject(error.response.data);
//                 });
//         });
//     } else {
//         return new Promise((resolve, reject) => {
//             axios
//                 .get(`/chats`)
//                 .then(response => {
//                     resolve(response.data.data);
//                 })
//                 .catch(error => {
//                     reject(error.response.data);
//                 });
//         });
//     }
// }

export function takeChat(chat_id, admin_id) {

    let cFormData = new FormData();

    cFormData.append("admin_id", admin_id);

    return new Promise((resolve, reject) => {
        axios
            .post(`/chat/${chat_id}/open`, cFormData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function continueChat(chat_id, admin_id) {

    let cFormData = new FormData();

    cFormData.append("admin_id", admin_id);

    return new Promise((resolve, reject) => {
        axios
            .post(`/chat/${chat_id}/continue`, cFormData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getChats(id, admin_id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/admin/${admin_id}/chats/paginated`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .post(`/admin/${admin_id}/chats/paginated`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

// export function getChats(admin_id) {
//
//     return new Promise((resolve, reject) => {
//         axios
//             .get(`/admin/${admin_id}/chats`)
//             .then(response => {
//                 resolve(response.data.data);
//             })
//             .catch(error => {
//                 reject(error.response.data);
//             });
//     });
//
// }

export function getMessages(chat_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/chat/${chat_id}/messages`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function sendMessage(admin_id, chat_id, content, messageType, image) {

    let msgForm = new FormData();

    msgForm.append("admin_id", admin_id);
    msgForm.append("chat_id", chat_id);
    msgForm.append("content", content);
    msgForm.append("type", messageType);
    msgForm.append("image", image?.file);

    return new Promise((resolve, reject) => {
        axios
            .post(`/admin/send/message`, msgForm, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getRecentChat(admin_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/admin/${admin_id}/recent/chat`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getRecentMessages(admin_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/admin/${admin_id}/recent/messages`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function closeCht(chat_id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/close/chat/${chat_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function leaveCht(chat_id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/leave/chat/${chat_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function changePostUserStatus(id, newStatus, quantity) {

    let statusFormData = new FormData();
    console.log(newStatus);
    console.log(quantity);

    statusFormData.append("status", newStatus);
    statusFormData.append("quantity", quantity);

    return new Promise((resolve, reject) => {
        axios
            .post(`/change/post-user-status/${id}/status`, statusFormData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPostStatus(chat_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/post-user-status/chat/${chat_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}
