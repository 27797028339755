//url to media in the local backend
//export const MEDIA_API_URL = "http://127.0.0.1:8000/api/media/";

//export const MEDIA_URL = "http://127.0.0.1:8000/media/";

//export const CLIENT_URL = "http://127.0.0.1:3000/";

//url to media in the local backend yaaaaaaa
//export const MEDIA_URL = "http://192.168.2.53:8001/media/";

//url to media in the online backend
//export const MEDIA_URL = "https://api.dodo.ntb-entreprise.de/media/";

//export const CLIENT_URL = "https://dodo.ntb-entreprise.de/";

//export const MEDIA_API_URL = "https://api.dodo.ntb-entreprise.de/api/media/";

export const inputStyle = {
    width: "300px",
    height: "30vh",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    border: "none",
};

export const inputStyleFaq = {
    width: "500px",
    height: "30vh",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    border: "none",
};


export const hastagHighlight = (element) => {
    //let hastags = element.match(/(\#[a-z0-9_A-Z]+\b)(?!;)/gm);
    let hastags = element.match(/(\#[a-z0-9A-Z][a-z0-9_A-Z]+\b)(?!;)/gm);
    if (hastags === null) {
        return element;
    } else {
        let result = element;
        for (let i = 0; i < hastags.length; i++) {
            result = result.replace(hastags[i], "<span style=\"color:blue\">" + hastags[i] + "</span >");
        }
        return result;
    }
}

export const computePrice = (article) => {
    //console.log(article);
    if (article) {
        let myReductionType = article.reduction_type;
        let myReductionPrice = article.reduction_price;
        let price = 0;
        if (myReductionType !== null) {
            if (myReductionType === "AMOUNT") {
                price = article.price - myReductionPrice;
                return price;
            }
            if (myReductionType === "PERCENTAGE" && myReductionPrice > 0) {
                let percentage = (myReductionPrice / 100);
                price = article.price - (article.price * percentage);
                return price;
            }
        }
        return article.price;
    }
    return 0;
}

export const computePercentage = (price, reduction_price) => {
  // console.log(price +" "+reduction_price)
        return ((price * reduction_price) / 100).toFixed(2);
}

export const computeNumerotation = (currentPage, itemPerPage, index) => {
    return ((currentPage*itemPerPage)-itemPerPage)+index+1;
}