import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Media,
  Progress,
  Row,
  Table,
} from "reactstrap";

// core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ArticleHeader from "components/Headers/ArticleHeader.js";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createArticle,
  deleteArticle,
  getPaginatedArticle,
} from "services/ArticleServices";
import ImageComponent from "./ImageComponent";
import "./filestyle.css";
import "./videostyle.css";
//import { CLIENT_URL, hastagHighlight, inputStyle } from "../../constants";
import { v4 as uuidv4 } from "uuid";
//import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTheme } from "@mui/material/styles";
import ReactLoading from "react-loading";
import {
  addArticle,
  addNewVariant,
  updateArticle,
  updateVariant,
} from "services/ArticleServices";
import {
  getCategoriesWithoutChild,
  getDescentCategories,
} from "services/CategoryServices";
import { addValue } from "services/FeatureServices";
import env from "ts-react-dotenv";
import { computeNumerotation, computePrice } from "../../constants";

const steps = ["Product Detail", "Product variant details"];

const modules = {
  toolbar: [
    // Header options: choose between different header levels or no header
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // Font style options: bold, italic, underline, strike
    ["bold", "italic", "underline", "strike"],

    // Script/subscript/superscript: allows superscript or subscript text
    [{ script: "sub" }, { script: "super" }],

    // List options: ordered list or bullet list
    [{ list: "ordered" }, { list: "bullet" }],

    // Blockquote and code-block
    ["blockquote", "code-block"],

    // Text alignment options: left, center, right, justify
    [{ align: [] }],

    // Link, image, and video insertion
    // ["link", "image", "video"],
    ["link"],

    // Text color and background color

    [{ color: [] }, { background: [] }],

    // Font size options
    [{ size: ["small", false, "large", "huge"] }],

    // Dropdown for font family selection
    [{ font: [] }],

    // Indent and outdent options
    [{ indent: "-1" }, { indent: "+1" }],

    // Direction of the text
    [{ direction: "rtl" }],

    // Clean: removes formatting from the selected text
    ["clean"],
  ],
};

const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const extensionMap = {
  '1': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'],
  '2': ['.xlsx', '.xls'],
  '3': ['.csv'],
  '4': ['.docx', '.doc'],
  '5': ['.pdf'],
  '6': ['.mp3', '.wav', '.ogg', '.flac', '.aac'],
  '7': ['.mp4', '.avi', '.mkv', '.mov', '.flv', '.webm'],
  '8': ['.pptx', '.ppt'],
};

function getStyles(name, selectedFeatures, theme) {
  return {
    fontWeight:
      selectedFeatures.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Articles = () => {
  const {
    control,
    setValue,
    register: register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const theme = useTheme();

  const { ref: name, ...restName } = register("name", {
    required: true,
    minLength: 5,
  });
  const { ref: short_description, ...restShortDescription } = register(
    "short_description",
    { required: true, minLength: 10 }
  );
  const { ref: long_description, ...restLongDescription } = register(
    "long_description",
    { required: true, minLength: 10 }
  );
  const { ref: price, ...restPrice } = register("price", {
    required: true,
    min: 0,
  });
/*   const { ref: quantity, ...restQuantity } = register("quantity", {
    required: true,
    min: 0,
  }); */
  const { ref: reduction_price, ...restReductionPrice } = register(
    "reduction_price",
    { min: 0 }
  );
  const { ref: type, ...restType } = register("type");
  const { ref: etype, ...restEType } = register("etype");

  const { ref: tax, ...restTax } = register("tax", { min: 0 });
  const { ref: sub_category, ...restSubCategory } = register("sub_category", {
    required: "select one option"
  });
  const { ref: reduction_type, ...restReductionType } = register("reduction_type");

  const { user } = useContext(AuthContext);
  const [openArticle, setOpenArticle] = useState(false);
  const [openAddVariant, setOpenAddVariant] = useState(false);
  const [openEditArticle, setOpenEditArticle] = useState(false);
  const [openEditVariant, setOpenEditVariant] = useState(false);
  const [openTable, setOpenTable] = useState(true);
  const [openVariantTable, setOpenVariantTable] = useState(false);
  const [off, setOff] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [articles, setArticles] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDescriptions, setOpenDescriptions] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openVariantModal, setOpenVariantModal] = useState(false);
  const [images, setImages] = useState([]);
  const [articleFile, setArticleFile] = useState(null);
  const [articlePreviewFile, setArticlePreviewFile] = useState(null);
  const [variantImages, setVariantImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [savedVariantImages, setSavedVariantImages] = useState([]);
  const [toDeleteImages, setToDeleteImages] = useState([]);
  const [toDeleteVariantImages, setToDeleteVariantImages] = useState([]);
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const previewFileRef = useRef(null);
  const variantRef = useRef(null);
  const videoRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [savedVideos, setSavedVideos] = useState([]);
  const [toDeleteVideos, setToDeleteVideos] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [nbPageElement, setNbPageElement] = useState(0);
  const [markdown, setMarkdown] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [variantCats, setVariantCats] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoriesWithoutChild, setCategoriesWithoutChild] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [isLoading, setLoading] = useState(true);
  const [currentArticle, setCurrentArticle] = useState({
    name: "",
    price: 0,
    tax: 0,
    reduction_price: 0,
    reduction_type: "1",
    type: "0",
    short_description: "",
    long_description: "",
    link: "",
    path: "",
    preview_path: "",
    category_id: 0,
  });
  const [currentVariant, setCurrentVariant] = useState({
    id: 0,
    price: 0,
    reduction_price: 0,
    reduction_type: "",
    feature_values: {},
  });
  const [featuresToLoad, setFeaturesToLoad] = useState([]);
  const [inputs, setInputs] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [featuresValues, setFeaturesValues] = useState([]);
  const [variantPrice, setVariantPrice] = useState(0);
  const [variantQuantity, setVariantQuantity] = useState(0);
  const [variants, setVariants] = useState([]);
  const [variantReductionPrice, setVariantReductionPrice] = useState(0);
  const [variantReductionType, setVariantReductionType] = useState("");
  const [markdownUpdated, setMarkdownUpdated] = useState("");
  const [actualPrice, setActualPrice] = useState(0);
  const [discountType, setDiscountType] = useState("1");
  const [selectedExtension, setSelectedExtension] = useState("0");
  const [acceptValue, setAcceptValue] = useState('');

  const clearVariant = () => {
    setVariantReductionPrice(0);
    setVariantReductionType("");
    setCurrentVariant((prevState) => ({
      ...prevState,
      id: 0,
      price: 0,
      reduction_price: 0,
      reduction_type: "",
      feature_values: {},
    }));
  };

  const clearArticle = () => {
    setCurrentArticle((prevState) => ({
      ...prevState,
      name: "",
      price: 0,
      tax: 0,
      reduction_price: 0,
      reduction_type: "1",
      type: "0",
      short_description: "",
      long_description: "",
      link: "",
      path: "",
      preview_path: "",
      category_id: 0,
    }));
  };

  const getFileTypeValue = (fileType) => {
    const fileTypeMap = {
      'IMAGE': '1',
      'EXCEL': '2',
      'CSV': '3',
      'WORD': '4',
      'PDF': '5',
      'AUDIO': '6',
      'VIDEO': '7',
      'POWERPOINT': '8',
    };
  
    return fileTypeMap[fileType] || '';
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (selectedCategories.length < 1) {
      setAlertMessage("You have to select at least one category");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (images.length < 1) {
      setAlertMessage("You have to select at least one image");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    console.log("article");
    console.log(currentArticle);

    const currentPrice = computePrice(currentArticle);

    if (currentArticle.price <= 0) {
      setAlertMessage("Invalid price");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (currentPrice <= 0) {
      setAlertMessage("Impossible to make this discount.");
      setAlertType("error");
      handleOpenAlert();
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    console.log("article");
    console.log(currentArticle);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkVariables = (previewPath, previewFile, articlePath, articleFile) =>  {
    return Boolean(previewPath) || Boolean(previewFile) || Boolean(articlePath) || Boolean(articleFile);
  }

  const handleSelectCategory = (event) => {
    setSelectedCategories(event.target.value);
    fetchFeatures(event.target.value);
  };

  const fetchFeatures = async (selectedCats) => {
    const myCategories = subCategories.filter((cat) => {
      return selectedCats.some((id) => {
        return id === cat.id;
      });
    });

    let myFeatures = [];
    for (let category of myCategories) {
      let cats = await getDescentCategories(category.id);
      for (let cat of cats) {
        const feats = cat.features;
        for (let i = 0; i < feats.length; i++) {
          myFeatures.push({ ...feats[i] });
        }
      }
    }

    /*         let myFeatures = [];
                for (let category of myCategories) {
                    const feats = category.features;
                    for (let i = 0; i < feats.length; i++) {
                        myFeatures.push({ ...feats[i] });
                    }
                } */

    const ids = myFeatures.map((obj) => obj.id);
    const uniqueFeatures = myFeatures.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let featureArray = [];

    for (var i in uniqueFeatures) {
      var item = uniqueFeatures[i];
      featureArray.push(item);
    }
    setFeaturesToLoad(featureArray);
  };

  let handleChangeFeature = (i, e) => {
    let newFeatures = [...featuresToLoad];
    newFeatures[i]["value"] = e.target.value;
    setFeaturesToLoad(newFeatures);
  };

  let addFeature = (i, e) => {
    let newFeatures = [...featuresToLoad];
    newFeatures[i]["value"] = e.target.value;
    setFeaturesToLoad(newFeatures);
  };

  const addFeaturesValue = async (i, feature_id) => {
    let val = inputs[i];

    try {
      let featVal = await addValue(feature_id, val);
      setAlertMessage("Feature value added sucessfully");
      setAlertType("success");
      handleOpenAlert();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const addVariant = () => {
    let bool = false;
    for (let variant of variants) {
      const feats = variant.variant_feature_values;
      if (feats === featuresValues) {
        bool = true;
      }
    }

    if (variantQuantity < 1) {
      setAlertMessage("The quantity must be positive");
      setAlertType("error");
      handleOpenAlert();
    } else if (variantPrice < 1) {
      setAlertMessage("The price must be positive");
      setAlertType("error");
      handleOpenAlert();
    } else if (bool) {
      setAlertMessage("This combination already exist !");
      setAlertType("error");
      handleOpenAlert();
    } else {
      if (featuresValues.length < 1) {
        setAlertMessage("Select features of this variant");
        setAlertType("error");
        handleOpenAlert();
        return;
      }
      let key = uuidv4();
      setVariants([
        ...variants,
        {
          variant_key: key,
          variant_quantity: variantQuantity,
          variant_price: variantPrice,
          variant_images: variantImages,
          variant_feature_values: featuresValues,
          variant_reduction_price: variantReductionPrice,
          variant_reduction_type: variantReductionType,
        },
      ]);
      setVariantPrice(currentArticle.price);
      setVariantReductionPrice(currentArticle.reduction_price);
      setVariantReductionType(currentVariant.reduction_type);
      setVariantQuantity(0);
      setVariantImages([]);
    }
  };

  let onFeatureChanged = (e, feature_id, feature_value_id, value) => {
    let valueExist = false;
    const newVals = featuresValues.map((obj) => {
      if (obj.feature_id === feature_id) {
        valueExist = true;
        return {
          feature_id: feature_id,
          feature_value_id: feature_value_id,
          value: value,
        };
      }
      return obj;
    });

    if (valueExist) {
      setFeaturesValues(newVals);
    } else {
      setFeaturesValues([
        ...featuresValues,
        {
          feature_id: feature_id,
          feature_value_id: feature_value_id,
          value: value,
        },
      ]);
    }

    // console.log(featuresValues);
  };

  const updateProgress = (val) => {
    setProgress(val);
  };

  const handleArticle = () => {
    setOpenArticle(!openArticle);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setImages([]);
    setVideos([]);
    setVariants([]);
    setSelectedCategories([]);
    setFeaturesToLoad([]);
    setFeaturesValues([]);
    setActiveStep(0);
    clearArticle();
  };

  const handleAddVariant = () => {
    selectCategoryFeatures(variantCats);
    setOpenAddVariant(!openAddVariant);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setVariants([]);
    setVariantImages([]);
  };

  const selectCategoryFeatures = async (myCategories) => {
    //let myCategories = variantCats;
    // console.log('in select category ', currentArticle.categories);
    let myFeatures = [];
    for (let category of myCategories) {
      let cats = await getDescentCategories(category.id);
      for (let cat of cats) {
        const feats = cat.features;
        for (let i = 0; i < feats.length; i++) {
          myFeatures.push({ ...feats[i] });
        }
      }
    }

    const ids = myFeatures.map((obj) => obj.id);
    const uniqueFeatures = myFeatures.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let featureArray = [];

    for (var i in uniqueFeatures) {
      var item = uniqueFeatures[i];
      featureArray.push(item);
    }
    setFeaturesToLoad(featureArray);
  };

  const closeAddVariant = () => {
    setOpenAddVariant(!openAddVariant);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setVariants([]);
    setVariantImages([]);
    setFeaturesToLoad([]);
    setFeaturesValues([]);
  };

  const handleEditArticle = () => {
    setOpenEditArticle(!openEditArticle);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const handleEditVariant = () => {
    setOpenEditVariant(!openEditVariant);
    setOpenVariantTable(!openVariantTable);
  };

  const closeEditArticle = () => {
    setOpenEditArticle(!openEditArticle);
    setOpenTable(!openTable);
    setOff(!off);
    reset();
    setImages([]);
    setToDeleteImages([]);
    setSelectedCategories([]);
    setFeaturesToLoad([]);
    clearArticle();
  };

  const closeEditVariant = () => {
    setOpenEditVariant(!openEditVariant);
    setOpenVariantTable(!openVariantTable);
    setVariantImages([]);
    setCurrentVariant((prevState) => ({
      id: 0,
      price: 0,
      reduction_price: 0,
      reduction_type: "",
      feature_values: {},
    }));
  };

  const handleVariants = () => {
    setOpenVariantTable(!openVariantTable);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const handleDescription = () => {
    setOpenDescriptions(!openDescriptions);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickImageInput = () => {
    inputRef.current.click();
  };

  const handleClickFileInput = () => {
    fileRef.current.click();
  };

  const handleClickPreviewFileInput = () => {
    previewFileRef.current.click();
  };

  const handleUploadImage = (event) => {
    var image = event.target.files[0];

    var fSize = Math.round(image.size / 1024);
    if (fSize > 51200) {
      setAlertMessage("Image size should be less than 50Mo");
      setAlertType("error");
      handleOpenAlert();
    } else {
      let fileID = uuidv4() + "." + image.name.split(".").pop();
      let imageUrl = URL.createObjectURL(image);
      setImages([...images, { key: fileID, url: imageUrl, file: image }]);
    }
    event.target.value = null;
  };

  const handleUploadFile = (event) => {
    var file = event.target.files[0];

    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    const selectedExtensions = extensionMap[selectedExtension].map(ext => ext.toLowerCase());

    if (selectedExtensions.includes(fileExtension) || (selectedExtensions.includes('') && !fileName.includes('.'))) {
      var fSize = Math.round(file.size / 1024);
      if (fSize > 51200) {
        setAlertMessage("Image size should be less than 50Mo");
        setAlertType("error");
        handleOpenAlert();
      } else {
        let fileID = uuidv4() + "." + file.name.split(".").pop();
        let fileName = file.name;
        let fileUrl = URL.createObjectURL(file);
        setArticleFile({ key: fileID, name: fileName, url: fileUrl, file: file });
      }
      event.target.value = null;
    } else if (selectedExtension === "0"){
      setAlertMessage("Please you must first select the file type !");
      setAlertType("error");
      handleOpenAlert();
    } else {
      setAlertMessage("Invalid file type selected");
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const handleUploadPreviewFile = (event) => {
    var file = event.target.files[0];

    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    const selectedExtensions = extensionMap[selectedExtension].map(ext => ext.toLowerCase());

    if (selectedExtensions.includes(fileExtension) || (selectedExtensions.includes('') && !fileName.includes('.'))) {
      var fSize = Math.round(file.size / 1024);
      if (fSize > 51200) {
        setAlertMessage("Image size should be less than 50Mo");
        setAlertType("error");
        handleOpenAlert();
      } else {
        let fileID = uuidv4() + "." + file.name.split(".").pop();
        let fileName = file.name;
        let fileUrl = URL.createObjectURL(file);
        setArticlePreviewFile({ key: fileID, name: fileName, url: fileUrl, file: file });
      }
      event.target.value = null;
    } else if (selectedExtension === "0"){
      setAlertMessage("Please you must first select the file type !");
      setAlertType("error");
      handleOpenAlert();
    } else {
      setAlertMessage("Invalid file type selected");
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const handleRemoveImage = (imageKey) => {
    const myImages = images.filter((element) => element.key !== imageKey);
    setImages(myImages);
  };

  const handleRemoveFile = () => {
    setArticleFile(null);
  };

  const handleRemovePreviewFile = () => {
    setArticlePreviewFile(null);
  };

  const handleEditFile = () => {
    setCurrentArticle((prevState) => ({
      ...prevState,
      ["path"]: "",
    }));
  };

  const handleEditPreviewFile = () => {
    setCurrentArticle((prevState) => ({
      ...prevState,
      ["preview_path"]: "",
    }));
  };

  const removeToDeleteImage = (imageId) => {
    const myImages = savedImages.filter((element) => element.id !== imageId);
    setSavedImages(myImages);
    setToDeleteImages([...toDeleteImages, imageId]);
  };

  const handleClickVariantImageInput = () => {
    variantRef.current.click();
  };

  const handleUploadVariant = (event) => {
    let image = event.target.files[0];
    let fSize = Math.round(image.size / 1024);
    if (fSize > 51200) {
      setAlertMessage("Image size should be less than 50Mo");
      setAlertType("error");
      handleOpenAlert();
    } else {
      let fileID = uuidv4() + "." + image.name.split(".").pop();
      let imageUrl = URL.createObjectURL(image);
      setVariantImages([
        ...variantImages,
        { key: fileID, url: imageUrl, file: image },
      ]);
    }
    event.target.value = null;
  };

  const handleRemoveVariantImage = (imageKey) => {
    const myImages = images.filter((element) => element.key !== imageKey);
    setVariantImages(myImages);
  };

  const removeToDeleteVariantImage = (imageId) => {
    const myImages = savedVariantImages.filter(
      (element) => element.id !== imageId
    );
    setSavedVariantImages(myImages);
    setToDeleteVariantImages([...toDeleteVariantImages, imageId]);
  };

  const handleChangeVariantQuantity = (event) => {
    setVariantQuantity(event.target.value);
  };

  const handleChangeVariantPrice = (event) => {
    //const price = event.target.value;
    // console.log("le prix du variant  "+price)
    setVariantPrice(event.target.value);
  };

  const handleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleVariantModal = () => {
    setOpenVariantModal(!openVariantModal);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleChangeInput = (e) => {
    setCurrentArticle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if(e.target.name === "type" ){
      setSelectedExtension(e.target.value);
      setAcceptValue(extensionMap[e.target.value].join(', '));
    }

    //if(e.target.name === 'price' || e.target.name === 'reduction_price' || e.target.name === 'reduction_type'){
    //  computeActualPrice(currentArticle.price, currentArticle.reduction_type, currentArticle.reduction_price);
    //}
  };

  const computeActualPrice = (price, reductionType, reductionValue) => {

    let actualPrice;

    switch (reductionType) {
      case "AMOUNT":
      case "3":
        actualPrice = price - reductionValue;
        break;
      case "PERCENTAGE":
      case "2":
        const discount = price * (reductionValue / 100);
        actualPrice = price - discount;
        break;
      default:
        actualPrice = price;
    }

    setActualPrice(actualPrice);
  };

  const handleChangeVariant = (e) => {
    setCurrentVariant((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "reduction_type") {
      setVariantReductionType(e.target.value);
    }

    // if(e.target.name=== 'price'){
    //     setVariantPrice(e.target.value);

    //}
  };

  const addVariants = async () => {
    let article_id = activeObject.item.id;

    try {
      await addNewVariant(article_id, variants, updateProgress);
      setOpenAddVariant(!openAddVariant);
      setOpenTable(!openTable);
      setOff(!off);

      setAlertMessage("Variant added sucessfully");
      setAlertType("success");
      handleOpenAlert();
      retrieveArticles();

      setVariants([]);
      setVariantImages([]);
      setFeaturesToLoad([]);
      setFeaturesValues([]);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();

      setVariants([]);
      setVariantImages([]);
      setFeaturesToLoad([]);
      setFeaturesValues([]);
    }
  };

  const retrieveArticles = async () => {
    let response = await getPaginatedArticle();
    setCurrentPage(response.meta.current_page);
    setArticles(response.data);
    setLoading(false);
  };

  const saveArticle = async (data) => {

    if (actualPrice <= 0) {
      setAlertMessage("Impossible to make this discount.");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (images.length < 1) {
      setAlertMessage("You have to select at least one image");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (articleFile == null) {
      setAlertMessage("You must select a file for this article");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    if (articlePreviewFile == null) {
      setAlertMessage("You must select a preview file for this article");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    setSubmited(true);

    //const articleQuantity = data.
    try {
      let resource = await createArticle(
        data,
        images,
        articleFile,
        articlePreviewFile,
        updateProgress
      );
      console.log(resource);
      setOpenArticle(!openArticle);
      setOpenTable(!openTable);
      setOff(!off);

      setAlertMessage("Article created successfully");
      setAlertType("success");
      handleOpenAlert();

      retrieveArticles();
      reset();
      setImages([]);

      setCurrentArticle((prevState) => ({
        ...prevState,
        name: "",
        price: 0,
        tax: 0,
        quantity: 0,
        reduction_price: 0,
        reduction_type: "",
        type: "0",
        short_description: "",
        long_description: "",
        link: "",
        path: "",
        preview_path: "",
        category_id: 0,
      }));
      setArticleFile(null);
      setArticlePreviewFile(null);
      setSubmited(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
      setSubmited(false);
    }
  };

  const editArticle = async (data) => {
    /*     if (images.length < 1) {
          setAlertMessage("You have to select at least one image");
          setAlertType("error");
          handleOpenAlert();
          return;
        }
    
        if (articleFile == null) {
          setAlertMessage("You must select a file for this article");
          setAlertType("error");
          handleOpenAlert();
          return;
        } */

    if (actualPrice <= 0) {
      setAlertMessage("Impossible to make this discount.");
      setAlertType("error");
      handleOpenAlert();
      return;
    }

    setSubmited(true);
    let article_id = activeObject.item.id;

    try {
      await updateArticle(
        data,
        currentArticle,
        article_id,
        images,
        articleFile,
        articlePreviewFile,
        toDeleteImages,
        updateProgress
      );

      setOpenEditArticle(!openEditArticle);
      setOpenTable(!openTable);
      setOff(!off);
      setAlertMessage("Article updated sucessfully");
      setAlertType("success");
      handleOpenAlert();

      retrieveArticles();
      reset();
      setImages([]);
      setToDeleteImages([]);
      setCurrentArticle((prevState) => ({
        ...prevState,
        name: "",
        price: 0,
        tax: 0,
        quantity: 0,
        reduction_price: 0,
        reduction_type: "",
        type: "0",
        short_description: "",
        long_description: "",
        link: "",
        path: "",
        preview_path: "",
        category_id: 0,
      }));
      setArticleFile(null);
      setArticlePreviewFile(null);
      setSubmited(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      setSubmited(false);
    }
  };

  const editVariant = async () => {
    try {
      let item = await updateVariant(
        currentVariant,
        variantImages,
        toDeleteVariantImages,
        variantReductionPrice,
        variantReductionType
      );

      setOpenEditVariant(!openEditVariant);
      setOpenVariantTable(!openVariantTable);
      setAlertMessage("Variant updated sucessfully");
      setAlertType("success");
      handleOpenAlert();

      retrieveArticles();

      setCurrentArticle((prevState) => ({
        ...prevState,
        name: item.name,
        price: item.price,
        tax: item.tax,
        reduction_price: item.reduction_price,
        reduction_type: item.reduction_type,
        association: item.association,
        description: item.description,
        categories: item.categories,
        variants: item.variants,
      }));

      setVariantImages([]);
      setToDeleteVariantImages([]);

      //setCurrentVariant((prevState) => ({
      //    ...prevState,
      //    id: 0,
      //    price: 0,
      //    quantity: 0,
      //    feature_values: {},
      //}));
      clearVariant();
      setSubmited(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
      setVariantImages([]);
      setToDeleteVariantImages([]);
      //setCurrentVariant((prevState) => ({
      //    ...prevState,
      //    id: 0,
      //    price: 0,
      //    quantity: 0,
      //    feature_values: {},
      //}));
      clearVariant();
      setSubmited(false);
    }
  };

  const deletePost = async () => {
    setOpenDeleteModal(false);
    let post_id = activeObject.item.id;

    try {
      await deleteArticle(post_id);

      setAlertMessage("Post deleted sucessfully");
      setAlertType("success");
      handleOpenAlert();

      retrieveArticles();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const selectArticle = async (article_id) => {
    try {
      await addArticle(article_id);

      setAlertMessage("Article selected sucessfully");
      setAlertType("success");
      handleOpenAlert();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    let input = document.getElementById("textarea");
    if (input.selectionStart || input.selectionStart === "0") {
      let startpos = input.selectionStart;
      let endpos = input.selectionEnd;
      let restore = input.scrollTop;
      input.value =
        input.value.substring(0, startpos) +
        emojiObject.emoji +
        input.value.substring(endpos, input.value.length);
      setMarkdown(input.value);
      if (restore > 0) {
        input.scrollTop = restore;
      }
      input.focus();
      input.selectionStart = startpos + emojiObject.emoji.length;
      input.selectionEnd = startpos + emojiObject.emoji.length;
    } else {
      input.value += emojiObject.emoji;
      input.focus();
    }
  };

  const onUpdateEmojiClick = (event, emojiObject) => {
    let input = document.getElementById("textareaUpdate");
    if (input.selectionStart || input.selectionStart === "0") {
      let startpos = input.selectionStart;
      let endpos = input.selectionEnd;
      let restore = input.scrollTop;
      input.value =
        input.value.substring(0, startpos) +
        emojiObject.emoji +
        input.value.substring(endpos, input.value.length);
      setMarkdownUpdated(input.value);
      if (restore > 0) {
        input.scrollTop = restore;
      }
      input.focus();
      input.selectionStart = startpos + emojiObject.emoji.length;
      input.selectionEnd = startpos + emojiObject.emoji.length;
    } else {
      input.value += emojiObject.emoji;
      input.focus();
    }
  };

  const updateMarkdown = (e) => {
    let input = document.getElementById("textarea");
    setMarkdown(input.value);
  };

  const retrieveCategoriesPaginated = async (page) => {
    let response = await getPaginatedArticle(page, itemPerPage);
    setArticles(response.data);
    setCurrentPage(response.meta.current_page);
    setLastPage(response.meta.last_page);
  };

  const handlePageClick = (data) => {
    const requestedPage = data.selected + 1;
    retrieveCategoriesPaginated(requestedPage);
  };

  const handleSearch = async (e) => {
    let nb = e.target.value;
    setItemPerPage(nb);
    let response = await getPaginatedArticle(undefined, parseInt(nb));
    setArticles(response.data);
    setLastPage(response.meta.last_page);
    setCurrentPage(response.meta.current_page);
  };

  useEffect(() => {
    const retrieveArticles = async () => {
      let response = await getPaginatedArticle();
      setLastPage(response.meta.last_page);
      setCurrentPage(response.meta.current_page);
      setArticles(response.data);
      console.log(response.data);
      setLoading(false);
    };
    retrieveArticles();

    const retrieveCategoriesWithoutChild = async () => {
      let data = await getCategoriesWithoutChild(1);
      console.log(data);
      setCategoriesWithoutChild(data);
    };

    retrieveCategoriesWithoutChild();

  }, []);

  useEffect(() => {
    computeActualPrice(currentArticle.price, currentArticle.reduction_type, currentArticle.reduction_price);
  }, [currentArticle]);

  const DetailModal = ({ object: { item } }) => (
    <Dialog
      open={openDetails}
      onClose={handleDetails}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Article details</DialogTitle>
      <DialogContent>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <td></td>
                  <td>Article Name</td>
                  <td> {item.name}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Type</td>
                  <td> {item.type}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span>Short Description</span>
                  </td>
                  <td>{item.short_description}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Price</td>
                  <td> {item.price}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Quantity</td>
                  <td> {item.quantity} </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>

                  <td>
                    <Row></Row>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <Row>
                      <div className="col">
                        <a
                          href={CLIENT_URL + "details?postId=" + item.id}
                          target="_blank"
                        >
                          <Button color="primary">Go the the article</Button>
                        </a>
                      </div>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleDetails} color="danger">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const VariantModal = ({ object: item }) => (
    <Dialog
      open={openVariantModal}
      onClose={handleVariantModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Variant details</DialogTitle>
      <DialogContent>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <td></td>
                  <td>Price</td>
                  <td> {item.price}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Quantity</td>
                  <td> {item.quantity} </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span>Created at </span>
                  </td>
                  <td>
                    {" "}
                    {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span>Updated at</span>
                  </td>
                  <td>
                    {" "}
                    {moment(item.update_at).format("YYYY/MM/DD kk:mm:ss")}
                  </td>
                </tr>
              </tbody>
            </Table>
            <h2>Feature Values</h2>
            <br />
            <ul>
              {item.feature_values?.map((elt) => (
                <div key={elt.id}>
                  <li>
                    {" "}
                    {elt.feature_name} : {elt.name}
                  </li>
                </div>
              ))}
            </ul>
          </Row>
        </CardBody>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleVariantModal} color="danger">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DescriptionInfos = () => (
    <Dialog
      open={openDescriptions}
      onClose={handleDescription}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <h2>Description formatter</h2>
            <Table className="align-items-center table-flush" responsive>
              <thead>
                <th>Commands</th>
                <th>Syntax</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>Bold</td>
                  <td>
                    Sample content for <sup>**</sup> bold text <sup>**</sup>
                  </td>
                  <td>
                    For bold, add <sup>**</sup> or __ to front and back of the
                    text.
                  </td>
                </tr>
                <tr>
                  <td>Italic</td>
                  <td>
                    Sample content for <sup>*</sup> Italic text <sup>*</sup>
                  </td>
                  <td>
                    For Italic, add <sup>*</sup> or _ to front and back of the
                    text.
                  </td>
                </tr>
                <tr>
                  <td>Bold and Italics</td>
                  <td>
                    <span>
                      Sample content for <sup>***</sup> bold and Italic text{" "}
                      <sup>***</sup>{" "}
                    </span>
                  </td>
                  <td>
                    For bold and Italics, add <sup>***</sup> to the front and
                    back of the text.
                  </td>
                </tr>
                <tr>
                  <td>
                    Heading 1 <br />
                    Heading 6
                  </td>
                  <td>
                    <span># Heading 1 content</span>
                    <br />
                    <span>###### Heading 6 content</span>
                  </td>
                  <td>
                    For heading 1, add # to start of the line.
                    <br />
                    For heading 6, add ###### to start of the line.
                  </td>
                </tr>
                <tr>
                  <td>Hastag</td>
                  <td>#Hastag</td>
                  <td>#Hastag</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDescription} color="danger">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle id="form-dialog-title">Delete Article</DialogTitle>
          <DialogContent>
            <h3 className="mb-0">
              Are you sure you want to delete this article ?
            </h3>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteModal} color="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={deletePost} color="danger">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <ArticleHeader handleArticle={handleArticle} off={off} />
      {/* Page content */}

      {openArticle ? (
        <Container className="mt--7 mb-3" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Post new Article to sell</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button color="danger" onClick={handleArticle} size="sm">
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <React.Fragment>
                    <Form onSubmit={handleSubmit(saveArticle)}>
                      <div>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Name <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  name="name"
                                  className="form-control-alternative"
                                  id="input-address"
                                  placeholder="Name here ..."
                                  type="text"
                                  {...restName}
                                  innerRef={name}
                                  onChange={handleChangeInput}
                                />
                                {errors.name && (
                                  <FormHelperText style={{ color: "red" }}>
                                    At least 5 characters are required !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="pl-lg-4">
                          <Row>
{/*                             <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Quantity Present{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="10"
                                  name="quantity"
                                  type="number"
                                  {...restQuantity}
                                  innerRef={quantity}
                                  onChange={handleChangeInput}
                                />
                                {errors.quantity && (
                                  <FormHelperText style={{ color: "red" }}>
                                    Quantity must be positive !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col> */}
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Price <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  name="price"
                                  className="form-control-alternative"
                                  id="input-last-name"
                                  placeholder="20"
                                  type="number"
                                  {...restPrice}
                                  innerRef={price}
                                  value={currentArticle.price}
                                  onChange={handleChangeInput}
                                />
                                {errors.price && (
                                  <FormHelperText style={{ color: "red" }}>
                                    Price must be positive !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Discount type
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  type="select"
                                  name="reduction_type"
                                  {...restReductionType}
                                  innerRef={reduction_type}
                                  value={currentArticle.reduction_type}
                                  onChange={handleChangeInput}
                                >
                                  <option value="1">
                                    ---Select a reduction type---
                                  </option>
                                  <option value="2">PERCENTAGE</option>
                                  <option value="3">AMOUNT</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Discount value
                                </label>
                                <Input
                                  name="reduction_price"
                                  value={currentArticle.reduction_price}
                                  className="form-control-alternative"
                                  type="number"
                                  {...restReductionPrice}
                                  innerRef={reduction_price}
                                  onChange={handleChangeInput}
                                />

                                {errors.reduction_price && (
                                  <FormHelperText style={{ color: "red" }}>
                                    Discount value must be positive !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <span>Price preview &nbsp;</span> :&nbsp;&nbsp;
                              {currentArticle.price !== undefined &&
                                currentArticle.price !== null &&
                                actualPrice !== undefined &&
                                actualPrice !== null && (
                                  <>
                                    {actualPrice < 0 && <span style={{ color: "red" }}>{actualPrice}</span>}
                                    {actualPrice >= 0 && actualPrice < currentArticle.price && (
                                      <span style={{ color: "green" }}>{actualPrice}</span>
                                    )}
                                    {currentArticle.price === actualPrice && <span >{actualPrice}</span>}
                                    {actualPrice > currentArticle.price && <span style={{ color: "red" }}>{actualPrice}</span>}
                                  </>
                                )}
                            </Col>
                            <br />
                            <br />
                          </Row>
                        </div>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup error={errors.type ? true : undefined}>
                                <label className="form-control-label">
                                  Type <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  type="select"
                                  {...restType}
                                  innerRef={type}
                                  disabled={articleFile !== null || articlePreviewFile !== null}
                                  aria-invalid={errors.type ? "true" : "false"}
                                  onChange={(e) => {setSelectedExtension(e.target.value) ;
                                    setAcceptValue(extensionMap[e.target.value].join(', '));}}
                                >
                                  <option value="1">IMAGE</option>
                                  <option value="2">EXCEL</option>
                                  <option value="3">CSV</option>
                                  <option value="4">WORD</option>
                                  <option value="5">PDF</option>
                                  <option value="6">AUDIO</option>
                                  <option value="7">VIDEO</option>
                                  <option value="8">POWERPOINT</option>
                                </Input>
                                {errors.type && (
                                  <FormHelperText style={{ color: "red" }}>
                                    Select one association !
                                  </FormHelperText>
                                )}
                                {(articleFile !== null || articlePreviewFile !== null) && (
                                  <FormHelperText style={{ color: "gray" }}>
                                    To change this, please remove the article and preview files !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4 mb-3">
                          <Row>
                            <Col md="12">
                              <FormGroup
                                error={errors.sub_category ? true : undefined}
                              >
                                <label className="form-control-label">
                                  Select Category{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  type="select"
                                  {...restSubCategory}
                                  innerRef={sub_category}
                                  aria-invalid={
                                    errors.sub_category ? "true" : "false"
                                  }
                                >
                                  <option value="">
                                    --Select Sub Category Type--
                                  </option>
                                  {categoriesWithoutChild.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Input>
                                {errors.sub_category && (
                                  <FormHelperText style={{ color: "red" }}>
                                    Select one category !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup
                                error={
                                  errors.short_description ? true : undefined
                                }
                              >
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Short Description{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Input
                                  type="textarea"
                                  className="form-control-alternative"
                                  placeholder="Short description here ..."
                                  {...restShortDescription}
                                  innerRef={short_description}
                                  rows={3}
                                  aria-invalid={
                                    errors.short_description ? "true" : "false"
                                  }
                                />

                                {errors.short_description && (
                                  <FormHelperText style={{ color: "red" }}>
                                    At least 10 characters are required !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup
                                error={
                                  errors.long_description ? true : undefined
                                }
                              >
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Long Description{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <Controller
                                  name="long_description"
                                  control={control}
                                  rules={{ required: true, minLength: 10 }}
                                  render={({ field }) => (
                                    <ReactQuill
                                      theme="snow"
                                      modules={modules}
                                      value={field.value}
                                      onChange={field.onChange}
                                      placeholder="Long description here ..."
                                    />
                                  )}
                                />

                                {errors.long_description && (
                                  <FormHelperText style={{ color: "red" }}>
                                    At least 10 characters are required !
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Add Images
                        </h6>

                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={inputRef}
                            onChange={handleUploadImage}
                          />
                        </div>

                        <div className="images">
                          {images?.map((item) => (
                            <ImageComponent
                              key={item.key}
                              value={item.url}
                              onRemoveElement={() =>
                                handleRemoveImage(item.key)
                              }
                            />
                          ))}
                          <div className="pic" onClick={handleClickImageInput}>
                            ADD IMAGE
                          </div>
                        </div>

                        <hr className="my-4"/>
                        <h6 className="heading-small text-muted mb-4">
                          Add the corresponding File and Preview File
                        </h6>

                        <div>
                          <input
                            type="file"
                            accept={acceptValue}
                            style={{ display: "none" }}
                            ref={fileRef}
                            onChange={handleUploadFile}
                          />
                          <input
                            type="file"
                            accept={acceptValue}
                            style={{ display: "none" }}
                            ref={previewFileRef}
                            onChange={handleUploadPreviewFile}
                          />
                        </div>

                        <div className="images">
                          {articlePreviewFile != null ? (
                            <div className="fil" onClick={handleRemovePreviewFile}>
                              <span>CLICK TO REMOVE &nbsp;</span>
                              {articlePreviewFile.name}
                            </div>
                          ) : (
                            <div className="pic" style={{marginRight: '5px'}} onClick={handleClickPreviewFileInput}>
                              ADD PREVIEW FILE
                            </div>
                          )}
                          {articleFile != null ? (
                            <div className="fil" onClick={handleRemoveFile}>
                              <span>CLICK TO REMOVE &nbsp;</span>
                              {articleFile.name}
                            </div>
                          ) : (
                            <div className="pic" onClick={handleClickFileInput}>
                              ADD FILE
                            </div>
                          )}
                        </div>

                        <hr className="my-4" />
                        {progress > 0 && progress < 100 ? (
                          <div className="pl-lg-4">
                            <div className="text-center">{progress}%</div>
                            <Progress value={progress} />
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="pl-lg-4 mt-3">
                          <Row className="align-items-center">
                            <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                            <Col className="text-right" xs="4">
                              {submited ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                  size="sm"
                                  disabled
                                >
                                  Submit
                                </Button>
                              ) : (
                                <Button color="primary" type="submit" size="sm">
                                  Submit
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}

      {openEditArticle ? (
        <Container className="mt--7 mb-3" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Article</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="danger"
                        onClick={closeEditArticle}
                        size="sm"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit(editArticle)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              name="name"
                              value={currentArticle.name}
                              className="form-control-alternative"
                              id="input-address"
                              {...restName}
                              innerRef={name}
                              onChange={handleChangeInput}
                            />
                            {errors.name && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
{/*                         <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Quantity Present{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              name="quantity"
                              value={currentArticle.quantity}
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="10"
                              type="number"
                              {...restQuantity}
                              innerRef={quantity}
                            />
                            {errors.quantity && (
                              <FormHelperText style={{ color: "red" }}>
                                Quantity must be positive !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col> */}
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Price <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              name="price"
                              value={currentArticle.price}
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="20"
                              type="number"
                              {...restPrice}
                              innerRef={price}
                              onChange={handleChangeInput}
                            />
                            {errors.price && (
                              <FormHelperText style={{ color: "red" }}>
                                Price must be positive !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Discount type
                            </label>
                            <Input
                              name="reduction_type"
                              className="form-control-alternative"
                              type="select"
                              {...restReductionType}
                              innerRef={reduction_type}
                              onChange={handleChangeInput}
                            >
                              <option
                                value="1"
                                selected={
                                  discountType == "DEFAULT" ||
                                  discountType == "1"
                                }
                              >
                                --- Select Discount Type ---
                              </option>
                              <option
                                value="2"
                                selected={
                                  discountType == "PERCENTAGE" ||
                                  discountType == "2"
                                }
                              >
                                PERCENTAGE
                              </option>
                              <option
                                value="3"
                                selected={
                                  discountType == "AMOUNT" ||
                                  discountType == "3"
                                }
                              >
                                AMOUNT
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Discount value
                            </label>
                            <Input
                              name="reduction_price"
                              value={currentArticle.reduction_price}
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="16"
                              type="number"
                              {...restReductionPrice}
                              innerRef={reduction_price}
                              onChange={handleChangeInput}
                            />
                            {errors.reduction_price && (
                              <FormHelperText style={{ color: "red" }}>
                                Discount value must be positive !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <span>Price preview &nbsp;</span> :&nbsp;&nbsp;
                          {currentArticle.price !== undefined &&
                            currentArticle.price !== null &&
                            actualPrice !== undefined &&
                            actualPrice !== null && (
                              <>
                                {actualPrice < 0 && <span style={{ color: "red" }}>{actualPrice}</span>}
                                {actualPrice >= 0 && actualPrice < currentArticle.price && (
                                  <span style={{ color: "green" }}>{actualPrice}</span>
                                )}
                                {currentArticle.price === actualPrice && <span >{actualPrice}</span>}
                                {actualPrice > currentArticle.price && <span style={{ color: "red" }}>{actualPrice}</span>}
                              </>
                            )}
                        </Col>
                        <br />
                        <br />
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup error={errors.type ? true : undefined}>
                            <label className="form-control-label">
                              Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              name="type"
                              className="form-control-alternative"
                              type="select"
                              disabled={checkVariables(currentArticle.preview_path, articlePreviewFile, currentArticle.path, articleFile)}
                              {...restType}
                              innerRef={type}
                              onChange={handleChangeInput}
                            >
                              <option
                                value="1"
                                selected={
                                  currentArticle.type == "IMAGE" ||
                                  currentArticle.type == "1"
                                }
                              >
                                IMAGE
                              </option>
                              <option
                                value="2"
                                selected={
                                  currentArticle.type == "EXCEL" ||
                                  currentArticle.type == "2"
                                }
                              >
                                EXCEL
                              </option>
                              <option
                                value="3"
                                selected={
                                  currentArticle.type == "CSV" ||
                                  currentArticle.type == "3"
                                }
                              >
                                CSV
                              </option>
                              <option
                                value="4"
                                selected={
                                  currentArticle.type == "WORD" ||
                                  currentArticle.type == "4"
                                }
                              >
                                WORD
                              </option>
                              <option
                                value="5"
                                selected={
                                  currentArticle.type == "PDF" ||
                                  currentArticle.type == "5"
                                }
                              >
                                PDF
                              </option>
                              <option
                                value="6"
                                selected={
                                  currentArticle.type == "AUDIO" ||
                                  currentArticle.type == "6"
                                }
                              >
                                AUDIO
                              </option>
                              <option
                                value="7"
                                selected={
                                  currentArticle.type == "VIDEO" ||
                                  currentArticle.type == "7"
                                }
                              >
                                VIDEO
                              </option>
                              <option
                                value="8"
                                selected={
                                  currentArticle.type == "POWERPOINT" ||
                                  currentArticle.type == "8"
                                }
                              >
                                POWERPOINT
                              </option>
                            </Input>
                            {errors.type && (
                              <FormHelperText style={{ color: "red" }}>
                                Select one option
                              </FormHelperText>
                            )}
                            {(checkVariables(currentArticle.preview_path, articlePreviewFile, currentArticle.path, articleFile)) && (
                                  <FormHelperText style={{ color: "gray" }}>
                                    To change this, please remove the article and preview files !
                                  </FormHelperText>
                                )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="pl-lg-4 mb-3">
                      <Row>
                        <Col md="12">
                          <FormGroup
                            error={errors.sub_category ? true : undefined}
                          >
                            <label className="form-control-label">
                              Select Category{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="select"
                              {...restSubCategory}
                              innerRef={sub_category}
                              aria-invalid={
                                errors.sub_category ? "true" : "false"
                              }
                            >
                              <option value="0">--Select Category--</option>
                              {categoriesWithoutChild.map((item) => (
                                <option
                                  selected={
                                    currentArticle.category_id == item.id
                                  }
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {errors.sub_category && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 5 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup
                            error={errors.short_description ? true : undefined}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Short Description{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              name="short_description"
                              value={currentArticle.short_description}
                              type="textarea"
                              className="form-control-alternative"
                              placeholder="Short description here ..."
                              {...restShortDescription}
                              innerRef={short_description}
                              onChange={handleChangeInput}
                              rows={3}
                              aria-invalid={
                                errors.short_description ? "true" : "false"
                              }
                            />

                            {errors.short_description && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 10 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup
                            error={errors.long_description ? true : undefined}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Long Description{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <Controller
                              name="long_description"
                              control={control}
                              rules={{ required: true, minLength: 10 }}
                              render={({ field }) => (
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder="Long description here ..."
                                />
                              )}
                            />

                            {errors.long_description && (
                              <FormHelperText style={{ color: "red" }}>
                                At least 10 characters are required !
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Add Images
                    </h6>

                    <div className="all-input-files">
                      <input
                        id="file0"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={inputRef}
                        onChange={handleUploadImage}
                      />
                    </div>

                    <div className="images">
                      {savedImages?.map((item) => (
                        <ImageComponent
                          key={item.id}
                          value={MEDIA_URL + item.path}
                          onRemoveElement={() => removeToDeleteImage(item.id)}
                        />
                      ))}
                      {images?.map((item) => (
                        <ImageComponent
                          key={item.key}
                          value={item.url}
                          onRemoveElement={() => handleRemoveImage(item.key)}
                        />
                      ))}
                      <div className="pic" onClick={handleClickImageInput}>
                        ADD IMAGE
                      </div>
                    </div>

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Edit the corresponding File and Preview File
                    </h6>

                    <div>
                      <input
                        type="file"
                        accept={acceptValue}
                        style={{ display: "none" }}
                        ref={fileRef}
                        onChange={handleUploadFile}
                      />
                      <input
                            type="file"
                            accept={acceptValue}
                            style={{ display: "none" }}
                            ref={previewFileRef}
                            onChange={handleUploadPreviewFile}
                          />
                    </div>

                    <div className="images">
                      {currentArticle.preview_path != "" && (
                        <div className="fil" onClick={handleEditPreviewFile}>
                          <span>CLICK TO REMOVE PREVIEW &nbsp;</span>
                          {currentArticle.preview_path}
                        </div>
                      )}
                      {articlePreviewFile != null && (
                        <div className="fil" onClick={handleRemovePreviewFile}>
                          <span>CLICK TO REMOVE &nbsp;</span>
                          {articlePreviewFile.name}
                        </div>
                      )}
                      {currentArticle.preview_path == "" && articlePreviewFile == null && (
                        <div className="pic" style={{marginRight: '5px'}} onClick={handleClickPreviewFileInput}>
                          ADD PREVIEW FILE
                        </div>
                      )}
                      {articleFile != null && (
                        <div className="fil" onClick={handleRemoveFile}>
                          <span>CLICK TO REMOVE &nbsp;</span>
                          {articleFile.name}
                        </div>
                      )}
                      {currentArticle.path != "" && (
                        <div className="fil" onClick={handleEditFile}>
                          <span>CLICK TO REMOVE FILE &nbsp;</span>
                          {currentArticle.path}
                        </div>
                      )}
                      {currentArticle.path == "" && articleFile == null && (
                        <div className="pic" onClick={handleClickFileInput}>
                          ADD FILE
                        </div>
                      )}
                    </div>
                    {progress > 0 && progress < 100 ? (
                      <div className="pl-lg-4">
                        <div className="text-center">{progress}%</div>
                        <Progress value={progress} />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="pl-lg-4">
                      <Row className="align-items-center">
                        <Col xs="8">{/* <h3 className="mb-0"></h3> */}</Col>
                        <Col className="text-right" xs="4">
                          {submited ? (
                            <Button
                              color="primary"
                              type="submit"
                              size="sm"
                              disabled
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button color="primary" type="submit" size="sm">
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
      {openTable ? (
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All Your Articles Here </h3>
                </CardHeader>
                {isLoading ? (
                  <ReactLoading type={"spin"} color="#B20101" />
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Category</th>
                        <th scope="col">Type</th>
                        <th scope="col">Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {articles?.map((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {computeNumerotation(
                                currentPage,
                                itemPerPage,
                                index
                              )}
                            </span>
                          </th>
                          <td>{item.category_name}</td>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {item.type}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{item.name}</td>
                          <td>{item.price}</td>
                          <td>
                            {moment(item.created_at).format(
                              "YYYY/MM/DD kk:mm:ss"
                            )}
                          </td>
                          <td className="text-right">
                            <div className="d-flex px-2 py-1">
                              <Tooltip title="Edit" placement="top">
                                <span
                                  className="material-icons"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    setCurrentArticle((prevState) => ({
                                      ...prevState,
                                      name: item.name,
                                      price: item.price,
                                      tax: item.tax,
                                      reduction_price: item.reduction_price,
                                      reduction_type: item.reduction_type,
                                      type: item.type,
                                      short_description: item.short_description,
                                      long_description: item.long_description,
                                      path: item.path,
                                      preview_path: item.preview_path,
                                      category_id: item.category_id,
                                    }));
                                    setValue(
                                      "long_description",
                                      item.long_description
                                    );
                                    const typ = getFileTypeValue(item.type)
                                    setSelectedExtension(typ);
                                    setAcceptValue(extensionMap[typ].join(', '));
                                    setDiscountType(item.reduction_type)
                                    setSavedImages(item.medias);
                                    handleEditArticle();
                                  }}
                                  style={{ cursor: "pointer", color: "orange" }}
                                >
                                  edit
                                </span>
                              </Tooltip>
                              <Tooltip title="Delete" placement="top">
                                <span
                                  className="material-icons"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    handleDeleteModal();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "firebrick",
                                  }}
                                >
                                  delete
                                </span>
                              </Tooltip>
                              <Tooltip title="View Details" placement="top">
                                <span
                                  className="material-icons danger"
                                  onClick={() => {
                                    setActiveObject({ item });
                                    handleDetails();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "dodgerblue",
                                  }}
                                >
                                  visibility
                                </span>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <CardFooter className="py-4">
                  <Row>
                    <Col>
                      <Form>
                        <FormGroup className="mb-0" style={{ width: "100px" }}>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25" selected>
                              25
                            </option>
                          </select>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col>
                      <nav aria-label="...">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={lastPage}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-end mb-0"
                          }
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          pageLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        ""
      )}

      {openDetails ? <DetailModal object={activeObject} /> : null}
      {openVariantModal ? <VariantModal object={currentVariant} /> : null}
      {openDescriptions ? <DescriptionInfos /> : null}
    </>
  );
};

export default Articles;
