
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import { AuthContext } from "context/AuthContext";

function ProtectionAdmin(props) {

    let AdminCmp = props.Cmp;
    const history = useHistory();
    const { connected, user } = useContext(AuthContext);

    useEffect(() => {
        if (!localStorage.getItem("access_token") && !sessionStorage.getItem("access_token")) {
            history.push("/auth/login");
        } //else if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")){
           // history.push("/admin/index");
        //}

    }, []);

    return (
        <>
           {connected &&  <AdminCmp />}
        </>
    );

}

export default ProtectionAdmin;

